import React, { useState, useEffect, useContext } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, Button, Space, Select, Checkbox, Row, Col, message, Cascader, Tooltip, Badge, Radio } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { DollarFormatter, DollarParser, GetCurrentLoggedInUser, IsString, nicelyFormatPascalCaseString, roundNumber } from '../../helpers';
import { areArraysEqual } from '../../_dash';
import { ADAS_DECLINED_STATUS_VALUES, INSTALL_STATUS_VALUES, QRSCAN_JOB_CURRENT_STATUS_MAPPINGS } from '../../global_constants';

import TintPricingComponent from "../../components/tintPricingEditableTableModalComponent";
import AddUnpromptedPartReviewModal from '../addUnpromptedPartReviewModal';
import AccountUsersSystemContext from '../../context/accountUsersSystem';
import { addUnpromptedExpertReview } from '../../actions';

const allowedPartialEditProperties = ["glass_logistics_status", "install_status", "intake_form_vehicle_id", "technician_user_ids", "glass_price"];

const { Option } = Select;

const EditableCell = ({
  vehicles,
  technicianEmployees,
  fetchPartNr,
  checkPartNrInternalAvailability,
  onNagsPartChangeHandler,
  cancelOrderQRScan,
  cancelOrderNonQRScan,
  fetchPartNotes,
  performPriceCheck,
  editing,
  dataIndex,
  required,
  title,
  className,
  inputType,
  clearable,
  multiple,
  size,
  ddlOptions,
  record,
  index,
  children,
  form,
  width,
  saveAction,
  isGlassReplacementJobParent,
  internalHelpRequest,
  AGCrmNagsSearch,
  shopLocations,
  nagsPartNrAGCRMInstallInfos,
  isInsuranceWorkType,
  onFinishEditingFetchAGCRMNagsPartNr: onManuallyFetchAGCRMNagsPartNr,
  isQuoteOnlyLocationSelected,
  ...restProps
}) => {
  let inputNode = null;
  let isVisible = true;
  let skipConventionalField = false;
  switch (inputType) {
    case 'number':
      inputNode = <InputNumber />;
      break;
    case "cascadedDDL":
      inputNode = (
        <Cascader
          options={ddlOptions}
          placeholder={`Please input the ${title}`}
          onChange={(value) => {
            if (dataIndex === "glass_type" && !areArraysEqual(value, ["Other"])) {
              saveAction(record.key, false, true);
              return;
            }
            if (dataIndex === "glass_type" && !areArraysEqual(value, ["Windshield"])) {
              saveAction(record.key, false, true);
              return;
            }
          }}
        />
      )
      break;
    case "ddl":
      let labelInValue = false;
      if (dataIndex === "tint_pricing") {
        labelInValue = true;
      }
      inputNode = <Select
        allowClear={clearable === true}
        onClear={() => {
          if (dataIndex === "tint_pricing") {
            form.setFieldsValue({ tint_price: null, tint_pricing: null })
          }
          if (dataIndex === "inventory_source") {
            form.setFieldsValue({ inventory_source: null, inventory_source_other_value: null })
          }
        }}
        mode={multiple ? "multiple" : null}
        size={size ? size : "middle"}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        labelInValue={labelInValue}
        placeholder={`Please input the ${title}`}
        onChange={(value, option) => {
          if (dataIndex === "tint_pricing" && option?.value !== null) {
            saveAction(record.key, false, true);
            return;
          }
          if (dataIndex === "inventory_source" && option?.value !== "Other") {
            saveAction(record.key, false, true);
            return;
          }

          saveAction(record.key, false)
        }}
      >
        {ddlOptions.map((option) => {
          let disabled = false;
          let value = option.value;
          let key = option.value;
          let label = option.label;
          if (dataIndex === "tint_pricing") {
            value = option.price + ";;;" + option.name;
            key = option.name;
            disabled = !option.is_active;
          }

          return (
            <Option
              value={value}
              key={key}
              disabled={disabled}
            >
              {label}
            </Option>
          )
        })}
      </Select>
      break;
    case "checkbox":
      inputNode = <Checkbox> Yes/No </Checkbox>
      break;
    case "currency":
      inputNode = <InputNumber
        className={className ? className : ''}
        size="middle"
        style={{ width: "100%" }}
        formatter={DollarFormatter}
        parser={DollarParser}
        placeholder={`Please input the ${title}`}
      />
      break;
    case "currency-custom":
      inputNode = <></>;
      skipConventionalField = true;
      break;
    default:
      if (dataIndex === "nags_part_nr") {
        inputNode = <Input onChange={onNagsPartChangeHandler} />
        break;
      }
      inputNode = <Input />;
      break;
  }
  let additionalFormProps = {};
  if (inputType === "checkbox") {
    additionalFormProps = { valuePropName: "checked" }
  }

  if (inputType === "total_price") {

    let totalPriceObj = <></>
    let totalPrice = NaN;
    if (dataIndex === "glass_total_price" || dataIndex === "tint_only_total_price") {
      let tintPrice = 0;
      if (record.tint_pricing?.value.split(";;;")[0] === "null") {
        tintPrice = parseFloat(record.tint_price);
      } else {
        tintPrice = parseFloat(record.tint_pricing?.value.split(";;;")[0]);
      }
      if (dataIndex === "glass_total_price") {

        let glassPrice = (isInsuranceWorkType ? record.glass_price_total : null) || record.glass_price;
        glassPrice = parseFloat(glassPrice);

        totalPrice = tintPrice;
        if (isNaN(totalPrice)) totalPrice = 0;
        if (!isNaN(glassPrice)) totalPrice = totalPrice + glassPrice;
      }
      if (dataIndex === "tint_only_total_price") totalPrice = tintPrice;
    }
    if (dataIndex === "price_only_total_price") totalPrice = parseFloat(record.price);

    let calibrationPrice = (isInsuranceWorkType ? record.calibration_pricing_total : null) || record.calibration_pricing;
    let calibrationPricing = parseFloat(calibrationPrice);
    if (!isNaN(calibrationPricing)) totalPrice = totalPrice + calibrationPricing;

    if (!isNaN(totalPrice)) {
      totalPrice = roundNumber(totalPrice);
      totalPriceObj = DollarFormatter(totalPrice);
    }
    return <td {...restProps}>{totalPriceObj}</td>
  }

  let vehicleDisplay = null;
  if (dataIndex === "intake_form_vehicle_id") {
    if (children[1] !== null && children[1] !== undefined) {
      let matchedVehicles = vehicles.filter(x => (x.id || "---").toString() === children[1].toString());
      if (matchedVehicles.length > 0) {
        vehicleDisplay = `${matchedVehicles[0].year} ${matchedVehicles[0].make} ${matchedVehicles[0].model}`;
      }
    }
  }

  let technicianEmployeesDisplay = null;
  if (dataIndex === "technician_user_ids") {
    if (Array.isArray(children[1])) {
      if (children[1].length !== 0) {
        let matchedUsers = technicianEmployees.filter(
          x => children[1].includes(x.id)
        )
        matchedUsers = matchedUsers.map(x => {
          return `${x.first_name} ${x.last_name}`
        });
        technicianEmployeesDisplay = matchedUsers.join(", ");
      }
    }
  }

  let shopLocationDeliveryToOverridenName;
  if (!editing && isGlassReplacementJobParent) {
    shopLocationDeliveryToOverridenName = (shopLocations || []).find((shopLocation, index) => {
      if (shopLocation.id === record?.override_order_details_shop_location_id) {
        return true;
      }
      return false;
    })?.name;
  }

  let isPartialEditOnlyField = undefined;
  if (editing && isGlassReplacementJobParent) {
    if (record?.cancel_order_enabled) {
      isPartialEditOnlyField = allowedPartialEditProperties.includes(dataIndex);
    }
  }

  let nagsListPrice = undefined;
  if (dataIndex === "glass_price" && isGlassReplacementJobParent && record.nags_part_nr !== null && record.nags_part_nr !== undefined) {
    let nagsPartNrToSearch = (record.nags_part_nr || "").trim();
    if (nagsPartNrToSearch.length > 7) {
      nagsPartNrToSearch = nagsPartNrToSearch.substring(0, 7);
    }

    if (nagsPartNrAGCRMInstallInfos[nagsPartNrToSearch] !== undefined) {
      nagsListPrice = DollarFormatter(nagsPartNrAGCRMInstallInfos[nagsPartNrToSearch]?.price);
    }
  }

  const areNoPartCancellationsPerformed = (record?.part_cancellations || []).length === 0

  const cellContent = (
    <If condition={isVisible}>
      <If condition={editing && (isPartialEditOnlyField === true || isPartialEditOnlyField === undefined)}>
        <If condition={nagsListPrice !== undefined}>
          <i style={{ display: "block", marginBottom: "15px" }}>
            NAGS List Price: {nagsListPrice}
          </i>
        </If>
        <If condition={skipConventionalField === false}>
          <If condition={dataIndex === "glass_price" && isGlassReplacementJobParent}>
            Sale Price
          </If>
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: required,
                message: `Please Input ${title}!`,
              },
            ]}
            {...additionalFormProps}
          >
            {inputNode}
          </Form.Item>
        </If>
        <If condition={dataIndex === "glass_type" && areArraysEqual(record.glass_type, ["Other"])}>
          <Form.Item
            name="glass_type_other_value"
            label="Please enter Glass Type"
            style={{
              margin: 0,
              marginTop: "15px"
            }}
            rules={[
              {
                required: required,
                message: `Please Input Specify Other Glass Type!`,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </If>
        <If condition={dataIndex === "nags_part_nr" && record.glass_type !== undefined && !areArraysEqual(record.glass_type, ["Other"])}>
          <Button
            style={{ display: "block", marginTop: "10px", float: "right" }}
            onClick={() => fetchPartNr(record)}
            type="primary"
          > Part Lookup </Button>
        </If>
        <If condition={dataIndex === "nags_part_nr"}>
          <Button
            style={{ display: "block", marginTop: "10px", float: "right" }}
            onClick={() => {
              if (isQuoteOnlyLocationSelected) {
                message.warning("Current selected location is Quote Only - you might not be able to successfully order a glass part", 5);
              }
              performPriceCheck(record)
            }}
            type="primary"
          > Price Check </Button>
          <Button
            onClick={() => { internalHelpRequest() }}
            style={{ backgroundColor: "indianred", color: "black", display: "block", marginTop: "10px", float: "right" }}
          > Internal Help </Button>
          <Button
            onClick={() => { AGCrmNagsSearch() }}
            type="primary"
            style={{ display: "block", marginTop: "10px", float: "right" }}
          > NAGS </Button>
        </If>
        <If condition={dataIndex === "glass_type" && areArraysEqual(record.glass_type, ["Windshield"]) && isGlassReplacementJobParent}>
          <Select
            size="small"
            style={{ width: "100%", marginTop: "10px" }}
            placeholder="Calibration Pricing Options"
            onChange={(val, _) => form.setFieldsValue({ calibration_pricing: val.split(";")[1] })}
          >
            <Option value="Static;250">Static - $250</Option>
            <Option value="Dynamic;250">Dynamic - $250</Option>
          </Select>
          <Form.Item
            name="calibration_pricing"
            label="Please enter Calibration Pricing"
            style={{
              margin: 0,
              marginTop: "15px"
            }}
            initialValue={0}
            rules={[
              {
                required: required,
                message: `Please Input Calibration Pricing`,
              },
            ]}
          >
            <InputNumber
              size="small"
              style={{ width: "100%" }}
              formatter={DollarFormatter}
              parser={DollarParser}
            />
          </Form.Item>
          <Form.Item
            name="adas_declined_status"
            label="ADAS Declined?"
            style={{
              margin: 0,
              marginTop: "15px"
            }}
          >
            <Radio.Group>
              {ADAS_DECLINED_STATUS_VALUES.map((x) => {
                return (
                  <Radio value={x.value}>{x.label}</Radio>
                )
              })}
            </Radio.Group>
          </Form.Item>

          <If condition={isInsuranceWorkType}>
            <Form.Item
              name={"calibration_pricing_total"}
              label="Final Calibration Pricing"
              style={{
                margin: 0,
                marginTop: "10px"
              }}
            >
              <InputNumber
                size="small"
                style={{ width: "100%" }}
                formatter={DollarFormatter}
                parser={DollarParser}
              />
            </Form.Item>
          </If>
        </If>
        <If condition={dataIndex === "glass_type" && isGlassReplacementJobParent}>
          <Button
            type="primary"
            style={{ marginTop: "15px" }}
            onClick={() => {
              fetchPartNotes(record);
            }}
          > Part Notes </Button>
        </If>
        <If condition={dataIndex === "inventory_source" && record.inventory_source === "Other"}>
          <Form.Item
            name="inventory_source_other_value"
            label="Please enter Inventory Source"
            style={{
              margin: 0,
              marginTop: "15px"
            }}
            rules={[
              {
                required: true,
                message: `Please Input Inventory Source!`,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </If>
        <If condition={dataIndex === "tint_pricing" && record.tint_pricing?.value.split(";;;")[0] === "null"}>
          <Form.Item
            name="tint_price"
            label=""
            style={{
              margin: 0,
              marginTop: "15px"
            }}
            rules={[
              {
                required: required,
                message: `Please Input Tint Price!`,
              },
            ]}
          >
            <InputNumber
              size="middle"
              style={{ width: "100%" }}
              formatter={DollarFormatter}
              parser={DollarParser}
              placeholder={`Please input the ${title}`}
            />
          </Form.Item>
        </If>
        <If condition={dataIndex === "glass_price" && isGlassReplacementJobParent && isInsuranceWorkType}>
          <Form.Item
            name={"glass_price_total"}
            label="Final Glass Price"
            style={{
              margin: 0,
              marginTop: "20px"
            }}
          >
            <InputNumber
              size="small"
              style={{ width: "100%" }}
              formatter={DollarFormatter}
              parser={DollarParser}
            />
          </Form.Item>
        </If>
      </If>
      <If condition={!editing || (editing && isPartialEditOnlyField === false)}>
        <If condition={nagsListPrice !== undefined}>
          <i style={{ display: "block", marginBottom: "15px" }}>
            NAGS List Price: {nagsListPrice}
          </i>
        </If>
        <If condition={inputType === "currency"}>
          <If condition={dataIndex === "glass_price" && isGlassReplacementJobParent && isInsuranceWorkType}>
            <div style={{ marginTop: "15px", fontWeight: record.glass_price_total ? "normal" : "bolder" }}>
              Sale Price:
            </div>
          </If>
          <If condition={children[1] === undefined || children[1] === null}>
            {DollarFormatter(0)}
            <Else />
            {DollarFormatter(children[1])}
          </If>
          <If condition={dataIndex === "glass_price" && isGlassReplacementJobParent && record.glass_price_total && isInsuranceWorkType}>
            <div style={{ marginTop: "15px" }}>
              <i style={{ fontWeight: "bolder" }}> Final Glass Price: </i>
              <span style={{ display: "block" }}>{DollarFormatter(record.glass_price_total)}</span>
            </div>
          </If>
          <Else />
          <If condition={["glass_type", "intake_form_vehicle_id", "technician_user_ids"].includes(dataIndex)}>
            <If condition={dataIndex === "glass_type"}>
              {/* Sometimes, the glass type is string, and sometimes it is array of strings
              It's an intermittent issue, so we handle both situations here */}
              <If condition={!IsString((children[1] || [""]))}>
                {nicelyFormatPascalCaseString((children[1] || [""]).at(-1))}
                <Else />
                {children[1]}
              </If>
            </If>
            <If condition={dataIndex === "intake_form_vehicle_id"}>
              {/* eslint-disable-next-line jsx-control-statements/jsx-use-if-tag */}
              {vehicleDisplay !== null ? vehicleDisplay : <i>Vehicle Not Selected</i>}
            </If>
            <If condition={dataIndex === "technician_user_ids"}>
              {/* eslint-disable-next-line jsx-control-statements/jsx-use-if-tag */}
              {technicianEmployeesDisplay !== null ? technicianEmployeesDisplay : <i>No Techs Assigned</i>}
            </If>
            <Else />
            {children}
          </If>
        </If>
        <If condition={dataIndex === "nags_part_nr"}>
          <div style={{ display: "block" }}>
            <If condition={record.external_part_id !== null && record.external_part_id !== undefined}>

              <If condition={record.external_part_order_id !== null}>
                <If condition={record.external_part_order?.status === "PendingApproval"}>
                  <i>
                    Part Order has been prepared! Waiting on Employee Approval
                    <If condition={record?.override_order_details_shop_location_id !== null && record?.override_order_details_shop_location_id !== undefined}>
                      &nbsp;(To: {shopLocationDeliveryToOverridenName})
                    </If>
                  </i>
                  <Else />
                  <i>
                    Part ordered from Vendor
                    <If condition={record?.override_order_details_shop_location_id !== null && record?.override_order_details_shop_location_id !== undefined}>
                      &nbsp;(To: {shopLocationDeliveryToOverridenName})
                    </If>
                  </i>
                </If>
                <Else />
                <If condition={record.submit_order === true}>
                  <i>
                    Part will be ordered from Vendor
                    <If condition={record?.override_order_details_shop_location_id !== null && record?.override_order_details_shop_location_id !== undefined}>
                      &nbsp;(To: {shopLocationDeliveryToOverridenName})
                    </If>
                  </i>
                  <Else />
                  <i>Order won't be submitted to Vendor</i>
                </If>
              </If>
              <Else />
              <i>Part order won't be made</i>
            </If>

            <If condition={(record.part_cancellations || []).length > 0}>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  // open in new tab the url: part-cancellations?page=1&job_id=record.id
                  window.open(`/part-cancellations?attributes=job_id%3D${record.id}&page=1`, "_blank");
                }}
              >
                See Part Cancellations
              </Button>

              <i>You don't need to delete the NAGS Part# or any of the details</i>
            </If>

            <If
              condition={
                record.external_part_order?.status !== "PendingApproval"
                &&
                record.external_part_order?.status !== "Pending"
              }
            >
              {/* If we don't have to submit an order on save, display an option to cancel the order */}
              {/* Additionally, only show the cancel order button if there are no part cancellations */}
              <If condition={!record.submit_order && areNoPartCancellationsPerformed}>
                <Button
                  type="primary"
                  style={{ marginTop: "15px", marginBottom: "15px" }}
                  onClick={() => {
                    if (record.inventory_source === "Atlas Inventory" && record.external_part_order_id) {
                      cancelOrderQRScan(record);
                    } else {
                      cancelOrderNonQRScan(record);
                    }
                  }}
                > Cancel Order </Button>
              </If>
              {/* If we have to submit an order on save, display a disabled button */}
              {/* Additionally, only show the (disabled) cancel order button if there are no part cancellations */}
              <If condition={record.submit_order && areNoPartCancellationsPerformed}>
                <Tooltip
                  title="Cancel Order is disabled if you just added a part that needs to be ordered 
                    and you have not yet saved the current Job Form. Reason for this is that item is not yet ordered - 
                    it gets ordered when the Job Form is saved."
                >
                  <Button
                    type="primary"
                    disabled={true}
                  > Cancel Order </Button>
                </Tooltip>
              </If>
            </If>
          </div>
        </If>
        <If condition={dataIndex === "glass_type" && areArraysEqual(record.glass_type, ["Other"])}>
          <p>{record.glass_type_other_value}</p>
        </If>
        <If condition={dataIndex === "glass_type" && (areArraysEqual(record.glass_type, ["Windshield"]) || record.glass_type === "Windshield") && isGlassReplacementJobParent}>
          <If condition={record.calibration_pricing !== null && record.calibration_pricing !== undefined}>
            <If condition={parseFloat(record.calibration_pricing) !== 0}>
              <p style={{ fontWeight: record.calibration_pricing_total ? "normal" : "bolder" }}>
                + {DollarFormatter(record.calibration_pricing)} Calibration Fee
              </p>
            </If>
          </If>
          <If condition={record.adas_declined_status === "Yes"}>
            <p>ADAS Declined!</p>
          </If>
          <If condition={record.calibration_pricing_total && isInsuranceWorkType}>
            <div style={{ marginTop: "15px" }}>
              <i style={{ fontWeight: "bolder" }}> Calibration Fee Final: </i>
              <span style={{ display: "block" }}>{DollarFormatter(record.calibration_pricing_total)}</span>
            </div>
          </If>
        </If>
        <If condition={dataIndex === "inventory_source"}>
          <If condition={record.inventory_source === "Other"}>
            <p>{record.inventory_source_other_value}</p>
          </If>
          <If condition={record.inventory_source === "Atlas Inventory" && record.external_part_order !== null}>
            <If condition={QRSCAN_JOB_CURRENT_STATUS_MAPPINGS[record.external_part_order?.external_order_status] !== undefined}>
              <i style={{ display: "block", marginTop: "15px" }}>Status: {QRSCAN_JOB_CURRENT_STATUS_MAPPINGS[record.external_part_order.external_order_status]} </i>
            </If>
          </If>
        </If>
        <If condition={dataIndex === "tint_pricing" && record.tint_pricing?.value.split(";;;")[0] !== "null"}>
          <p>{record.tint_pricing?.label}</p>
        </If>
        <If condition={dataIndex === "tint_pricing" && record.tint_pricing?.value.split(";;;")[0] === "null"}>
          <p>{record.tint_pricing.label}</p>
          <p>{DollarFormatter(record.tint_price)}</p>
        </If>
      </If>
    </If>
  );

  let specialBadgeValues = [];
  if ((dataIndex === "glass_type" || dataIndex === "job_description")) {
    if (record.is_warranty_work) {
      specialBadgeValues.push("Warranty Work");
    }
    if (record.is_created_as_split_job) {
      specialBadgeValues.push("Created as Split Job");
    }
    if (record.has_jobs_splitted_from_this) {
      specialBadgeValues.push("Job Split");
    }
  }

  return (
    <If condition={(dataIndex === "glass_type" || dataIndex === "job_description") && specialBadgeValues.length > 0}>
      <td style={{ width: width ? width : 'auto' }} {...restProps}>
        <Badge.Ribbon
          text={specialBadgeValues.join(", ")}
          placement="start"
          style={{ width: width ? width : 'auto' }}
          {...restProps}
        >
          {cellContent}
        </Badge.Ribbon>
      </td>
      <Else />
      <td
        style={{ width: width ? width : 'auto' }}
        {...restProps}
      >
        {cellContent}
      </td >
    </If >
  );
};

const EditableTable = ({
  form,
  initialData,
  setDataRecords,
  columns,
  editingKey,
  setEditingKey,
  vehicles,
  technicianEmployees,
  shopLocations = [],
  displayTintingOptionsButton = false,
  isGlassReplacementJobParent = false,
  tintPricings = [],
  scrollableWidth = 1300,
  intakeFormFormObject = undefined,
  intakeForm = null,
  isInsuranceWorkType = true,
  expandableTableObject = undefined,
  onValuesChange = () => { },
  setExpertUnpromptedPartReviews = () => { },
  ...restProps
}) => {

  const { users: accountUsers } = useContext(AccountUsersSystemContext);

  const [data, setData] = useState(initialData);

  const [priceSum, setPriceSum] = useState(0);

  const [isTintPricingModalVisible, setIsTintPricingModalVisible] = useState(false);
  const [tintPricing, setTintPricing] = useState(undefined);
  const [customTintPricing, setCustomTintPricing] = useState(0);
  const [isAddEditUnpromptedPartReviewModalVisible, setIsAddEditUnpromptedPartReviewModalVisible] = useState(false);
  const [unpromptedPartReviewGlassReplacementJob, setUnpromptedPartReviewGlassReplacementJob] = useState(null);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    console.log("edit, record", record);
    form.setFieldsValue(record);
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const addNewRecord = () => {

    // if there is only one vehicle, auto select that vehicle
    const isOnlyOneVehicle = (vehicles || []).length === 1

    // setting the default install status to be "InstallationNotStarted"
    const defaultInstallStatus = INSTALL_STATUS_VALUES.find(x => x.value === "InstallationNotStarted")?.value;

    const newData = {
      key: uuidv4(),
      userAdded: true,
      install_status: defaultInstallStatus,
    };

    if (isOnlyOneVehicle) {
      newData["intake_form_vehicle_id"] = vehicles[0].id.toString();
    }

    setData([...data, newData]);
    setDataRecords([...data, newData]);
    setEditingKey('');
    form.resetFields();

    // have to set the form fields values
    form.setFieldsValue({ "install_status": defaultInstallStatus })
    if (isOnlyOneVehicle) {
      form.setFieldsValue({ "intake_form_vehicle_id": vehicles[0].id.toString() })
    }

    setEditingKey(newData.key);
  };

  const handleDeleteRecord = (key) => {
    setDataRecords(data.filter((item) => item.key !== key));
    setData(data.filter((item) => item.key !== key));
    setEditingKey('');
    form.resetFields();
  };

  const save = async (key, endEditing = true, skipValidation = false) => {
    try {
      let row = null;
      if (skipValidation) {
        row = form.getFieldsValue();
      } else {
        row = await form.validateFields();
      }
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setDataRecords(newData);
      } else {
        newData.push(row);
        setData(newData);
        setDataRecords(newData);
      }
      if (endEditing) {
        setEditingKey('');
        form.resetFields();
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      message.warning("Please fill out all required fields");
    }
  };

  const editDeleteActionsNode = (record, disabled, allowPartialEdit, isGlassReplacementJobParent) => {
    const editNode = (
      <>
        <If condition={allowPartialEdit}>
          <Typography.Link style={{ display: "block" }} disabled={false} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        </If>
        <If condition={!allowPartialEdit}>
          <Typography.Link style={{ display: "block" }} disabled={editingKey !== '' || disabled} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        </If>
      </>
    )

    const isNewlyAdded = record?.userAdded;
    const isAddExpertReviewButtonDisabled = isNewlyAdded || disabled || record.submit_order;
    const doesHavePartCancellations = (record?.part_cancellations || []).length > 0;
    return (
      <>
        {editNode}
        <Typography.Link
          style={{ display: "block" }}
          disabled={editingKey !== '' || disabled}
          onClick={() => {
            if (doesHavePartCancellations) {
              message.warning("Cannot delete a part that has been cancelled. You might want to set the 'Our Cost' or 'Glass Sale Price' value(s) for this job to 0.", 10);
              return;
            }
            handleDeleteRecord(record.key)
          }}
        >
          Delete
        </Typography.Link>
        <If condition={isGlassReplacementJobParent && GetCurrentLoggedInUser(accountUsers).is_expert_tech && !(editingKey !== '' || disabled)}>
          <Tooltip title={!isAddExpertReviewButtonDisabled ? null : disabled || record.submit_order ? "Cannot add expert review to already ordered glass parts" : "Please save the job first, and reopen to provide Expert Review"}>
            <div style={{ display: "block" }}>
              <Typography.Link
                style={{ margin: "0px", padding: "0px", pointerEvents: isAddExpertReviewButtonDisabled ? "none" : "auto" }}
                disabled={isAddExpertReviewButtonDisabled}
                size="small"
                onClick={() => {
                  setIsAddEditUnpromptedPartReviewModalVisible(true);
                  setUnpromptedPartReviewGlassReplacementJob(record);
                  form.setFieldsValue(record);
                  setEditingKey(record.key);
                }}>
                Add Expert Review
              </Typography.Link>
            </div>
          </Tooltip>
        </If>
      </>
    );
  }

  columns = [
    ...columns,
    {
      title: 'Action',
      dataIndex: 'operation',
      fixed: "right",
      width: "95px",
      render: (_, record) => {
        if (record.is_edit_forbidden) {
          return (
            <>
              <p style={{ display: "block", marginBottom: "15px" }}>
                Editing is not allowed  - Job entry is from original Intake Form
              </p>
            </>
          )
        }
        const editable = isEditing(record);
        let disabled = false;
        if (record?.cancel_order_enabled) disabled = true;

        return (
          <div style={{ paddingRight: "2px", paddingLeft: "2px" }}>
            <If condition={editable}>
              <If condition={displayTintingOptionsButton}>
                <Button
                  size="small"
                  type="primary"
                  style={{ display: "block", marginBottom: "15px" }}
                  onClick={() => setIsTintPricingModalVisible(true)}
                > Tinting </Button>
              </If>
              <span>
                <Typography.Link
                  onClick={() => {
                    try {
                      if (isGlassReplacementJobParent) {
                        restProps["onFinishEditingFetchAGCRMNagsPartNr"]();
                      }
                    } catch (e) {
                      alert("Error while invoking onFinishEditingFetchAGCRMNagsPartNr");
                    }
                    save(record.key)
                  }}
                  style={{
                    display: "block",
                  }}
                >
                  Save
                </Typography.Link>
                <Popconfirm title="Are you sure you want to cancel?" onConfirm={cancel}>
                  <a
                    href="#d"
                    style={{
                      display: "block",
                    }}
                  >Cancel</a>
                </Popconfirm>
              </span>
            </If>

            <If condition={!editable}>
              <If condition={record.tint_pricing?.label !== undefined}>
                <p style={{ marginBottom: "15px" }}>
                  TINTING: {record.tint_pricing?.label}
                  <If condition={record.tint_pricing?.label === "Custom Tinting"}>
                    <span>&nbsp;(${record.tint_price})</span>
                  </If>
                </p>
              </If>
              <If condition={disabled}>
                <Tooltip title="You need to cancel the order before you are able to fully edit or delete this record. Partial Edits are supported. Some vendors don't allow the option to cancel the order">
                  {editDeleteActionsNode(record, disabled, true, isGlassReplacementJobParent)}
                </Tooltip>
                <Else />
                {editDeleteActionsNode(record, disabled, false, isGlassReplacementJobParent)}
              </If>
            </If>
            <AddUnpromptedPartReviewModal
              isModalVisible={isAddEditUnpromptedPartReviewModalVisible}
              setIsModalVisible={setIsAddEditUnpromptedPartReviewModalVisible}
              glassReplacementJob={unpromptedPartReviewGlassReplacementJob}
              onSave={(partReviewObject) => {
                partReviewObject.expert_tech_user_assigned = GetCurrentLoggedInUser(accountUsers);
                partReviewObject.glass_replacement_job = unpromptedPartReviewGlassReplacementJob;

                setExpertUnpromptedPartReviews((curr) => {
                  return [...curr, partReviewObject];
                });

                const createObjectParams = {
                  internal_user_part_number_response: partReviewObject.internal_user_part_number_response,
                  internal_user_detailed_response: partReviewObject.internal_user_detailed_response,
                  job_original_part_number: partReviewObject.job_original_part_number,
                  intake_form_id: intakeForm?.id,
                  glass_replacement_job_id: unpromptedPartReviewGlassReplacementJob.id,
                }
                addUnpromptedExpertReview(createObjectParams).then((res) => {
                  const { data } = res;
                  message.success("Successfully created unprompted expert part review");
                }).catch((err) => {
                  message.error(err?.response?.data?.detail || "Something went wrong while creating unprompted expert part review - please contact support!");
                });
                form.setFieldsValue({ nags_part_nr: partReviewObject.internal_user_part_number_response });
                save(editingKey);
              }}
            />
          </div>
        )
      },
    },
  ];

  // we needed tint pricing as button in Actions column instead of a standalone column
  // by filtering it out, we won't have tint pricing as column
  const filteringOptionFilteredOut = columns.filter(col => col.dataIndex !== "tint_pricing")

  const mergedColumns = filteringOptionFilteredOut.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        vehicles: vehicles,
        technicianEmployees: technicianEmployees,
        ddlOptions: col.ddlOptions,
        label: col.label,
        type: col.type,
        record,
        className: col.className,
        inputType: col.inputType,
        required: col.required,
        dataIndex: col.dataIndex,
        title: col.title,
        clearable: col.clearable,
        multiple: col.multiple,
        size: col.size,
        editable: col.editable,
        editing: isEditing(record),
        form: form,
        width: col.width,
        saveAction: save,
        shopLocations: shopLocations,
        isGlassReplacementJobParent: isGlassReplacementJobParent,
        isInsuranceWorkType: isInsuranceWorkType,
        ...restProps
      }),
    };
  });

  useEffect(() => {
    console.log(data);
    let price = 0;
    for (const jobEntry of data) {
      let tintPrice = 0;
      // special calculation for tint price
      if (jobEntry.tint_pricing !== undefined && jobEntry.tint_pricing !== null) {
        if (jobEntry.tint_pricing.value.split(";;;")[0] === "null") {
          tintPrice = parseFloat(jobEntry.tint_price);
        } else {
          tintPrice = parseFloat(jobEntry.tint_pricing?.value.split(";;;")[0]);
        }
      } else {
        tintPrice = parseFloat(jobEntry.tint_price);
      }
      if (!isNaN(tintPrice)) {
        price = price + tintPrice;
      }

      let glassPrice = (isInsuranceWorkType ? jobEntry.glass_price_total : null) || jobEntry.glass_price;
      glassPrice = parseFloat(glassPrice);

      const price_ = parseFloat(jobEntry.price);
      if (!isNaN(glassPrice)) {
        price = price + glassPrice;
      }

      if (!isNaN(price_)) {
        price = price + price_;
      }

      let calibrationPrice = (isInsuranceWorkType ? jobEntry.calibration_pricing_total : null) || jobEntry.calibration_pricing;
      if (calibrationPrice !== undefined && calibrationPrice !== null) {
        calibrationPrice = parseFloat(calibrationPrice);
        if (!isNaN(calibrationPrice)) {
          price = price + calibrationPrice;
        }
      }
    }
    price = roundNumber(price)
    setPriceSum(price);
  }, [data, isInsuranceWorkType])

  useEffect(() => {
    if (isTintPricingModalVisible) {
      const formFields = form.getFieldsValue();
      setTintPricing(formFields.tint_pricing);
      setCustomTintPricing(formFields.tint_price);
    }
  }, [isTintPricingModalVisible])

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  return (
    <>
      <Form
        form={form}
        component={false}
        onValuesChange={onValuesChange}>
        <Form.Item
          style={{ display: "hidden", height: "0", margin: "0" }}
          name="tint_pricing"
          initialValue={undefined}
        >
        </Form.Item>
        <Form.Item
          style={{ display: "hidden", height: "0", margin: "0" }}
          name="tint_price"
          initialValue={null}
        >
        </Form.Item>

        <If condition={isGlassReplacementJobParent}>
          <Form.Item
            style={{ display: "hidden", height: "0", margin: "0" }}
            name="internal_help_needed"
            initialValue={false}
          >
          </Form.Item>
          <Form.Item
            style={{ display: "hidden", height: "0", margin: "0" }}
            name="ag_crm_help_needed"
            initialValue={false}
          >
          </Form.Item>
          <Form.Item
            style={{ display: "hidden", height: "0", margin: "0" }}
            name="cancel_order_enabled"
            initialValue={false}
          >
          </Form.Item>
          <Form.Item
            style={{ display: "hidden", height: "0", margin: "0" }}
            name="submit_order"
            initialValue={false}
          >
          </Form.Item>
          <Form.Item
            style={{ display: "hidden", height: "0", margin: "0" }}
            name="driver_user_id"
            initialValue={null}
          >
          </Form.Item>
          <Form.Item
            style={{ display: "hidden", height: "0", margin: "0" }}
            name="external_part_id"
            initialValue={null}
          >
          </Form.Item>
          <Form.Item
            style={{ display: "hidden", height: "0", margin: "0" }}
            name="external_part_order_id"
            initialValue={null}
          >
          </Form.Item>
          <Form.Item
            style={{ display: "hidden", height: "0", margin: "0" }}
            name="override_order_details_shop_location_id"
            initialValue={null}
          >
          </Form.Item>
          <Form.Item
            style={{ display: "hidden", height: "0", margin: "0" }}
            name="delivery_time"
            initialValue={null}
          >
          </Form.Item>
          <Form.Item
            style={{ display: "hidden", height: "0", margin: "0" }}
            name="qrscan_inventory_location"
            initialValue={null}
          >
          </Form.Item>
        </If>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          scroll={{ x: scrollableWidth }}
          bordered
          dataSource={data}
          size="small"
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
          expandable={expandableTableObject ? expandableTableObject : null}
        />
      </Form>
      <Row style={{ marginBottom: '15px' }}>
        <Col>
          <Button type="primary" onClick={addNewRecord}> Add Record </Button>
        </Col>
        <Col flex="auto"> </Col>
        <Col>
          <p align="right"> {DollarFormatter(priceSum)} - Total </p>
        </Col>
      </Row>
      <TintPricingComponent
        editingKey={editingKey}
        tintPricing={tintPricing}
        setTintPricing={setTintPricing}
        customTintPricing={customTintPricing}
        setCustomTintPricing={setCustomTintPricing}
        saveAction={save}
        form={form}
        tintPricings={tintPricings}
        isModalVisible={isTintPricingModalVisible}
        setIsModalVisible={setIsTintPricingModalVisible}
      />
    </>
  );
};

export default EditableTable