import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import prompt from 'antd-prompt';
import { Table, Form, Button, Space, Select, message, Collapse, Modal, List, Spin, Image } from "antd";

import EditableTable from "../editableTableComponent";
import { GLASS_LOGISTICS_STATUS, GLASS_TYPE_CASCADER_VALUES, INSTALL_STATUS_VALUES, INVENTORY_GLASS_SOURCES } from "../../global_constants";
import { badgeElement, GetTechnicianEmployeeSelectionComponentForJobs, GetVehicleSelectionComponentForJobs, nicelyFormatPascalCaseString, replaceCharacter } from '../../helpers';
import { getAllTintPricings, getAllPartsForVin, getAllQRScanPartsForPartNumber, getXYGPartsForNags, setAcknowledgedForJobReview, cancelASale, getAGCRMNagsPartNrSearchResult, getAllPartsForVehicleInfo, getAllMakes, getExtraDetailsForNagsNumber, addPartCancellation } from '../../actions';
import PriceCheckDrawerComponent from "../priceCheckDrawerComponent";
import DeliveryTimeDriverModalComponent from "../deliveryTimeDriverModalComponent";

import ReviewJobReviewModal from "../../pages/jobReviews/reviewJobReviewModal";
import VehicleSelectionPartsLookupComponent from "../vehicleSelectionPartsLookupComponent";
import AGCRMNagsSearchResult from "../AGCRMNagsSearchResult";
import AGCRMPartsLookupDrawerComponent from "../agCRMPartsLookupDrawerComponent";
import DisplayNagsPartNrNotesModalComponent from "../displayNagsPartNrNotesModalComponent";
import NagsPartNrNotesListComponent from "../nagsPartNrNotesListComponent";
import PilkingtonDataPanelComponent from "../pilkingtonDataPanelComponent";
import SharedIntakeFormTableBrief from "../sharedIntakeFormTableBrief";

const { Option } = Select;
const { Panel } = Collapse;

const GlassReplacementJobEntryTable = ({
  glassReplacementJobEntries,
  setGlassReplacementJobEntries,
  intakeFormFormObject,
  editingKey,
  setEditingKey,
  unacknowledgedJobReviews,
  setUnacknowledgedJobReviews,
  expertUnpromptedPartReviews,
  setExpertUnpromptedPartReviews,
  refreshUnacknowledgedJobReviews,
  setIsGlassPartMarkedForSale,
  isInsuranceWorkType,
  vehicles,
  nagsPartNrAGCRMInstallInfos,
  setNagsPartNrAGCRMInstallInfos,
  technicianEmployees,
  shopLocations,
  isMobileJobLocation,
  isQuoteOnlyLocationSelected,
  intakeForm
}) => {
  const history = useHistory();
  const search = useLocation().search;

  const [form] = Form.useForm();

  const [tintPricings, setTintPricings] = useState([]);

  const [isProvideFeedbackModalVisible, setIsProvideFeedbackModalVisible] = useState(false);
  const [selectedJobReview, setSelectedJobReview] = useState(null);

  const [partsLookupResult, setPartsLookupResult] = useState({});
  const [partsLookupLoading, setPartsLookupLoading] = useState(false);
  const [xygPartsLookupResult, setXygPartsLookupResult] = useState([]);
  const [partLookupDrawerVisible, setPartLookupDrawerVisible] = useState(false);
  const [internalInventoryPartsLookupResult, setInternalInventoryPartsLookupResult] = useState([]);
  const [internalInventoryPartsLookupLoading, setInternalInventoryPartsLookupLoading] = useState(false);
  const [isInternalInventoryPartsModalVisible, setIsInternalInventoryPartsModalVisible] = useState(false);

  const [isPriceCheckDrawerVisible, setIsPriceCheckDrawerVisible] = useState(false);
  const [selectedNagsPartNr, setSelectedNagsPartNr] = useState(null);

  const [isDeliveryTimeDriverModalVisible, setIsDeliveryTimeDriverModalVisible] = useState(false);
  const [selectedVendorName, setSelectedVendorName] = useState(null);
  const [selectedVendorItem, setSelectedVendorItem] = useState(null);

  const [dataDump, setDataDump] = useState(null);
  const [isDataDumpModalVisible, setIsDataDumpModalVisible] = useState(false);

  const [isVehicleVinSelectionModalVisible, setIsVehicleVinSelectionModalVisible] = useState(false);
  const [selectedVehicleRecord, setSelectedVehicleRecord] = useState(null);

  const [AGCRMSearchResult, setAGCRMSearchResult] = useState(null);
  const [isAGCRMNagsSearchResultsModalVisible, setIsAGCRMNagsSearchResultsModalVisible] = useState(false);
  const [isAGCRMNagsSearchActive, setIsAGCRMNagsSearchActive] = useState(false);

  const [isDisplayNagsPartNrNotesModalVisible, setIsDisplayNagsPartNrNotesModalVisible] = useState(false);

  const [possibleMakes, setPossibleMakes] = useState([]);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandedData, setExpandedData] = useState({});

  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    setIsDataDumpModalVisible(false);
  };

  const handleCancel = () => {
    setIsDataDumpModalVisible(false);
  };

  const fetchAllMakes = () => {
    getAllMakes().then((response) => {
      const { data } = response;
      setPossibleMakes(data.map((x) => x.make));
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    })
  }

  const getNagsDetailsExtraData = (newlyExpandedId, nagsNumber) => {
    setLoading(true);
    getExtraDetailsForNagsNumber(null, nagsNumber).then((res) => {
      setLoading(false);
      const { data } = res;
      setExpandedData((curr) => {
        curr[newlyExpandedId] = data;
        return { ...curr, loading: false };
      })
    }).catch((err) => {
      setLoading(false);
      message.error(err?.response?.data?.detail || "Error getting extra details for NAGS number");
    })
  }

  const columns = [
    {
      title: 'Glass Type',
      inputType: "cascadedDDL",
      required: true,
      editable: true,
      dataIndex: 'glass_type',
      ddlOptions: GLASS_TYPE_CASCADER_VALUES,
      //fixed: 'left',
      width: '150px'
    },
    GetVehicleSelectionComponentForJobs(vehicles),
    GetTechnicianEmployeeSelectionComponentForJobs(technicianEmployees),
    Table.EXPAND_COLUMN,
    {
      title: 'NAGS Part #',
      inputType: "text",
      required: false,
      dataIndex: 'nags_part_nr',
      editable: true,
      width: '150px'
    },
    {
      title: "Inventory Source",
      inputType: "ddl",
      required: false,
      editable: true,
      clearable: true,
      dataIndex: 'inventory_source',
      ddlOptions: INVENTORY_GLASS_SOURCES,
      width: '150px'
    },
    {
      title: "Install Status",
      inputType: "ddl",
      required: true,
      editable: true,
      clearable: false,
      dataIndex: 'install_status',
      ddlOptions: INSTALL_STATUS_VALUES,
      width: '150px'
    },
    {
      title: "Glass Logistics Status",
      inputType: "ddl",
      required: false,
      editable: true,
      clearable: true,
      dataIndex: 'glass_logistics_status',
      ddlOptions: GLASS_LOGISTICS_STATUS,
      width: '150px'
    },
    {
      title: 'Our Cost',
      inputType: "currency",
      required: false,
      editable: true,
      dataIndex: 'cost',
      className: "hide-price-stepper",
      width: '90px'
    },
    {
      title: 'Tint Pricing',
      inputType: "ddl",
      required: false,
      editable: true,
      clearable: true,
      dataIndex: 'tint_pricing',
      width: '150px',
      ddlOptions: tintPricings,
    },
    {
      title: 'Glass Sale Price',
      inputType: "currency",
      required: false,
      editable: true,
      dataIndex: 'glass_price',
      className: "hide-price-stepper",
      width: '90px'
    },
    {
      title: 'Total Sale Price',
      inputType: "total_price",
      required: false,
      editable: true,
      dataIndex: 'glass_total_price',
      width: '90px'
    },
  ];

  const selectVendorPriceCheck = (priceCheckVendorObj, selectedVendorItem = null) => {
    form.setFieldsValue({ inventory_source: priceCheckVendorObj.vendor });
    if (selectedVendorItem !== null) {
      let nagsNumberForm = selectedVendorItem.nags_part_nr;
      if (priceCheckVendorObj.vendor === "American") {
        nagsNumberForm = replaceCharacter(nagsNumberForm, 0, "");
        nagsNumberForm = replaceCharacter(nagsNumberForm, 0, "");
        nagsNumberForm = replaceCharacter(nagsNumberForm, 2, "");
      }
      form.setFieldsValue({
        cost: selectedVendorItem.price,
        nags_part_nr: nagsNumberForm
      });
    }

    const glassTypePreselection = [
      {
        prefix: "FW",
        glassType: "Windshield"
      },
      {
        prefix: "DW",
        glassType: "Windshield"
      },
      {
        prefix: "FB",
        glassType: "BackGlassBackWindshield"
      },
      {
        prefix: "DB",
        glassType: "BackGlassBackWindshield"
      }
    ]

    for (const preselectData of glassTypePreselection) {
      if ((selectedNagsPartNr || "").toLowerCase().startsWith(preselectData.prefix.toLowerCase())) {
        form.setFieldsValue({ glass_type: [preselectData.glassType] });
        break;
      }
    }

    setSelectedVendorName(priceCheckVendorObj.vendor);
    setSelectedVendorItem(selectedVendorItem);
    setIsDeliveryTimeDriverModalVisible(true);
  }

  const deliveryTimeDriverSelectionCompleted = (time, partId = null, driverId = null, submitAnOrder = false, selectedVendorItem = null, selectedOverridenShopLocation = null) => {
    form.setFieldsValue({
      delivery_time: time,
      external_part_id: partId,
      driver_user_id: driverId,
      submit_order: submitAnOrder,
      override_order_details_shop_location_id: selectedOverridenShopLocation
    });

    // we override the partId in display message if the vendor is Mygrant because Mygrant has JSON string for partID handling in backedn and it looks ugly
    if (selectedVendorItem !== null) {
      if (selectedVendorItem.source === "Mygrant") {
        partId = selectedVendorItem.nags_part_nr
      }
    }

    if (submitAnOrder) {
      const displayMessage = `On Job Saved, Sales Portal will make an order to ${selectedVendorName}, for a part ID: ${partId} at the following time: ${time}`;
      message.success(displayMessage, 5);
    }
    setIsDeliveryTimeDriverModalVisible(false);
  }

  const performPriceCheck = (record) => {
    const partNumber = form.getFieldsValue()?.nags_part_nr || "";
    if (partNumber === null || partNumber === "") {
      message.warning("NAGS Part # field must be filled out with part # for search to work");
      return;
    }
    setIsPriceCheckDrawerVisible(true);
    setSelectedNagsPartNr(partNumber);
  }

  const internalHelpRequest = () => {
    form.setFieldsValue({ internal_help_needed: true });
    message.info("Help will be requested from expert techs in our company after the current Form is saved");
  }

  const onFinishEditingFetchAGCRMNagsPartNr = () => {

    const nagsPartNr = form.getFieldValue("nags_part_nr");
    if (nagsPartNr === null || nagsPartNr === undefined) {
      return;
    }
    getAGCRMNagsPartNrSearchResult(nagsPartNr, true).then((response) => {
      const { data } = response;
      let currentNagsPartNrAGCRMInstallInfos = { ...nagsPartNrAGCRMInstallInfos };
      for (const iterator of data) {
        currentNagsPartNrAGCRMInstallInfos[iterator.part_number] = iterator;
      }
      setNagsPartNrAGCRMInstallInfos(curr => currentNagsPartNrAGCRMInstallInfos);
    }).catch((err) => {
      message.warning(err?.response?.data?.detail || "Unknown error while fetching AG CRM Nags Part Install info - please contact developer with information about how this occured.");
    })
  }

  const AGCrmNagsSearch = () => {
    if (isAGCRMNagsSearchActive) {
      message.warning("AG CRM Nags search already active!");
      return;
    }

    const nagsPartNr = form.getFieldValue("nags_part_nr");
    if (nagsPartNr === null || nagsPartNr === undefined) {
      message.error("Please enter a valid NAGS Part Number!");
      return;
    }

    setIsAGCRMNagsSearchActive(true);
    setIsAGCRMNagsSearchResultsModalVisible(true);
    setAGCRMSearchResult(null);

    getAGCRMNagsPartNrSearchResult(nagsPartNr, false, true).then((response) => {
      setIsAGCRMNagsSearchActive(false);
      const { data } = response;
      setAGCRMSearchResult(data);
      console.log("getAGCRMNagsPartNrSearchResult", data);
    }).catch((err) => {
      setIsAGCRMNagsSearchActive(false);
      message.warning(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    })
  }

  const fetchPartNotes = (record) => {
    const partNumber = form.getFieldsValue()?.nags_part_nr || "";
    if (partNumber === null || partNumber === "") {
      message.warning("NAGS Part # field must be filled out with part # for search to work");
      return;
    }
    setIsDisplayNagsPartNrNotesModalVisible(true);
    setSelectedNagsPartNr(partNumber);
  }

  const fetchPartNr = (record) => {
    if (vehicles.length === 0) {
      message.error("No vehicle is added to this Job Form, please add one!");
      return;
    }

    setIsVehicleVinSelectionModalVisible(true);
    setSelectedVehicleRecord(record);
  }

  const partsLookupForVin = (record, vin) => {
    if (record === null) {
      record = selectedVehicleRecord;
    }
    if (vin === undefined || vin === null) {
      message.warning("VIN is not entered - please enter valid VIN!");
      return;
    }
    if (vin.length !== 17) {
      message.warning("VIN is not valid, each VIN must consist of 17 characters! Please enter valid VIN!");
      return;
    }

    setIsVehicleVinSelectionModalVisible(false);
    setPartLookupDrawerVisible(true);
    setPartsLookupLoading(true);

    const glassType = record.glass_type.at(-1);
    getAllPartsForVin(vin, glassType).then((response) => {
      setPartsLookupLoading(false);
      const { data } = response;
      setPartsLookupResult(data);

      getXYGPartsForNags((data?.parts || []).map((part) => part.part_number)).then((response) => {
        const { data } = response;
        setXygPartsLookupResult(data);
      }).catch((err) => {
        message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
      })
    }).catch((err) => {
      setPartsLookupLoading(false);
      message.warning(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    })
  }

  const partsLookupForVehicleInfo = (record, vehicleInfo) => {
    if (record === null) {
      record = selectedVehicleRecord;
    }
    if (vehicleInfo === undefined || vehicleInfo === null) {
      message.warning("Vehicle info is not entered - please enter valid Vehicle info!");
      return;
    }

    setIsVehicleVinSelectionModalVisible(false);
    setPartLookupDrawerVisible(true);
    setPartsLookupLoading(true);

    const glassType = record.glass_type.at(-1);
    vehicleInfo.glass_type = glassType;

    getAllPartsForVehicleInfo(vehicleInfo).then((response) => {
      setPartsLookupLoading(false);
      const { data } = response;
      setPartsLookupResult(data);

      getXYGPartsForNags((data?.parts || []).map((part) => part.part_number)).then((response) => {
        const { data } = response;
        setXygPartsLookupResult(data);
      }).catch((err) => {
        message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
      })
    }).catch((err) => {
      setPartsLookupLoading(false);
      message.warning(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    })
  }

  const onNagsPartChangeHandler = () => {
    form.setFieldsValue({ external_part_id: null });
  }

  const checkPartNrInternalAvailability = () => {
    const partNumber = form.getFieldsValue()?.nags_part_nr || "";
    if (partNumber === null || partNumber === "") {
      message.warning("NAGS Part # field must be filled out with part # for search to work");
      return;
    }
    setIsInternalInventoryPartsModalVisible(true);
    setInternalInventoryPartsLookupLoading(true);
    getAllQRScanPartsForPartNumber(partNumber).then((response) => {
      setInternalInventoryPartsLookupLoading(false);
      const { data } = response;
      setInternalInventoryPartsLookupResult(data);
    }).catch((err) => {
      setInternalInventoryPartsLookupLoading(false);
      setIsInternalInventoryPartsModalVisible(false);
      message.warning(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    })
  }

  const getTintPricings = () => {
    getAllTintPricings().then((response) => {
      const { data } = response;
      setTintPricings(data.map((tint) => {
        tint.label = tint.name + " " + tint.warranty_info + " $" + tint.price;
        if (tint.price === null) {
          tint.label = tint.name
        }
        tint.value = tint.price;
        tint.key = tint.name;
        return tint;
      }))
    }).catch((err) => {
      message.error("Couldn't load all pricings!")
    })
  }

  const cancelOrderQRScan = async (record) => {
    if (editingKey !== "") {
      message.warning("Please finish editing the current active record before you perform undo sale action!");
      return;
    }
    if (record.inventory_source === "Atlas Inventory") {
      let reason = null;
      try {
        reason = await prompt({
          title: "Please provide a reason for cancellation",
          placeholder: "Cancellation Reason",
          rules: [
            {
              required: true,
              message: "You must enter a reason for cancellation"
            }
          ],
          modalProps: {
            width: '80%'
          }
        });
      } catch (e) {
        message.warning('Cancellation reason not captured!');
        return;
      }
      cancelASale(record.id, reason).then((response) => {
        const { data } = response;
        const { details } = data;
        const { part_cancellation } = details;

        setGlassReplacementJobEntries((currentValues) => {
          return currentValues.map((item) => {
            if (item.id === record.id) {
              item.external_part_order_id = null
              item.external_part_id = null;
              item.cancel_order_enabled = false;

              // check if item.part_cancellations is array, if not, make it an array
              if (!Array.isArray(item.part_cancellations)) {
                item.part_cancellations = [];
              }
              // add the new cancellation to the list of cancellations
              item.part_cancellations = [...item.part_cancellations, part_cancellation];
            }
            return item;
          })
        })

        message.info("Undo sale action has been processed!");
        message.info("Cancellation has been processed!");
      }).catch((err) => {
        message.warning(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
      })
    }
  }

  const cancelOrderNonQRScan = async (record) => {
    if (editingKey !== "") {
      message.warning("Please finish editing the current active record before you perform cancel order!");
      return;
    }
    let reason = null;
    try {
      reason = await prompt({
        title: "Please provide a reason for cancellation",
        placeholder: "Cancellation Reason",
        rules: [
          {
            required: true,
            message: "You must enter a reason for cancellation"
          }
        ],
        modalProps: {
          width: '80%'
        }
      });
    } catch (e) {
      message.warning('Cancellation reason not captured!');
      return;
    }
    const payload = {
      "reason": reason,
      "glass_replacement_job_id": record.id
    }
    addPartCancellation(payload).then((response) => {
      const { data } = response;
      setGlassReplacementJobEntries((currentValues) => {
        return currentValues.map((item) => {
          if (item.id === record.id) {
            item.external_part_order_id = null
            item.external_part_id = null;
            item.cancel_order_enabled = false;

            // check if item.part_cancellations is array, if not, make it an array
            if (!Array.isArray(item.part_cancellations)) {
              item.part_cancellations = [];
            }
            // add the new cancellation to the list of cancellations
            item.part_cancellations = [...item.part_cancellations, data];
          }
          return item;
        })
      })
      message.info("Cancellation has been processed!");
    }).catch((err) => {
      message.warning(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    })
  }

  const externalOrderJobs = () => {
    const allJobEntriesWithExternalOrders = (glassReplacementJobEntries || []).filter(x => x.external_part_order !== null && x.external_part_order !== undefined);
    return allJobEntriesWithExternalOrders;
  }
  useEffect(() => {
    const strKeys = expandedRowKeys.map((key) => key.toString());
    for (const existingKey of Object.keys(expandedData)) {
      if (!strKeys.includes(existingKey)) {
        delete expandedData[existingKey];
      }
    }
    let newExpandedData = {};
    let newlyExpandedIds = []
    for (const expandedRowKey of expandedRowKeys) {
      if (expandedData[expandedRowKey] === undefined) {
        newlyExpandedIds.push(expandedRowKey);
        newExpandedData[expandedRowKey] = { loading: true };
      }
    }
    if (newlyExpandedIds.length > 0) {
      const idToMatch = newlyExpandedIds[0];
      const matchedJob = glassReplacementJobEntries.find((job) => job.key === idToMatch);
      let nagsNumberToSearchFor = matchedJob?.nags_part_nr;
      if (editingKey === matchedJob.key) {
        nagsNumberToSearchFor = form.getFieldValue("nags_part_nr");
      }

      if (matchedJob === undefined) {
        message.warning("Couldn't match the job entry with id: " + idToMatch);
      } else {
        getNagsDetailsExtraData(idToMatch, nagsNumberToSearchFor);
      }
    }
    console.log("newlyExpandedIds", newlyExpandedIds);
    setExpandedData((curr) => {
      const newData = { ...curr, ...newExpandedData }
      return newData;
    })

  }, [expandedRowKeys]);

  useEffect(() => {
    getTintPricings();
    fetchAllMakes();
  }, [])

  const jobsWithExternalOrders = externalOrderJobs();

  return (
    <>
      <If condition={(unacknowledgedJobReviews || []).length !== 0}>
        <h4>Job Reviews Requested by CSRs (unacknowledged)</h4>
        <Collapse style={{ marginBottom: "15px" }} activeKey={(unacknowledgedJobReviews || []).map((jobReview) => jobReview.id)}>
          {(unacknowledgedJobReviews || []).map((jobReview) => {
            const glassTypeValue = jobReview.glass_replacement_job?.glass_type || jobReview.glass_replacement_job?.glass_type_other_value;
            const isJobCompleted = jobReview.status === "Completed";
            let expertReviewer = "";
            if (isJobCompleted) {
              const fields = [jobReview.expert_tech_user_assigned?.first_name, jobReview.expert_tech_user_assigned?.last_name]
              expertReviewer = fields.filter(x => x !== undefined).join(" ");
            }
            const dataFormatted = [
              { key: "Reviewer Type", value: nicelyFormatPascalCaseString(jobReview.request_type) },
              { key: "Expert Tech Name", value: expertReviewer },
              { key: "Expert Tech Part Number Response", value: jobReview.internal_user_part_number_response },
              { key: "Expert Tech Part Detailed Response", value: jobReview.internal_user_detailed_response },
              { key: "Action(s)", value: "Acknowledge" }
            ]
            return (
              <Panel header={glassTypeValue} key={jobReview.id}>
                <Table
                  pagination={false}
                  columns={[
                    {
                      title: 'Key',
                      dataIndex: 'key',
                      width: "200px"
                    },
                    {
                      title: 'Value',
                      dataIndex: 'value',
                      render: (record) => {
                        if (record !== "Acknowledge") return record;
                        // if the job isn't completed, don't allow the user to mark the response as acknowledged.
                        // if the link contains ?review allow the user to review the job
                        if (!isJobCompleted && !window.location.search.includes("?review")) {
                          return ""
                        }

                        return (
                          <>
                            <If condition={!isJobCompleted && window.location.search.includes("?review")}>
                              <Button
                                type="primary"
                                onClick={() => {
                                  setSelectedJobReview(jobReview);
                                  setIsProvideFeedbackModalVisible(true);
                                }}
                              > Review</Button>
                            </If>
                            <If condition={isJobCompleted}>
                              <Button
                                type="primary"
                                onClick={() => {
                                  setAcknowledgedForJobReview(jobReview.id).then((response) => {
                                    const { data } = response;
                                    refreshUnacknowledgedJobReviews();
                                  }).catch((err) => {
                                    message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
                                  })
                                }}
                              > Acknowledge</Button>
                            </If>
                          </>

                        )
                      }
                    },
                  ]}
                  showHeader={false}
                  dataSource={dataFormatted}
                  size="small"
                />
              </Panel>
            )
          })}
        </Collapse>
      </If>
      <If condition={(expertUnpromptedPartReviews || []).length !== 0}>
        <h4>Expert Reviews (provided without CSR request)</h4>
        <Collapse style={{ marginBottom: "15px" }}>
          {(expertUnpromptedPartReviews || []).map((jobReview) => {
            const glassTypeValue = jobReview.glass_replacement_job?.glass_type || jobReview.glass_replacement_job?.glass_type_other_value;
            const fields = [jobReview.expert_tech_user_assigned?.first_name, jobReview.expert_tech_user_assigned?.last_name]
            const expertReviewer = fields.filter(x => x !== undefined).join(" ");
            const dataFormatted = [
              { key: "Expert Tech Name", value: expertReviewer },
              { key: "Expert Tech Part Number Response", value: jobReview.internal_user_part_number_response },
              { key: "Expert Tech Part Detailed Response", value: jobReview.internal_user_detailed_response },
              { key: "Original Part #", value: jobReview.job_original_part_number },
            ]
            return (
              <Panel header={glassTypeValue} key={jobReview.id}>
                <Table
                  pagination={false}
                  columns={[
                    {
                      title: 'Key',
                      dataIndex: 'key',
                      width: "200px"
                    },
                    {
                      title: 'Value',
                      dataIndex: 'value',
                      render: (record) => {
                        return record;
                      }
                    },
                  ]}
                  showHeader={false}
                  dataSource={dataFormatted}
                  size="small"
                />
              </Panel>
            )
          })}
        </Collapse>
      </If>
      <If condition={jobsWithExternalOrders.length > 0}>
        <h4>Vendor Orders</h4>
        <Collapse style={{ marginBottom: "15px" }}>
          {(jobsWithExternalOrders || []).map((job) => {
            let glass = job.glass_type || []
            const glassTypeValue = glass.at(-1) || job.glass_type_other_value;
            const dataFormatted = [
              { key: "Glass Type", value: nicelyFormatPascalCaseString(glassTypeValue) },
              { key: "NAGS Part #", value: job.nags_part_nr },
              { key: "Vendor", value: job.inventory_source },
              { key: "Order Status", value: job.external_part_order.status },
              { key: "Order ID", value: job.external_part_order.external_service_order_id },
              { key: "Action(s)", value: "Actions" }
            ]
            return (
              <Panel header={nicelyFormatPascalCaseString(glassTypeValue)} key={job.id}>
                <Table
                  pagination={{
                    current: 1,
                    pageSize: 1000,
                  }}
                  columns={[
                    {
                      title: 'Key',
                      dataIndex: 'key',
                      width: "200px"
                    },
                    {
                      title: 'Value',
                      dataIndex: 'value',
                      render: (record) => {
                        if (record !== "Actions") return record;

                        return (
                          <Space>
                            <Button
                              type="primary"
                              onClick={() => {
                                if (job.inventory_source === "Pilkington") {
                                  setDataDump(job.external_part_order.order_creation_request_object);
                                } else {
                                  setDataDump(JSON.stringify(JSON.parse(job.external_part_order.order_creation_request_object), null, 2));
                                }
                                setIsDataDumpModalVisible(true);
                              }}
                            > View Request Object </Button>
                            <Button
                              type="primary"
                              onClick={() => {
                                setDataDump(JSON.stringify(JSON.parse(job.external_part_order.order_creation_response_object), null, 2));
                                setIsDataDumpModalVisible(true);
                              }}
                            > View Response Object </Button>
                          </Space>
                        )
                      }
                    },
                  ]}
                  showHeader={false}
                  dataSource={dataFormatted}
                  size="small"
                />
              </Panel>
            )
          })}
        </Collapse>
      </If>
      <i style={{ margin: "10px", display: "block" }}> If glass has substitute parts, please separate them by "/" character and don't include spaces (" "). Also each part should have its proper prefix (FW, DW, FB, ...)</i>
      <i style={{ margin: "10px", display: "block" }}><b>Valid</b> entry: "FW12333/FW51310"</i>
      <i style={{ margin: "10px", display: "block" }}><b>Invalid</b> entries: "FW12333 FW51310", "FW12333/51310"</i>
      <EditableTable
        form={form}
        initialData={glassReplacementJobEntries}
        setDataRecords={setGlassReplacementJobEntries}
        columns={columns}
        vehicles={vehicles}
        technicianEmployees={technicianEmployees}
        scrollableWidth={700}
        displayTintingOptionsButton={true}
        isGlassReplacementJobParent={true}
        intakeFormFormObject={intakeFormFormObject}
        editingKey={editingKey}
        setEditingKey={setEditingKey}
        tintPricings={tintPricings}
        fetchPartNr={fetchPartNr}
        checkPartNrInternalAvailability={checkPartNrInternalAvailability}
        onNagsPartChangeHandler={onNagsPartChangeHandler}
        cancelOrderQRScan={cancelOrderQRScan}
        cancelOrderNonQRScan={cancelOrderNonQRScan}
        fetchPartNotes={fetchPartNotes}
        performPriceCheck={performPriceCheck}
        internalHelpRequest={internalHelpRequest}
        AGCrmNagsSearch={AGCrmNagsSearch}
        onFinishEditingFetchAGCRMNagsPartNr={onFinishEditingFetchAGCRMNagsPartNr}
        shopLocations={shopLocations}
        nagsPartNrAGCRMInstallInfos={nagsPartNrAGCRMInstallInfos}
        isInsuranceWorkType={isInsuranceWorkType}
        isQuoteOnlyLocationSelected={isQuoteOnlyLocationSelected}
        setExpertUnpromptedPartReviews={setExpertUnpromptedPartReviews}
        intakeForm={intakeForm}
        onValuesChange={(changedValues, allValues) => {
          if (changedValues?.nags_part_nr) {
            setExpandedRowKeys([]);
          }
        }}
        expandableTableObject={{
          expandedRowRender: (record) => {
            const nagsHoursPrice = (expandedData[record.key]?.nags_hours_price || []);
            const jobsWithPartInstalled = (expandedData[record.key]?.jobs_with_part_installed || []);
            const xygData = (expandedData[record.key]?.xyg_results || []);
            const pilkingtonProducts = (expandedData[record.key]?.pilkington_products || []);
            return (
              <>
                <If condition={expandedData[record.key]?.loading}>
                  <div style={{ textAlign: "center" }}>
                    <Spin size="large" />
                  </div>
                </If>
                <If condition={!expandedData[record.key]?.loading}>
                  <Collapse defaultActiveKey={['4']}>
                    <Panel header="Notes" key="4">
                      <NagsPartNrNotesListComponent
                        nagsPartNrNotes={(expandedData[record.key]?.notes || [])}
                        showInCollapse={false}
                      />
                    </Panel>
                    <Panel header={badgeElement("NAGS Hours & Price", nagsHoursPrice.length)} key="3">
                      <Table
                        pagination={false}
                        columns={[
                          {
                            title: 'Part',
                            dataIndex: 'part_number',
                          },
                          {
                            title: 'Hours',
                            render: (record) => {
                              return record.user_edited_hours || record.hours
                            }
                          },
                          {
                            title: 'Price',
                            render: (record) => {
                              return record.user_edited_price || record.price
                            }
                          },
                        ]}
                        dataSource={nagsHoursPrice}
                        size="small"
                      />
                    </Panel>
                    <Panel header={badgeElement("Jobs with Part Installed", jobsWithPartInstalled.length)} key="2">
                      <SharedIntakeFormTableBrief
                        jobsWithPartInstalled={jobsWithPartInstalled}
                      />
                    </Panel>
                    <Panel header={badgeElement("XYG Photos", xygData.length)} key="1">
                      <List
                        loading={loading}
                        grid={{
                          gutter: 16,
                          xs: 1,
                          sm: 2,
                          md: 4,
                          lg: 4,
                          xl: 6,
                          xxl: 3,
                        }}
                        dataSource={xygData}
                        renderItem={result => {
                          const imageFields = ["s3_car_photo_url", "s3_glass_photo_url", "s3_sunvisor_photo_url", "s3_outside_photo_url"];
                          let imageElements = [];
                          for (const iterator of imageFields) {
                            const value = result[iterator];
                            if (value !== null) {
                              imageElements.push(
                                <Image
                                  style={{ width: "100%" }}
                                  src={value}
                                />
                              )
                            }
                          }
                          return (
                            <List.Item
                              key={result.id}
                            >
                              NAGS#: <b>{result.nags_number}</b>
                              {imageElements}
                            </List.Item>
                          )
                        }}
                      />
                    </Panel>
                    <PilkingtonDataPanelComponent
                      key={"abcd"}
                      pilkingtonProducts={pilkingtonProducts}
                      loading={loading}
                    />
                  </Collapse>
                </If>
              </>
            )
          },
          expandedRowKeys: expandedRowKeys,
          onExpandedRowsChange: setExpandedRowKeys,
          onExpand: (expanded, record) => {
            console.log({ expanded, record });
          }
        }}
      />
      <VehicleSelectionPartsLookupComponent
        isModalVisible={isVehicleVinSelectionModalVisible}
        setIsModalVisible={setIsVehicleVinSelectionModalVisible}
        vehicles={vehicles}
        partsLookupForVin={partsLookupForVin}
        partsLookupForVehicleInfo={partsLookupForVehicleInfo}
        possibleMakes={possibleMakes}
      />
      <ReviewJobReviewModal
        isModalVisible={isProvideFeedbackModalVisible}
        getAllReviews={() => { window.location.reload(); }}
        setIsModalVisible={setIsProvideFeedbackModalVisible}
        reviewData={selectedJobReview}
      />
      <PriceCheckDrawerComponent
        isDrawerVisible={isPriceCheckDrawerVisible}
        setIsDrawerVisible={setIsPriceCheckDrawerVisible}
        nagsPartNrSearch={selectedNagsPartNr}
        selectVendor={selectVendorPriceCheck}
      />
      <DeliveryTimeDriverModalComponent
        isModalVisible={isDeliveryTimeDriverModalVisible}
        setIsModalVisible={setIsDeliveryTimeDriverModalVisible}
        selectedVendorName={selectedVendorName}
        selectedVendorItem={selectedVendorItem}
        selectedTimeDriverAction={deliveryTimeDriverSelectionCompleted}
        shopLocations={shopLocations}
        isMobileJobLocation={isMobileJobLocation}
        isQuoteOnlyLocationSelected={isQuoteOnlyLocationSelected}
      />
      <AGCRMNagsSearchResult
        isModalVisible={isAGCRMNagsSearchResultsModalVisible}
        setIsModalVisible={setIsAGCRMNagsSearchResultsModalVisible}
        results={AGCRMSearchResult}
      />
      <DisplayNagsPartNrNotesModalComponent
        isModalVisible={isDisplayNagsPartNrNotesModalVisible}
        setIsModalVisible={setIsDisplayNagsPartNrNotesModalVisible}
        nagsPartNr={selectedNagsPartNr}
      />
      <Modal
        title="Data Dump"
        visible={isDataDumpModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}>
        <pre>{dataDump}</pre>
      </Modal>
      <AGCRMPartsLookupDrawerComponent
        partLookupDrawerVisible={partLookupDrawerVisible}
        setPartLookupDrawerVisible={setPartLookupDrawerVisible}
        partsLookupResult={partsLookupResult}
        form={form}
        partsLookupLoading={partsLookupLoading}
        xygPartsLookupResult={xygPartsLookupResult}
        onNagsPartChangeHandler={onNagsPartChangeHandler}
        isGlassReplacementJobParent={true}
      />
    </>
  )

}

export default GlassReplacementJobEntryTable