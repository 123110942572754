import moment from 'moment';
import instance from "../axios";
import * as endpoints from "./endpoints";
import { AUTHORIZATION_KEY } from "../global_constants";
import axios from "axios";
import { resetLogoutAndEarlyLogoutWarningTimers } from "../helpers";

const cancelInFlightGetRequest = (path) => {
  const cancelTokenSource = axios.CancelToken.source();
  return {
    request: instance.get(path, {
      cancelToken: cancelTokenSource.token,
    }),
    cancelTokenSource,
  };
};

export const registerUser = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.REGISTER_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loginUser = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.LOGIN_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.access_token}`;
        resetLogoutAndEarlyLogoutWarningTimers();
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAuthUserDetail = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTHORIZATION_KEY);
    if (token) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      instance
        .get(endpoints.AUTH_USER_API_PATH)
        .then((response) => {
          resetLogoutAndEarlyLogoutWarningTimers();
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject();
    }
  });
};

export const getAllIntakeForms = (page = 1, limit = 20, params = null) => {
  let path = endpoints.GET_ALL_INTAKE_FORMS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return cancelInFlightGetRequest(path);
};
export const addIntakeForm = (payload) => {
  let path = endpoints.ADD_INTAKE_FORMS_API_PATH;
  return instance.post(path, payload);
};
export const getSingleIntakeForm = (intakeFormId) => {
  let path = endpoints.GET_SINGLE_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId);
  return instance.get(path);
};
export const getIntakeFormJobs = (intakeFormId) => {
  let path = endpoints.GET_INTAKE_FORM_JOBS_API_PATH.replace("{intakeFormId}", intakeFormId);
  return instance.get(path);
};
export const editIntakeForm = (payload, intakeFormId) => {
  let path = endpoints.EDIT_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId);
  return instance.post(path, payload);
};
export const checkInManagementChangeIntakeForm = (payload, intakeFormId) => {
  let path = endpoints.CHECK_IN_MANAGEMENT_CHANGE_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId);
  return instance.post(path, payload);
};
export const deleteIntakeForm = (intakeFormId) => {
  let path = endpoints.DELETE_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId);
  return instance.delete(path);
};
export const intakeFormQuickSaleStageChange = (intakeFormId, targetStatusValue) => {
  let path = endpoints.QUICK_SALE_STAGE_CHANGE_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId).replace("{targetStatusValue}", targetStatusValue);
  return instance.get(path);
};
export const intakeFormCustomerStatusQuickChange = (intakeFormId, targetStatusValue) => {
  let path = endpoints.CUSTOMER_CHECK_IN_STATUS_QUICK_CHANGE_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId).replace("{targetStatusValue}", targetStatusValue);
  return instance.get(path);
};
export const intakeFormTechnicianUserQuickChange = (intakeFormId, technicianUserId) => {
  let path = endpoints.TECHNICIAN_QUICK_CHANGE_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId).replace("{technicianUserId}", technicianUserId);
  return instance.get(path);
};
export const intakeFormAppointmentTimeQuickChange = (intakeFormId, appointmentTime) => {
  let path = endpoints.APPOINTMENT_TIME_QUICK_CHANGE_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId).replace("{appointmentTime}", appointmentTime);
  return instance.get(path);
};
export const intakeFormPaymentStatusQuickChange = (intakeFormId, paymentStatus) => {
  let path = endpoints.PAYMENT_STATUS_QUICK_CHANGE_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId).replace("{paymentStatus}", paymentStatus);
  return instance.get(path);
};
export const intakeFormInstallStatusQuickChange = (intakeFormId, installStatus) => {
  let path = endpoints.INSTALL_STATUS_QUICK_CHANGE_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId).replace("{installStatus}", installStatus);
  return instance.get(path);
};
export const intakeFormGlassStatusQuickChange = (intakeFormId, glassStatus) => {
  let path = endpoints.GLASS_STATUS_QUICK_CHANGE_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId).replace("{glassStatus}", glassStatus);
  return instance.get(path);
};
export const intakeFormInvoiceSentToInsuranceOrVendorQuickChange = (intakeFormId, status) => {
  let path = endpoints.IS_INVOICE_SENT_TO_INSURANCE_OR_VENDOR_QUICK_CHANGE_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId).replace("{status}", status);
  return instance.get(path);
};
export const intakeFormExpertReviewedQuickChange = (intakeFormId, isChecked) => {
  let path = endpoints.EXPERT_REVIEWED_QUICK_CHANGE_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId).replace("{isChecked}", isChecked);
  return instance.get(path);
};
export const getAllIntakeFormHistoryEntries = (intakeFormId) => {
  let path = endpoints.GET_ALL_INTAKE_FORM_HISTORY_ENTRIES_API_PATH.replace("{intakeFormId}", intakeFormId);
  return instance.get(path);
};
export const assignUserToEGRLeadIntakeForm = (intakeFormId) => {
  let path = endpoints.ASSIGN_USER_TO_EGR_LEAD_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId);
  return instance.get(path);
}
export const assignUserToCallRailFormIntakeForm = (intakeFormId) => {
  let path = endpoints.ASSIGN_USER_TO_CALLRAIL_FORM_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId);
  return instance.get(path);
}

export const cloneWarrantyIntakeForm = (payload) => {
  let path = endpoints.CLONE_WARRANTY_INTAKE_FORM_API_PATH;
  return instance.post(path, payload);
};
export const quickQuoteForIntakeForm = (payload) => {
  let path = endpoints.QUICK_QUOTE_INTAKE_FORM_API_PATH;
  return instance.post(path, payload);
};
export const splitIntakeIntakeForm = (payload) => {
  let path = endpoints.SPLIT_JOBS_INTAKE_FORM_API_PATH;
  return instance.post(path, payload);
};
export const checkUncheckIntakeFormStatusValue = (intakeFormId, targetStatusValue) => {
  let path = endpoints.DELETE_STATUS_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId).replace("{status}", targetStatusValue);
  return instance.get(path);
};
export const setQuoteRejectedReasonForIntakeForm = (intakeFormId, reason) => {
  let path = endpoints.SET_QUOTE_REJECTED_REASON_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId).replace("{reason}", reason);
  return instance.get(path);
};

export const getAllVehicles = () => {
  let path = endpoints.GET_ALL_VEHICLES_API_PATH;
  return instance.get(path);
};
export const getAllFilteredVehicles = (searchTerm) => {
  let path = endpoints.GET_ALL_FILTERED_VEHICLES_API_PATH.replace("{searchTerm}", searchTerm);
  return instance.get(path);
};

export const addVehicle = (payload) => {
  let path = endpoints.ADD_VEHICLES_API_PATH;
  return instance.post(path, payload);
};
export const editVehicle = (payload, vehicleId) => {
  let path = endpoints.EDIT_VEHICLE_API_PATH.replace("{vehicleId}", vehicleId);
  return instance.post(path, payload);
};
export const deleteVehicle = (vehicleId) => {
  let path = endpoints.DELETE_VEHICLE_API_PATH.replace("{vehicleId}", vehicleId);
  return instance.delete(path);
};

export const addCashLedger = (payload) => {
  let path = endpoints.ADD_CASH_LEDGERS_API_PATH;
  return instance.post(path, payload);
};
export const editCashLedger = (payload, cashLedgerId) => {
  let path = endpoints.EDIT_CASH_LEDGER_API_PATH.replace("{cashLedgerId}", cashLedgerId);
  payload.id = parseInt(cashLedgerId)
  return instance.put(path, payload);
};
export const deleteCashLedger = (cashLedgerId) => {
  let path = endpoints.DELETE_CASH_LEDGER_API_PATH.replace("{cashLedgerId}", cashLedgerId);
  return instance.delete(path);
};
export const getAllFilteredCashLedgers = (searchTerm, page = 1, limit = 10) => {
  let path = `${endpoints.GET_ALL_FILTERED_CASH_LEDGERS_API_PATH}?page=${page}&limit=${limit}&${searchTerm}`;
  return instance.get(path)
};
export const getSingleCashLedger = (cashLedgerId) => {
  let path = endpoints.GET_SINGLE_CASH_LEDGER_API_PATH.replace("{cashLedgerId}", cashLedgerId);
  return instance.get(path);
};

export const getAllShopLocations = () => {
  let path = endpoints.GET_ALL_SHOP_LOCATIONS_API_PATH;
  return instance.get(path);
};
export const getAllShopLocationsDetailed = () => {
  let path = endpoints.GET_ALL_SHOP_LOCATIONS_DETAILED_API_PATH;
  return instance.get(path);
};
export const getSingleShopLocation = (shopLocationId) => {
  let path = endpoints.GET_SINGLE_SHOP_LOCATION_API_PATH.replace("{shopLocationId}", shopLocationId);
  return instance.get(path);
};
export const addShopLocation = (payload) => {
  let path = endpoints.ADD_SHOP_LOCATIONS_API_PATH;
  return instance.post(path, payload);
};
export const editShopLocation = (payload, shopLocationId) => {
  let path = endpoints.EDIT_SHOP_LOCATION_API_PATH.replace("{shopLocationId}", shopLocationId);
  return instance.post(path, payload);
};
export const deleteShopLocation = (shopLocationId) => {
  let path = endpoints.DELETE_SHOP_LOCATION_API_PATH.replace("{shopLocationId}", shopLocationId);
  return instance.delete(path);
};

export const showAllGoogleCalendars = () => {
  let path = endpoints.GET_ALL_GOOGLE_CALENDARS_API_PATH;
  return instance.get(path);
};

export const publishSquareInvoice = (intakeFormId, emailOverride = undefined) => {
  let path = endpoints.PUBLISH_INTAKE_FORM_SQUARE_INVOICE_API_PATH.replace("{intakeFormId}", intakeFormId);
  if (emailOverride !== undefined && emailOverride !== null) {
    path = path + `&email_override=${emailOverride}`;
  }
  return instance.get(path);
};

export const getAllTintPricings = () => {
  let path = endpoints.GET_ALL_TINT_PRICINGS_API_PATH;
  return instance.get(path);
};
export const getSingleTintPricing = (tintPricingId) => {
  let path = endpoints.GET_SINGLE_TINT_PRICING_API_PATH.replace("{tintPricingId}", tintPricingId);
  return instance.get(path);
};
export const addTintPricing = (payload) => {
  let path = endpoints.ADD_TINT_PRICINGS_API_PATH;
  return instance.post(path, payload);
};
export const editTintPricing = (payload, tintPricingId) => {
  let path = endpoints.EDIT_TINT_PRICING_API_PATH.replace("{tintPricingId}", tintPricingId);
  return instance.post(path, payload);
};
export const deleteTintPricing = (tintPricingId) => {
  let path = endpoints.DELETE_TINT_PRICING_API_PATH.replace("{tintPricingId}", tintPricingId);
  return instance.delete(path);
};


export const getCurrentEmployeeInfo = () => {
  let path = endpoints.CURRENT_USER_INFO_API_PATH;
  return instance.get(path);
};
export const getAllCSREmployees = () => {
  let path = endpoints.ALL_CSR_USERS_API_PATH;
  return instance.get(path);
};
export const getAllTechnicianEmployees = () => {
  let path = endpoints.ALL_TECHNICIAN_USERS_API_PATH;
  return instance.get(path);
};
export const getAllDeliveryDriverUsers = () => {
  let path = endpoints.ALL_DELIVERY_DRIVERS_USERS_API_PATH;
  return instance.get(path);
};

export const getSettings = () => {
  let path = endpoints.GET_SETTINGS_API_PATH;
  return instance.get(path);
}

export const editSettings = (payload) => {
  let path = endpoints.EDIT_SETTINGS_API_PATH
  return instance.post(path, payload);
};

export const getAllUsersForAccount = () => {
  let path = endpoints.GET_ALL_ACCOUNT_USERS_API_PATH;
  return instance.get(path);
};
export const getFilteredUsers = (params) => {
  let path = endpoints.GET_ALL_FILTERED_USERS_API_PATH;
  if (params) path = path + "?" + params;
  return instance.get(path);
}
export const getSingleUser = (userId) => {
  let path = endpoints.GET_SINGLE_USER_API_PATH.replace("{userId}", userId);
  return instance.get(path);
};
export const addUser = (payload) => {
  let path = endpoints.ADD_USERS_API_PATH;
  return instance.post(path, payload);
};
export const editUser = (payload, userId) => {
  let path = endpoints.EDIT_USER_API_PATH.replace("{userId}", userId);
  return instance.post(path, payload);
};
export const disableEnableUser = (userId, isDisabled = true) => {
  let path = endpoints.DELETE_USER_API_PATH.replace("{userId}", userId).replace("{disabled}", isDisabled);
  return instance.get(path);
};
export const updateCurrentActiveShopLocationForUser = (userId, payload) => {
  let path = endpoints.UPDATE_CURRENT_ACTIVE_SHOP_LOCATION_API_PATH.replace("{userId}", userId);
  return instance.post(path, payload)
}

export const getAllInsuranceCarriers = () => {
  let path = endpoints.GET_ALL_INSURANCE_CARRIERS_API_PATH;
  return instance.get(path);
};
export const getSingleInsuranceCarrier = (insuranceCarrierId) => {
  let path = endpoints.GET_SINGLE_INSURANCE_CARRIER_API_PATH.replace("{insuranceCarrierId}", insuranceCarrierId);
  return instance.get(path);
};
export const addInsuranceCarrier = (payload) => {
  let path = endpoints.ADD_INSURANCE_CARRIERS_API_PATH;
  return instance.post(path, payload);
};
export const editInsuranceCarrier = (payload, insuranceCarrierId) => {
  let path = endpoints.EDIT_INSURANCE_CARRIER_API_PATH.replace("{insuranceCarrierId}", insuranceCarrierId);
  return instance.post(path, payload);
};
export const deleteInsuranceCarrier = (insuranceCarrierId) => {
  let path = endpoints.DELETE_INSURANCE_CARRIER_API_PATH.replace("{insuranceCarrierId}", insuranceCarrierId);
  return instance.delete(path);
};

export const getAllCustomers = () => {
  let path = endpoints.GET_ALL_CUSTOMERS_API_PATH;
  return instance.get(path);
};
export const getAllFilteredCustomers = (searchTerm, customerId) => {
  console.log("customerId: ", customerId);
  console.log("isInteger:", Number.isInteger(customerId))
  if (!Number.isInteger(customerId)) {
    customerId = -1;
  }
  let path = endpoints.GET_ALL_FILTERED_CUSTOMERS_API_PATH.replace("{searchTerm}", searchTerm).replace("{customerId}", customerId);
  return instance.get(path);
};
export const getSingleCustomer = (customerId) => {
  let path = endpoints.GET_SINGLE_CUSTOMER_API_PATH.replace("{customerId}", customerId);
  return instance.get(path);
};
export const getAllEmailsOnRecordForCustomer = (customerId = null, intakeFormId = null) => {
  let path = endpoints.GET_EMAILS_ON_RECORD_FOR_CUSTOMER_API_PATH.replace("{customerId}", customerId).replace("{intakeFormId}", intakeFormId);
  return instance.get(path);
};
export const getSingleCustomerPhoneNumber = (phoneNumber) => {
  let path = endpoints.GET_CUSTOMER_BY_PHONE_NUMBER_API_PATH.replace("{phoneNumber}", phoneNumber);
  return instance.get(path);
};
export const addCustomer = (payload) => {
  let path = endpoints.ADD_CUSTOMERS_API_PATH;
  return instance.post(path, payload);
};
export const editCustomer = (payload, customerId) => {
  let path = endpoints.EDIT_CUSTOMER_API_PATH.replace("{customerId}", customerId);
  return instance.post(path, payload);
};
export const deleteCustomer = (customerId) => {
  let path = endpoints.DELETE_CUSTOMER_API_PATH.replace("{customerId}", customerId);
  return instance.delete(path);
};

export const getAllUsersFiles = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_CUSTOMERS_FILES_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params
  return instance.get(path);
};

export const getAllCallRailWebhooks = (page = 1, limit = 10, params = null) => {
  if (!Number.isInteger(page)) {
    console.log("page is not an integer, defaulting to 1");
    page = 1;
  }
  let path = endpoints.GET_ALL_CALLRAIL_WEBHOOKS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return cancelInFlightGetRequest(path);
};
export const getAllCallRailWebhookModeCounters = () => {
  let path = endpoints.GET_ALL_CALLRAIL_WEBHOOK_MODE_COUNTS_API_PATH;
  return instance.get(path);
};
export const addCallRailWebhook = (payload) => {
  let path = endpoints.ADD_CALLRAIL_WEBHOOKS_API_PATH;
  return instance.post(path, payload);
};
export const getSingleCallRailWebhook = (callRailWebhookId) => {
  let path = endpoints.GET_SINGLE_CALLRAIL_WEBHOOK_API_PATH.replace("{callRailWebhookId}", callRailWebhookId);
  return instance.get(path);
};
export const getAssignedAgentsForCallRailWebhook = (callRailWebhookId) => {
  let path = endpoints.GET_ASSIGNED_AGENTS_CALLRAIL_WEBHOOK_API_PATH.replace("{callRailWebhookId}", callRailWebhookId);
  return instance.get(path);
};
export const assignAgentsToCallRailPhoneNumber = (payload, callRailWebhookId) => {
  let path = endpoints.ASSIGN_AGENTS_TO_CALLRAIL_PHONE_NUMBER_API_PATH.replace("{callRailWebhookId}", callRailWebhookId);
  return instance.post(path, payload);
};
export const getCountUnassignedNeedsAttention = () => {
  let path = endpoints.GET_COUNT_UNASSIGNED_NEEDS_ATTENTION_API_PATH;
  return instance.get(path);
}
export const getCountNeedsAttention = () => {
  let path = endpoints.GET_COUNT_NEEDS_ATTENTION_API_PATH;
  return instance.get(path);
}
export const ignoreCallRailWebhook = (payload, callRailWebhookId) => {
  let path = endpoints.IGNORE_CALLRAIL_WEBHOOK_API_PATH.replace("{callRailWebhookId}", callRailWebhookId);
  return instance.post(path, payload);
};
export const ignoreCallRailWebhookFromIntakeForm = (payload, intakeFormId) => {
  let path = endpoints.IGNORE_CALLRAIL_WEBHOOK_FROM_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId);
  return instance.post(path, payload);
};
export const ignoreCallRailWebhookForPhoneNumber = (payload, phoneNumber) => {
  let path = endpoints.IGNORE_CALLRAIL_WEBHOOK_FOR_PHONE_NUMBER_API_PATH.replace("{phoneNumber}", phoneNumber);
  return instance.post(path, payload);
};
export const editCallRailWebhook = (payload, callRailWebhookId) => {
  let path = endpoints.EDIT_CALLRAIL_WEBHOOK_API_PATH.replace("{callRailWebhookId}", callRailWebhookId);
  return instance.post(path, payload);
};
export const deleteCallRailWebhook = (callRailWebhookId) => {
  let path = endpoints.DELETE_CALLRAIL_WEBHOOK_API_PATH.replace("{callRailWebhookId}", callRailWebhookId);
  return instance.delete(path);
};
export const assignCSRToUnderlyingIntakeFormForCallRailForm = (callRailWebhookId) => {
  let path = endpoints.ASSIGN_CSR_TO_UNDERLYING_INTAKE_FORM_CALLRAIL_FORM_API_PATH.replace("{callRailWebhookId}", callRailWebhookId);
  return instance.get(path);
}

export const getAllCallRailUsers = () => {
  let path = endpoints.GET_ALL_CALLRAIL_USERS_API_PATH;
  return instance.get(path);
};
export const getSingleCallRailUser = (callRailUserId) => {
  let path = endpoints.GET_SINGLE_CALLRAIL_USER_API_PATH.replace("{callRailUserId}", callRailUserId);
  return instance.get(path);
};
export const addCallRailUser = (payload) => {
  let path = endpoints.ADD_CALLRAIL_USERS_API_PATH;
  return instance.post(path, payload);
};
export const editCallRailUser = (payload, callRailUserId) => {
  let path = endpoints.EDIT_CALLRAIL_USER_API_PATH.replace("{callRailUserId}", callRailUserId);
  return instance.post(path, payload);
};
export const deleteCallRailUser = (callRailUserId) => {
  let path = endpoints.DELETE_CALLRAIL_USER_API_PATH.replace("{callRailUserId}", callRailUserId);
  return instance.delete(path);
};

export const getAllCallRailTextsForConversation = (conversationId, page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_CALLRAIL_TEXTS_FOR_CONVERSATION_API_PATH.replace("{conversationId}", conversationId);
  path = path + `&page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return cancelInFlightGetRequest(path);
};
export const getAllCallRailTextsForMultipleConversations = (conversationIds) => {
  let path = endpoints.GET_ALL_CALLRAIL_TEXTS_FOR_MULTIPLE_CONVERSATIONS_API_PATH.replace("{conversationIds}", conversationIds);
  return instance.get(path);
};
export const getActiveConversationDataForEmojisAndColors = (activeChatConversationIds) => {
  let path = endpoints.GET_ACTIVE_CONVERSATION_DATA_FOR_EMOJIS_AND_COLORS_API_PATH.replace("{activeChatConversationIds}", activeChatConversationIds);
  return instance.get(path);
};
export const getOpenChatConversationDataForInternalMessages = (conversationIds) => {
  let path = endpoints.GET_OPEN_CAHT_CONVERSATION_DATA_FOR_INTERNAL_MESSAGES_API_PATH.replace("{conversationIds}", conversationIds);
  return instance.get(path);
};
export const getAllCallRailTextsForPhoneNumber = (phoneNumber, page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_CALLRAIL_TEXTS_FOR_PHONE_NR_API_PATH.replace("{phoneNumber}", phoneNumber);
  path = path + `&page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return cancelInFlightGetRequest(path);
};

export const addCallRailTextRecord = (payload) => {
  let path = endpoints.ADD_CALLRAIL_TEXT_RECORDS_API_PATH;
  return instance.post(path, payload);
};
export const getSingleCallRailTextRecord = (callRailTextRecordId) => {
  let path = endpoints.GET_SINGLE_CALLRAIL_TEXT_RECORD_API_PATH.replace("{callRailTextRecordId}", callRailTextRecordId);
  return instance.get(path);
};
export const editCallRailTextRecord = (payload, callRailTextRecordId) => {
  let path = endpoints.EDIT_CALLRAIL_TEXT_RECORD_API_PATH.replace("{callRailTextRecordId}", callRailTextRecordId);
  return instance.post(path, payload);
};
export const deleteCallRailTextRecord = (callRailTextRecordId) => {
  let path = endpoints.DELETE_CALLRAIL_TEXT_RECORD_API_PATH.replace("{callRailTextRecordId}", callRailTextRecordId);
  return instance.delete(path);
};
export const sendSmsMessageCallRail = (payload, callRailConversationId) => {
  let path = endpoints.SEND_TEXT_MESSAGE_CALLRAIL_API_PATH.replace("{conversationId}", callRailConversationId);
  return instance.post(path, payload);
};
export const sendSmsMessageCallRailPhoneNumber = (payload, phoneNr) => {
  let path = endpoints.SEND_TEXT_MESSAGE_CALLRAIL_PHONE_NUMBER_ONLY_API_PATH.replace("{phoneNr}", phoneNr);
  return instance.post(path, payload);
};

export const acknowledgeAllMessagesForConvoCallRail = (callRailConversationId) => {
  let path = endpoints.ACKNOWLEDGE_ALL_MESSAGES_FOR_CONVERSATION_CALLRAIL_API_PATH.replace("{conversationId}", callRailConversationId);
  return instance.get(path);
};
export const undoAcknowledgeAllMessagesForConvoCallRail = (callRailConversationId) => {
  let path = endpoints.UNDO_ACKNOWLEDGE_ALL_MESSAGES_FOR_CONVERSATION_CALLRAIL_API_PATH.replace("{conversationId}", callRailConversationId);
  return instance.get(path);
};


export const getAllCallRailTextConversations = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_CALLRAIL_CONVERSATIONS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return cancelInFlightGetRequest(path);
};
export const getAllUnacknowledgedCallRailTextConversations = () => {
  let path = endpoints.GET_ALL_UNACKNOWLEDGED_CALLRAIL_CONVERSATIONS_API_PATH;
  return instance.get(path);
};
export const getCallRailConversationForPhoneNr = (phoneNumber, page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_CALLRAIL_CONVERSATION_FOR_PHONE_NR_API_PATH.replace("{phoneNumber}", phoneNumber);
  path = path + `&page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return cancelInFlightGetRequest(path);
};

export const addCallRailConversation = (payload) => {
  let path = endpoints.ADD_CALLRAIL_CONVERSATIONS_API_PATH;
  return instance.post(path, payload);
};
export const getSingleCallRailConversation = (callRailConversationId) => {
  let path = endpoints.GET_SINGLE_CALLRAIL_CONVERSATION_API_PATH.replace("{callRailConversationId}", callRailConversationId);
  return instance.get(path);
};
export const getCallRailConversationForIntakeForm = (intakeFormId) => {
  let path = endpoints.GET_CALLRAIL_CONVERSATION_FOR_INTAKE_FORM_API_PATH.replace("{intakeFormId}", intakeFormId);
  return instance.get(path);
};
export const getVinInCallRailConversation = (phoneNumber) => {
  let path = endpoints.GET_VIN_IN_CALLRAIL_CONVERSATION_FOR_PHONE_NR_API_PATH.replace("{phoneNumber}", phoneNumber);
  return instance.get(path);
};
export const editCallRailConversation = (payload, callRailConversationId) => {
  let path = endpoints.EDIT_CALLRAIL_CONVERSATION_API_PATH.replace("{callRailConversationId}", callRailConversationId);
  return instance.post(path, payload);
};
export const editCallRailConversationTags = (payload, callRailConversationId) => {
  let path = endpoints.EDIT_TAGS_CALLRAIL_CONVERSATION_API_PATH.replace("{callRailConversationId}", callRailConversationId);
  return instance.post(path, payload);
};
export const deleteCallRailConversation = (callRailConversationId) => {
  let path = endpoints.DELETE_CALLRAIL_CONVERSATION_API_PATH.replace("{callRailConversationId}", callRailConversationId);
  return instance.delete(path);
};
export const getFirstLastNameCallrailConversation = (callRailConversationId) => {
  let path = endpoints.GET_FIRST_LAST_NAME_CONVERSATION_API_PATH.replace("{callRailConversationId}", callRailConversationId);
  return instance.get(path);
}

export const getBackendLogs = () => {
  let path = endpoints.BACKEND_LOGS_API_PATH;
  return instance.get(path);
}
export const getBackendLogOfType = (logType) => {
  let path = endpoints.SPECIFIC_BACKEND_LOG_API_PATH.replace("{logType}", logType);
  return instance.get(path, { responseType: "blob" });
}
export const logFrontendApiError = (payload) => {
  let path = endpoints.LOG_FRONTEND_API_ERROR_API_PATH;
  return instance.post(path, payload);
}

export const getAllChatQuickResponses = () => {
  let path = endpoints.GET_ALL_CHAT_QUICK_RESPONSES_API_PATH;
  return instance.get(path);
};
export const getSingleChatQuickResponse = (chatQuickResponseId) => {
  let path = endpoints.GET_SINGLE_CHAT_QUICK_RESPONSE_API_PATH.replace("{chatQuickResponseId}", chatQuickResponseId);
  return instance.get(path);
};
export const addChatQuickResponse = (payload) => {
  let path = endpoints.ADD_CHAT_QUICK_RESPONSES_API_PATH;
  return instance.post(path, payload);
};
export const editChatQuickResponse = (payload, chatQuickResponseId) => {
  let path = endpoints.EDIT_CHAT_QUICK_RESPONSE_API_PATH.replace("{chatQuickResponseId}", chatQuickResponseId);
  return instance.post(path, payload);
};
export const logUsageChatQuickResponse = (chatQuickResponseId) => {
  let path = endpoints.LOG_USAGE_CHAT_QUICK_RESPONSE_API_PATH.replace("{chatQuickResponseId}", chatQuickResponseId);
  return instance.get(path);
};

export const deleteChatQuickResponse = (chatQuickResponseId) => {
  let path = endpoints.DELETE_CHAT_QUICK_RESPONSE_API_PATH.replace("{chatQuickResponseId}", chatQuickResponseId);
  return instance.delete(path);
};

export const getAllChatQuickResponseCategories = () => {
  let path = endpoints.GET_ALL_CHAT_QUICK_RESPONSES_CATEGORIES_API_PATH;
  return instance.get(path);
};
export const getSingleChatQuickResponseCategory = (chatQuickResponseCategoryId) => {
  let path = endpoints.GET_SINGLE_CHAT_QUICK_RESPONSE_CATEGORY_API_PATH.replace("{chatQuickResponseCategoryId}", chatQuickResponseCategoryId);
  return instance.get(path);
};
export const addChatQuickResponseCategory = (payload) => {
  let path = endpoints.ADD_CHAT_QUICK_RESPONSE_CATEGORYS_CATEGORIES_API_PATH;
  return instance.post(path, payload);
};
export const editChatQuickResponseCategory = (payload, chatQuickResponseCategoryId) => {
  let path = endpoints.EDIT_CHAT_QUICK_RESPONSE_CATEGORY_API_PATH.replace("{chatQuickResponseCategoryId}", chatQuickResponseCategoryId);
  return instance.post(path, payload);
};
export const deleteChatQuickResponseCategory = (chatQuickResponseCategoryId) => {
  let path = endpoints.DELETE_CHAT_QUICK_RESPONSE_CATEGORY_API_PATH.replace("{chatQuickResponseCategoryId}", chatQuickResponseCategoryId);
  return instance.delete(path);
};

export const getAllCallRailRecordsForPhoneNumber = (phoneNumber) => {
  let path = endpoints.GET_ALL_CALLRAIL_RECORDS_FOR_PHONE_NUMBER_API_PATH.replace("{phoneNumber}", phoneNumber);
  return instance.get(path);
};

export const getAllGlassAbbreviations = () => {
  let path = endpoints.GET_ALL_GLASS_ABBREVIATIONS_API_PATH;
  return instance.get(path);
};
export const getSingleGlassAbbreviation = (glassAbbreviationId) => {
  let path = endpoints.GET_SINGLE_GLASS_ABBREVIATION_API_PATH.replace("{glassAbbreviationId}", glassAbbreviationId);
  return instance.get(path);
};
export const addGlassAbbreviation = (payload) => {
  let path = endpoints.ADD_GLASS_ABBREVIATIONS_API_PATH;
  return instance.post(path, payload);
};
export const editGlassAbbreviation = (payload, glassAbbreviationId) => {
  let path = endpoints.EDIT_GLASS_ABBREVIATION_API_PATH.replace("{glassAbbreviationId}", glassAbbreviationId);
  return instance.post(path, payload);
};
export const deleteGlassAbbreviation = (glassAbbreviationId) => {
  let path = endpoints.DELETE_GLASS_ABBREVIATION_API_PATH.replace("{glassAbbreviationId}", glassAbbreviationId);
  return instance.delete(path);
};

export const getAllIntakeFormTags = () => {
  let path = endpoints.GET_ALL_INTAKE_FORMS_TAGS_API_PATH;
  return instance.get(path);
};
export const getAllConversationTags = () => {
  let path = endpoints.GET_ALL_CONVERSATION_TAGS_API_PATH;
  return instance.get(path);
};
export const getAllTags = () => {
  let path = endpoints.GET_ALL_TAGS_API_PATH;
  return instance.get(path);
};
export const getAllTagsReusableOnly = () => {
  let path = endpoints.GET_ALL_TAGS_REUSABLE_ONLY_API_PATH;
  return instance.get(path);
};
export const getSingleTag = (tagId) => {
  let path = endpoints.GET_SINGLE_TAG_API_PATH.replace("{tagId}", tagId);
  return instance.get(path);
};
export const addTag = (payload) => {
  let path = endpoints.ADD_TAGS_API_PATH;
  return instance.post(path, payload);
};
export const editTag = (payload, tagId) => {
  let path = endpoints.EDIT_TAG_API_PATH.replace("{tagId}", tagId);
  return instance.post(path, payload);
};
export const deleteTag = (tagId) => {
  let path = endpoints.DELETE_TAG_API_PATH.replace("{tagId}", tagId);
  return instance.delete(path);
};

export const deleteFile = (fileId) => {
  let path = endpoints.DELETE_FILE_API_PATH.replace("{fileId}", fileId);
  return instance.delete(path);
};

export const getAllPartsForVin = (vin, glassType) => {
  let path = endpoints.GET_ALL_PARTS_FOR_VIN_API_PATH.replace("{vin}", vin).replace("{glassType}", glassType);
  return instance.get(path);
};
export const getAllPartsForVehicleInfo = (payload) => {
  let path = endpoints.GET_ALL_PARTS_FOR_VEHICLE_INFO;
  return instance.post(path, payload);
};
export const getAGCRMNagsPartNrSearchResult = (nagsPartNr, isAutomaticRequest = true, include_pilkington_products = null) => {
  let path = endpoints.NAGS_PART_SEARCH_API_PATH.replace("{nagsPartNr}", nagsPartNr).replace("{isAutomaticRequest}", isAutomaticRequest);
  if (include_pilkington_products !== null) {
    path += `&include_pilkington_products=${include_pilkington_products}`;
  }
  return instance.get(path);
}
export const getAGCRMNagsPartNrResultsIntakeForm = (intakeFormId) => {
  let path = endpoints.NAGS_PART_INTAKE_FORM_RESULTS_API_PATH.replace("{intakeFormId}", intakeFormId);
  return instance.get(path);
}
export const getAllMakes = () => {
  let path = endpoints.GET_ALL_MAKES_API_PATH;
  return instance.get(path);
};
export const getAllModels = (make, year) => {
  let path = endpoints.GET_ALL_MODELS_API_PATH.replace("{make}", make).replace("{year}", year);
  return instance.get(path);
};
export const getAllBodyTypes = (make, year, model) => {
  let path = endpoints.GET_ALL_BODY_TYPES_API_PATH.replace("{make}", make).replace("{year}", year).replace("{model}", model);
  return instance.get(path);
};
export const editNagsPartNrSearchResult = (nagsPartNr, editedHours) => {
  let path = endpoints.EDIT_NAGS_PART_SEARCH_RESULT_API_PATH.replace("{nagsPartNr}", nagsPartNr).replace("{editedHours}", editedHours);
  return instance.get(path);
}

export const getAllQRScanPartsForPartNumber = (partNumber) => {
  let path = endpoints.GET_PART_INFO_API_PATH.replace("{partNumber}", partNumber);
  return instance.get(path);
};

export const cancelASale = (glassReplacementJobId, reason) => {
  const payload = {
    "glass_replacement_job_id": glassReplacementJobId,
    "reason": reason
  }
  let path = endpoints.CANCEL_SALE_API_PATH;
  return instance.post(path, payload);
};

export const getCarInfoForVinLookup = (vin) => {
  let path = endpoints.GET_CAR_INFO_VIN_LOOKUP_API_PATH.replace("{vin}", vin);
  return instance.get(path);
};

export const addSquareInvoice = (payload) => {
  let path = endpoints.ADD_SQUARE_INVOICES_API_PATH;
  return instance.post(path, payload);
};
export const getAllSquareInvoices = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_SQUARE_INVOICES_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getSingleSquareInvoice = (squareInvoiceId) => {
  let path = endpoints.GET_SINGLE_SQUARE_INVOICE_API_PATH.replace("{squareInvoiceId}", squareInvoiceId);
  return instance.get(path);
};
export const editSquareInvoice = (payload, squareInvoiceId) => {
  let path = endpoints.EDIT_SQUARE_INVOICE_API_PATH.replace("{squareInvoiceId}", squareInvoiceId);
  return instance.post(path, payload);
};

export const getMobileLocationDistances = (address) => {
  let path = endpoints.GET_MOBILE_LOCATION_DISTANCES_API_PATH.replace("{address}", address);
  return instance.get(path);
};

export const getXYGPartsForNags = (nags) => {
  let path = endpoints.GET_XYG_PARTS_FOR_NAGS_API_PATH.replace("{nags}", nags);
  return instance.get(path);
};
export const getXYGPartForNags = (nags) => {
  let path = endpoints.GET_XYG_PART_FOR_NAGS_NR_API_PATH.replace("{nags}", nags);
  return instance.get(path);
};

export const getAllFilteredJobReviews = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_JOB_REVIEWS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return cancelInFlightGetRequest(path);
};
export const getSingleJobReview = (jobReviewId) => {
  let path = endpoints.GET_SINGLE_JOB_REVIEW_API_PATH.replace("{jobReviewId}", jobReviewId);
  return instance.get(path);
};
export const addJobReview = (payload) => {
  let path = endpoints.ADD_JOB_REVIEWS_API_PATH;
  return instance.post(path, payload);
};
export const editJobReview = (payload, jobReviewId) => {
  let path = endpoints.EDIT_JOB_REVIEW_API_PATH.replace("{jobReviewId}", jobReviewId);
  return instance.post(path, payload);
};
export const provideInternalUserJobReview = (payload, jobReviewId) => {
  let path = endpoints.INTERNAL_USER_PROVIDE_JOB_REVIEW_API_PATH.replace("{jobReviewId}", jobReviewId);
  return instance.post(path, payload);
};
export const deleteJobReview = (jobReviewId) => {
  let path = endpoints.DELETE_JOB_REVIEW_API_PATH.replace("{jobReviewId}", jobReviewId);
  return instance.delete(path);
};
export const selfAssignJobReview = (jobReviewId) => {
  let path = endpoints.SELF_ASSIGN_JOB_REVIEW_API_PATH.replace("{jobReviewId}", jobReviewId);
  return instance.get(path);
};
export const getAllUnacknowledgedAndUnpromptedJobReviews = (intakeFormId) => {
  let path = endpoints.GET_UNACKNOWLEDGED_OR_UNPROMPTED_JOB_REVIEWS_API_PATH.replace("{intakeFormId}", intakeFormId);
  return instance.get(path);
};
export const setAcknowledgedForJobReview = (jobReviewId) => {
  let path = endpoints.SET_ACKNOWLEDGED_FOR_JOB_REVIEW_API_PATH.replace("{jobReviewId}", jobReviewId);
  return instance.get(path);
};
export const getCountUnassignedPendingInternalJobReviews = () => {
  let path = endpoints.GET_COUNT_UNASSIGNED_PENDING_INTERNAL_JOB_REVIEWS_API_PATH;
  return instance.get(path);
};
export const addUnpromptedExpertReview = (payload) => {
  let path = endpoints.ADD_UNPROMPTED_EXPERT_JOB_REVIEW_API_PATH;
  return instance.post(path, payload);
}

export const getDynamicDashboardUrl = (payload, dashboardId) => {
  let path = endpoints.DYNAMIC_METABASE_DASHBOARD_API_PATH.replace("{dashboardId}", dashboardId);
  return instance.post(path, payload);
};

export const resetAttentionNeededCallRailRecords = () => {
  let path = endpoints.RESET_ATTENTION_NEEDED_API_PATH;
  return instance.get(path);
};

export const addSquarePayment = (payload) => {
  let path = endpoints.ADD_SQUARE_PAYMENTS_API_PATH;
  return instance.post(path, payload);
};
export const getAllSquarePayments = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_SQUARE_PAYMENTS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getSingleSquarePayment = (squarePaymentId) => {
  let path = endpoints.GET_SINGLE_SQUARE_PAYMENT_API_PATH.replace("{squarePaymentId}", squarePaymentId);
  return instance.get(path);
};
export const reprocessAllSquarePayments = () => {
  let path = endpoints.REPROCESS_SQUARE_PAYMENTS_API_PATH;
  return instance.get(path);
};
export const editSquarePayment = (payload, squarePaymentId) => {
  let path = endpoints.EDIT_SQUARE_PAYMENT_API_PATH.replace("{squarePaymentId}", squarePaymentId);
  return instance.post(path, payload);
};

export const getAllSquareLocations = () => {
  let path = endpoints.GET_ALL_SQUARE_LOCATIONS_API_PATH;
  return instance.get(path);
};
export const getSingleSquareLocation = (squareLocationId) => {
  let path = endpoints.GET_SINGLE_SQUARE_LOCATION_API_PATH.replace("{squareLocationId}", squareLocationId);
  return instance.get(path);
};
export const addSquareLocation = (payload) => {
  let path = endpoints.ADD_SQUARE_LOCATIONS_API_PATH;
  return instance.post(path, payload);
};
export const editSquareLocation = (payload, squareLocationId) => {
  let path = endpoints.EDIT_SQUARE_LOCATION_API_PATH.replace("{squareLocationId}", squareLocationId);
  return instance.post(path, payload);
};
export const reprocessAllSquareLocations = () => {
  let path = endpoints.REPROCESS_SQUARE_LOCATIONS_API_PATH;
  return instance.get(path);
};
export const deleteSquareLocation = (squareLocationId) => {
  let path = endpoints.DELETE_SQUARE_LOCATION_API_PATH.replace("{squareLocationId}", squareLocationId);
  return instance.delete(path);
};

export const getAllVendors = () => {
  let path = endpoints.GET_ALL_VENDORS_API_PATH;
  return instance.get(path);
};
export const getAllDeletedVendors = () => {
  let path = endpoints.GET_ALL_DELETED_VENDORS_API_PATH;
  return instance.get(path);
};
export const getSingleVendor = (vendorId) => {
  let path = endpoints.GET_SINGLE_VENDOR_API_PATH.replace("{vendorId}", vendorId);
  return instance.get(path);
};
export const addVendor = (payload) => {
  let path = endpoints.ADD_VENDORS_API_PATH;
  return instance.post(path, payload);
};
export const editVendor = (payload, vendorId) => {
  let path = endpoints.EDIT_VENDOR_API_PATH.replace("{vendorId}", vendorId);
  return instance.post(path, payload);
};
export const restoreVendor = (vendorId) => {
  let path = endpoints.RESTORE_DELETED_VENDOR_API_PATH.replace("{vendorId}", vendorId);
  return instance.get(path);
};
export const deleteVendor = (vendorId) => {
  let path = endpoints.DELETE_VENDOR_API_PATH.replace("{vendorId}", vendorId);
  return instance.delete(path);
};

export const getAllVendorDeliveryTimes = () => {
  let path = endpoints.GET_ALL_VENDOR_DELIVERY_TIMES_API_PATH;
  return instance.get(path);
};
export const getAllDeliveryTimesForVendor = (vendorName) => {
  let path = endpoints.GET_ALL_DELIVERY_TIMES_FOR_SPECIFIC_API_PATH.replace("{vendorName}", vendorName);
  return instance.get(path);
};
export const getSingleVendorDeliveryTime = (vendorDeliveryId) => {
  let path = endpoints.GET_SINGLE_VENDOR_DELIVERY_TIME_API_PATH.replace("{vendorDeliveryId}", vendorDeliveryId);
  return instance.get(path);
};
export const addVendorDeliveryTime = (payload) => {
  let path = endpoints.ADD_VENDOR_DELIVERY_TIMES_API_PATH;
  return instance.post(path, payload);
};
export const editVendorDeliveryTime = (payload, vendorDeliveryId) => {
  let path = endpoints.EDIT_VENDOR_DELIVERY_TIME_API_PATH.replace("{vendorDeliveryId}", vendorDeliveryId);
  return instance.post(path, payload);
};
export const deleteVendorDeliveryTime = (vendorDeliveryId) => {
  let path = endpoints.DELETE_VENDOR_DELIVERY_TIME_API_PATH.replace("{vendorDeliveryId}", vendorDeliveryId);
  return instance.delete(path);
};

export const getPriceCheckVendors = () => {
  let path = endpoints.PRICE_CHECK_VENDORS_API_PATH;
  return instance.get(path);
};
export const getPriceCheckForVendor = (nagsPartNr, vendorName) => {
  let path = endpoints.PRICE_CHECK_PER_VENDOR_API_PATH.replace("{nagsPartNr}", nagsPartNr).replace("{vendorName}", vendorName);
  return instance.get(path);
};

export const getAgdShippingTerms = (authKey, customerRegistrationKey) => {
  let path = endpoints.AGD_SHIPPING_TERMS_API_PATH.replace("{authKey}", authKey).replace("{customerRegistrationKey}", customerRegistrationKey);
  return instance.get(path);
};

export const addExternalOrder = (payload) => {
  let path = endpoints.ADD_EXTERNAL_ORDERS_API_PATH;
  return instance.post(path, payload);
};
export const getAllExternalOrders = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_EXTERNAL_ORDERS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getSingleExternalOrder = (externalOrderId) => {
  let path = endpoints.GET_SINGLE_EXTERNAL_ORDER_API_PATH.replace("{externalOrderId}", externalOrderId);
  return instance.get(path);
};
export const editExternalOrder = (payload, externalOrderId) => {
  let path = endpoints.EDIT_EXTERNAL_ORDER_API_PATH.replace("{externalOrderId}", externalOrderId);
  return instance.post(path, payload);
};
export const changeDeliveryDriverForExternalOrder = (externalOrderId, userId) => {
  let path = endpoints.CHANGE_DELIVERY_DRIVER_EXTERNAL_ORDER_API_PATH.replace("{externalOrderId}", externalOrderId).replace("{userId}", userId);
  return instance.get(path);
};

export const getAllPortalNotifications = () => {
  let path = endpoints.GET_ALL_PORTAL_NOTIFICATIONS_API_PATH;
  return instance.get(path);
};
export const getAllPortalNotificationsForSpecificUser = (userId) => {
  let path = endpoints.GET_ALL_PORTAL_NOTIFICATIONS_FOR_SPECIFIC_USER_API_PATH.replace("{userId}", userId);
  return instance.get(path);
};

export const getCountMyPortalNotifications = () => {
  let path = endpoints.GET_COUNT_ONLY_MY_PORTAL_NOTIFICATIONS_API_PATH;
  return instance.get(path);
};
export const getMyPortalNotifications = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_MY_PORTAL_NOTIFICATIONS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getSinglePortalNotification = (portalNotificationId) => {
  let path = endpoints.GET_SINGLE_PORTAL_NOTIFICATION_API_PATH.replace("{portalNotificationId}", portalNotificationId);
  return instance.get(path);
};
export const acknowledgeSinglePortalNotification = (portalNotificationId) => {
  let path = endpoints.ACKNOWLEDGDE_PORTAL_NOTIFICATION_API_PATH.replace("{portalNotificationId}", portalNotificationId);
  return instance.get(path);
};
export const addPortalNotification = (payload) => {
  let path = endpoints.ADD_PORTAL_NOTIFICATIONS_API_PATH;
  return instance.post(path, payload);
};
export const editPortalNotification = (payload, portalNotificationId) => {
  let path = endpoints.EDIT_PORTAL_NOTIFICATION_API_PATH.replace("{portalNotificationId}", portalNotificationId);
  return instance.post(path, payload);
};
export const deletePortalNotification = (portalNotificationId) => {
  let path = endpoints.DELETE_PORTAL_NOTIFICATION_API_PATH.replace("{portalNotificationId}", portalNotificationId);
  return instance.delete(path);
};

export const getAllNagsPartNrNote = () => {
  let path = endpoints.GET_ALL_NAGS_PART_NR_NOTES_API_PATH;
  return instance.get(path);
};
export const getAllNagsPartNrNotesForNagsPartNr = (nagsPartNr, displayInterchangeResults = true) => {
  let path = endpoints.GET_ALL_NAGS_PART_NR_NOTES_FOR_NAGS_PART_NR_API_PATH.replace("{nagsPartNr}", nagsPartNr);
  if (displayInterchangeResults) {
    path = path + "&display_interchange_results=true"
  }
  return instance.get(path);
};
export const getSingleNagsPartNrNote = (nagsPartNrNoteId) => {
  let path = endpoints.GET_SINGLE_NAGS_PART_NR_NOTE_API_PATH.replace("{nagsPartNrNoteId}", nagsPartNrNoteId);
  return instance.get(path);
};
export const addNagsPartNrNote = (payload) => {
  let path = endpoints.ADD_NAGS_PART_NR_NOTES_API_PATH;
  return instance.post(path, payload);
};
export const editNagsPartNrNote = (payload, nagsPartNrNoteId) => {
  let path = endpoints.EDIT_NAGS_PART_NR_NOTE_API_PATH.replace("{nagsPartNrNoteId}", nagsPartNrNoteId);
  return instance.post(path, payload);
};
export const deleteNagsPartNrNote = (nagsPartNrNoteId) => {
  let path = endpoints.DELETE_NAGS_PART_NR_NOTE_API_PATH.replace("{nagsPartNrNoteId}", nagsPartNrNoteId);
  return instance.delete(path);
};

export const getCommonHeaderCounts = () => {
  let path = endpoints.COMMON_HEADER_COUNTS_API_PATH;
  return instance.get(path);
};
export const getQuoteStageAttentionNeededCount = () => {
  let path = endpoints.QUOTE_STAGE_ATTENTION_NEEDED_COUNTS_API_PATH;
  return instance.get(path);
};

export const getAllJobsFromIntakeForm = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_JOB_ENTRIES_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const updateTechniciansForJob = (payload) => {
  let path = endpoints.EDIT_TECHNICIANS_FOR_JOB_ENTRY_API_PATH;
  return instance.post(path, payload);
};
export const updateInstallStatusForSpecificJob = (payload) => {
  let path = endpoints.EDIT_INSTALL_STATUS_FOR_JOB_ENTRY_API_PATH;
  return instance.post(path, payload);
};

export const getAllAvailableTechnicians = () => {
  let path = endpoints.GET_ALL_AVAILABLE_TECHNICIANS_API_PATH;
  return instance.get(path);
};
export const getAllAvailableCSRAgents = () => {
  let path = endpoints.GET_ALL_AVAILABLE_CSR_AGENTS_API_PATH;
  return instance.get(path);
};

export const markPhoneNumberAsFollowedUp = (phoneNumber) => {
  let path = endpoints.MARK_PHONE_NUMBER_AS_FOLLOW_UP.replace("{phoneNumber}", phoneNumber);
  return instance.get(path);
};
export const addPhoneNumberToFollowUp = (payload) => {
  let path = endpoints.ADD_PHONE_NUMBER_TO_FOLLOW_UP_API_PATH;
  return instance.post(path, payload);
};

export const refreshHomeBaseTimeCardsForAllEmployees = () => {
  let path = endpoints.REFRESH_HB_TIME_CARDS_API_PATH;
  return instance.get(path);
};

export const getAllEgrLeads = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_EGR_LEADS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getSingleEgrLead = (egrLeadId) => {
  let path = endpoints.GET_SINGLE_EGR_LEAD_API_PATH.replace("{egrLeadId}", egrLeadId);
  return instance.get(path);
};
export const assignCSRToUnderlyingIntakeFormForEgrLead = (egrLeadId) => {
  let path = endpoints.ASSIGN_CSR_TO_UNDERLYING_INTAKE_FORM_EGR_LEAD_API_PATH.replace("{egrLeadId}", egrLeadId);
  return instance.get(path);
}

export const createSingleLiabilityWaiver = (intakeFormId, waiverType) => {
  let path = endpoints.CREATE_LIABILITY_WAIVER_API_PATH.replace("{intakeFormId}", intakeFormId).replace("{waiverType}", waiverType);
  return instance.get(path);
};

export const sendSignedWaiverToCustomer = (waiverId, payload) => {
  let path = endpoints.SEND_SIGNED_WAIVER_TO_CUSTOMER.replace("{waiverId}", waiverId);
  return instance.post(path, payload);
};
export const sendWaiverToCustomerToSign = (waiverId, payload) => {
  let path = endpoints.SEND_WAIVER_TO_CUSTOMER_TO_SIGN.replace("{waiverId}", waiverId);
  return instance.post(path, payload);
};

export const getAllPortalDashboards = () => {
  let path = endpoints.GET_ALL_PORTAL_DASHBOARDS_API_PATH;
  return instance.get(path);
};
export const getAllAvailablePortalDashboards = () => {
  let path = endpoints.GET_ALL_AVAILABLE_PORTAL_DASHBOARDS_API_PATH;
  return instance.get(path);
};
export const getSinglePortalDashboard = (portalDashboardId) => {
  let path = endpoints.GET_SINGLE_PORTAL_DASHBOARD_API_PATH.replace("{portalDashboardId}", portalDashboardId);
  return instance.get(path);
};
export const addPortalDashboard = (payload) => {
  let path = endpoints.ADD_PORTAL_DASHBOARDS_API_PATH;
  return instance.post(path, payload);
};
export const editPortalDashboard = (payload, portalDashboardId) => {
  let path = endpoints.EDIT_PORTAL_DASHBOARD_API_PATH.replace("{portalDashboardId}", portalDashboardId);
  return instance.post(path, payload);
};
export const deletePortalDashboard = (portalDashboardId) => {
  let path = endpoints.DELETE_PORTAL_DASHBOARD_API_PATH.replace("{portalDashboardId}", portalDashboardId);
  return instance.delete(path);
};

export const getAllNagsNumberDetails = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_NAGS_NUMBERS_DETAILS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getExtraDetailsForNagsNumber = (xygNagsId, nagsNumber = null) => {
  let path = endpoints.GET_EXTRA_DETAILS_FOR_NAGS_NUMBER_API_PATH.replace("{xygNagsId}", xygNagsId).replace("{nagsNumber}", nagsNumber);
  return instance.get(path);
};
export const getNagsNumberPricingInfo = (nagsNumber) => {
  let path = endpoints.GET_SINGLE_NAGS_NUMBER_API_PATH.replace("{nagsNumber}", nagsNumber);
  return instance.get(path);
};
export const editNagsDetails = (payload, nagsNumber) => {
  let path = endpoints.EDIT_NAGS_NUMBER_DETAILS_API_PATH.replace("{nagsNumber}", nagsNumber);
  return instance.post(path, payload);
};
export const editNagsDetailsPriceRange = (payload, nagsNumber, rangeType) => {
  let path = endpoints.EDIT_NAGS_NUMBER_DETAILS_PRICE_RANGE_API_PATH.replace("{nagsNumber}", nagsNumber).replace("{rangeType}", rangeType);
  return instance.post(path, payload);
};
export const editNagsDetailsNonAPIVendorPrice = (payload, nagsNumber, vendorName) => {
  let path = endpoints.EDIT_NAGS_NUMBER_DETAILS_NON_API_VENDOR_PRICE_CHANGE_API_PATH.replace("{nagsNumber}", nagsNumber).replace("{vendorName}", vendorName);
  return instance.post(path, payload);
};
export const editNagsDetailsNonAPIVendorAvailability = (payload, nagsNumber, vendorName) => {
  let path = endpoints.EDIT_NAGS_NUMBER_DETAILS_NON_API_VENDOR_AVAILABILITY_CHANGE_API_PATH.replace("{nagsNumber}", nagsNumber).replace("{vendorName}", vendorName);
  return instance.post(path, payload);
};
export const editNagsDetailsDesiredQuantity = (payload, nagsNumber) => {
  let path = endpoints.EDIT_NAGS_NUMBER_DETAILS_DESIRED_QUANTITY_API_PATH.replace("{nagsNumber}", nagsNumber);
  return instance.post(path, payload);
};
export const getQRScanInventoryLiveResultsForNagsNumber = (nagsNumber) => {
  let path = endpoints.QRSCAN_INVENTORY_RESULTS_NAGS_NUMBER_API_PATH.replace("{nagsNumber}", nagsNumber);
  return instance.get(path);
};
export const refreshSpecificNagsNumbersQRScanInventory = (specificNagsNumbers) => {
  let path = endpoints.REFRESH_SPECIFIC_NAGS_NUMBERS_QRSCAN_INVENTORY_API_PATH.replace("{specificNagsNumbers}", specificNagsNumbers);
  return instance.get(path);
};

export const getAllPriceSheets = (priceSheetDetailsId = null) => {
  let path = endpoints.GET_ALL_PRICE_SHEETS_API_PATH;
  if (priceSheetDetailsId !== null) {
    path = path + `?price_sheet_detail_id=${priceSheetDetailsId}`;
  }
  return instance.get(path);
};
export const getWorkAndAmountTypes = () => {
  let path = endpoints.GET_ALL_WORK_AND_AMOUNT_TYPES_API_PATH;
  return instance.get(path);
};
export const getSinglePriceSheet = (priceSheetId) => {
  let path = endpoints.GET_SINGLE_PRICE_SHEET_API_PATH.replace("{priceSheetId}", priceSheetId);
  return instance.get(path);
};
export const addPriceSheet = (payload) => {
  let path = endpoints.ADD_PRICE_SHEETS_API_PATH;
  return instance.post(path, payload);
};
export const editPriceSheet = (payload, priceSheetId) => {
  let path = endpoints.EDIT_PRICE_SHEET_API_PATH.replace("{priceSheetId}", priceSheetId);
  return instance.post(path, payload);
};
export const deletePriceSheet = (priceSheetId) => {
  let path = endpoints.DELETE_PRICE_SHEET_API_PATH.replace("{priceSheetId}", priceSheetId);
  return instance.delete(path);
};

export const getAllPriceSheetDetails = () => {
  let path = endpoints.GET_ALL_PRICE_SHEET_DETAILS_API_PATH;
  return instance.get(path);
};
export const getSinglePriceSheetDetail = (priceSheetDetailId) => {
  let path = endpoints.GET_SINGLE_PRICE_SHEET_DETAILS_API_PATH.replace("{priceSheetDetailId}", priceSheetDetailId);
  return instance.get(path);
};
export const addPriceSheetDetail = (payload) => {
  let path = endpoints.ADD_PRICE_SHEET_DETAILS_API_PATH;
  return instance.post(path, payload);
};
export const editPriceSheetDetail = (payload, priceSheetDetailId) => {
  let path = endpoints.EDIT_PRICE_SHEET_DETAILS_API_PATH.replace("{priceSheetDetailId}", priceSheetDetailId);
  return instance.post(path, payload);
};
export const deletePriceSheetDetail = (priceSheetDetailId) => {
  let path = endpoints.DELETE_PRICE_SHEET_DETAILS_API_PATH.replace("{priceSheetDetailId}", priceSheetDetailId);
  return instance.delete(path);
};

export const getAllIntakeFormNotes = () => {
  let path = endpoints.GET_ALL_INTAKE_FORM_NOTES_API_PATH;
  return instance.get(path);
};
export const getSingleIntakeFormNote = (jobNoteId) => {
  let path = endpoints.GET_SINGLE_INTAKE_FORM_NOTES_API_PATH.replace("{jobNoteId}", jobNoteId);
  return instance.get(path);
};
export const addIntakeFormNote = (payload) => {
  let path = endpoints.ADD_INTAKE_FORM_NOTES_API_PATH;
  return instance.post(path, payload);
};
export const deleteIntakeFormNote = (jobNoteId) => {
  let path = endpoints.DELETE_INTAKE_FORM_NOTES_API_PATH.replace("{jobNoteId}", jobNoteId);
  return instance.delete(path);
};
export const jobNoteEditIntakeForm = (payload, jobNoteId) => {
  let path = endpoints.JOB_NOTE_EDIT_INTAKE_FORM_API_PATH.replace("{jobNoteId}", jobNoteId);
  return instance.post(path, payload);
};

export const liveSearchPilkingtonScrape = (searchTerm) => {
  let path = endpoints.LIVE_SEARCH_PILKINGTON_API_PATH.replace("{searchTerm}", searchTerm);
  return instance.get(path);
};

export const getAllFollowUpPendings = (priceSheetDetailsId = null) => {
  let path = endpoints.GET_ALL_CUSTOMER_FOLLOW_UP_PENDING_PHONE_NRS_API_PATH;
  if (priceSheetDetailsId !== null) {
    path = path + `?price_sheet_detail_id=${priceSheetDetailsId}`;
  }
  return instance.get(path);
};
export const getSingleFollowUpPending = (followUpPendingId) => {
  let path = endpoints.GET_SINGLE_CUSTOMER_FOLLOW_UP_PENDING_PHONE_NR_API_PATH.replace("{followUpPendingId}", followUpPendingId);
  return instance.get(path);
};
export const addFollowUpPending = (payload) => {
  let path = endpoints.ADD_CUSTOMER_FOLLOW_UP_PENDING_PHONE_NRS_API_PATH;
  return instance.post(path, payload);
};
export const editFollowUpPending = (payload, followUpPendingId) => {
  let path = endpoints.EDIT_CUSTOMER_FOLLOW_UP_PENDING_PHONE_NR_API_PATH.replace("{followUpPendingId}", followUpPendingId);
  return instance.post(path, payload);
};
export const deleteFollowUpPending = (followUpPendingId) => {
  let path = endpoints.DELETE_CUSTOMER_FOLLOW_UP_PENDING_PHONE_NR_API_PATH.replace("{followUpPendingId}", followUpPendingId);
  return instance.delete(path);
};

export const getAllSixtEmails = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_SIXT_EMAILS_API_PATH;
  path = path + `&page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const setIsIgnorableClientEmailMessage = (clientEmailMessageId, value) => {
  let path = endpoints.SET_IS_IGNORABLE_CLIENT_EMAIL_MESSAGE_API_PATH.replace("{clientEmailMessageId}", clientEmailMessageId).replace("{isIgnorable}", value);
  return instance.get(path);
}
export const setIsAcknowledgedClientEmailMessage = (clientEmailMessageId, value) => {
  let path = endpoints.SET_IS_ACKNOWLEDGED_CLIENT_EMAIL_MESSAGE_API_PATH.replace("{clientEmailMessageId}", clientEmailMessageId).replace("{isAcknowledged}", value);
  return instance.get(path);
}

export const getAllPartCancellations = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_PART_CANCELLATIONS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getSinglePartCancellation = (partCancellationId) => {
  let path = endpoints.GET_SINGLE_PART_CANCELLATION_API_PATH.replace("{partCancellationId}", partCancellationId);
  return instance.get(path);
};
export const addPartCancellation = (payload) => {
  let path = endpoints.ADD_PART_CANCELLATION_API_PATH;
  return instance.post(path, payload);
};
export const editPartCancellation = (payload, partCancellationId) => {
  let path = endpoints.EDIT_PART_CANCELLATION_API_PATH.replace("{partCancellationId}", partCancellationId);
  return instance.post(path, payload);
};
export const deletePartCancellation = (partCancellationId) => {
  let path = endpoints.DELETE_PART_CANCELLATION_API_PATH.replace("{partCancellationId}", partCancellationId);
  return instance.delete(path);
};

export const getAllGlassPickups = () => {
  let path = endpoints.GET_ALL_GLASS_PICKUP_API_PATH;
  return instance.get(path);
};
