import { useEffect, useState, useRef, useContext } from "react";
import { useHistory } from "react-router";
import { message, Input, Collapse, Typography, Row, Select, Col, Button, Drawer, Switch, Tooltip, Tabs, Spin, Divider, Space, Image, Upload, Radio, Timeline } from "antd";

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import _ from "lodash";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import TextArea from "antd/lib/input/TextArea";

import "react-chat-elements/dist/main.css";
import { MessageList, MessageBox } from "react-chat-elements";
import {
  acknowledgeAllMessagesForConvoCallRail, addTag,
  editCallRailConversationTags, getAllCallRailTextsForConversation,
  getAllChatQuickResponseCategories, getAllConversationTags,
  getCallRailConversationForPhoneNr, getFirstLastNameCallrailConversation,
  getSingleCallRailConversation, ignoreCallRailWebhookForPhoneNumber, logUsageChatQuickResponse, sendSmsMessageCallRail,
  sendSmsMessageCallRailPhoneNumber, undoAcknowledgeAllMessagesForConvoCallRail
} from "../../actions";
import AddEditChatQuickResponseModal from "../../pages/chatQuickResponses/addEditChatQuickResponseModal";
import { PlusOutlined, SnippetsFilled, QuestionCircleFilled, FullscreenOutlined } from "@ant-design/icons";
import CreateJobGenericSystemContext from "../../context/createJobGenericSystem";
import { GetCurrentLoggedInUserResponseStyle, GetUserDisplayNameAccountUsers } from "../../helpers";
import AccountUsersSystemContext from "../../context/accountUsersSystem";
import FollowUpNeededModal from "../followUpNeededModal";
import CallRailConversationFullScreenModal from "../callRailConversationFullScreenModal";
import { GLASS_STATUS_VALUES, INSTALL_STATUS_VALUES, PAYMENT_STATUS_VALUES, SALE_STAGE_VALUES, displayNoYearDateTimeFormat } from "../../global_constants";

const { Panel } = Collapse;
dayjs.extend(relativeTime);

// mode can be: FullPage, Modal, ChatMessageSystemSingleChat
const MessagesListComponent = ({
  chatMessagingSystemAllActiveConversations,
  conversationId,
  setConversationId = null,
  phoneNrOnlyChat = null,
  setPhoneNrOnlyChat = undefined,
  mode = "FullPage",
  refreshCallRailLogs = undefined,
  refreshOperation = null,
  chatElementWidth = null,
  onNewConversationInitiatedAndCallrailConversationCreated = null,
  setIsIgnoreCallRailRecordModalVisible,
  setPhoneNumberForIgnore,
  internalMessagesInfo
}) => {

  const { createJobHandler } = useContext(CreateJobGenericSystemContext);
  const { users: accountUsers } = useContext(AccountUsersSystemContext);

  const history = useHistory();

  const [messageListLoading, setMessageListLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [newMessageImageFiles, setNewMessageImageFiles] = useState([]);
  const messageListReference = useRef();
  const [messageList, setMessageList] = useState([]);
  const [isCallRailConversationFullScreenModalVisible, setCallRailConversationFullScreenModalVisible] = useState(false);

  const [tags, setTags] = useState([]);
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [newTagAddition, setNewTagAddition] = useState("");
  const [newTagAdding, setNewTagAdding] = useState(false);

  const [conversation, setConversation] = useState(undefined);

  const [customerInfo, setCustomerInfo] = useState({});

  const [isQuickResponsesDrawerVisible, setIsQuickResponsesDrawerVisible] = useState(false);
  const [quickResponses, setQuickResponses] = useState([]);
  const [quickResponseInteractionMode, setQuickResponsesDrawerInteractionMode] = useState("Replace");
  const [isAddChatQuickResponseModalVisible, setIsAddChatQuickResponseModalVisible] = useState(false);

  const [loggedInUserInfo, setLoggedInUserInfo] = useState(undefined);

  const [isImageVisible, setIsImageVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  const [isFollowUpNeededModalVisible, setIsFollowUpNeededModalVisible] = useState(false);
  const [mergedMessagesListAndInternalMessagesInfoData, setMergedMessagesListAndInternalMessagesInfoData] = useState([])

  const mergeTwoArrays = (a1, a2) => {
    var max = Math.max(a1.length, a2.length);

    var result = [];
    for (var i = 0; i < max; i++) {
      if (a2[i])
        result.push(a2[i]);
      if (a1[i])
        result.push(a1[i]);
    }
    return result;
  }

  const changedPropertyValues = {
    'SaleStatus': {
      'label': 'Sale Status',
      'values': SALE_STAGE_VALUES
    },
    'GlassStatus': {
      'label': 'Glass Status',
      'values': GLASS_STATUS_VALUES
    },
    'InstallStatus': {
      'label': 'Install Status',
      'values': INSTALL_STATUS_VALUES
    },
    'PaymentStatus': {
      'label': 'Payment Status',
      'values': PAYMENT_STATUS_VALUES
    },
  }

  const installStatusChangedHistoryValues = [
    {
      label: "Install Status",
      value: "install_status"
    },
    {
      label: 'Glass Logistics Status',
      value: "glass_logistics_status"
    },
  ]

  const createIntakeFormHistoryData = (history) => {
    const oldValue = changedPropertyValues[history.changed_property].values.find(x => x.value == history.old_value)?.label || ''
    const newValue = changedPropertyValues[history.changed_property].values.find(x => x.value == history.new_value)?.label || ''

    return {
      "agent_name": "INTERNAL",
      "direction": "Outgoing",
      "content": `${changedPropertyValues[history.changed_property].label} change ${oldValue ? `from ${oldValue} -> ${newValue}` : `to ${newValue}`}`,
      "conversation_id": internalMessagesInfo.callrail_conversation_id,
      "timestamp": history.created_date,
      "is_message_acknowledged": true,
      "media_url": null,
      "created_date": history.created_date,
      "modified_date": history.created_date,
      "is_internal_message": true
    };
  };

  const createIntakeFormNotesData = (note) => {
    return {
      "agent_name": "INTERNAL",
      "direction": "Outgoing",
      "content": `Note: ${note.note}`,
      "conversation_id": internalMessagesInfo.callrail_conversation_id,
      "timestamp": note.created_date,
      "is_message_acknowledged": true,
      "media_url": null,
      "created_date": note.created_date,
      "modified_date": note.created_date,
      "is_internal_message": true
    };
  };

  const createReviewDetailData = (review_detail) => {
    return {
      "agent_name": "INTERNAL",
      "direction": "Outgoing",
      "content": `Conversation marked as Reviewed by ${GetUserDisplayNameAccountUsers(accountUsers, review_detail?.user_created_by_id) ?? ''}`,
      "conversation_id": internalMessagesInfo.callrail_conversation_id,
      "timestamp": review_detail.reviewed_at,
      "is_message_acknowledged": true,
      "media_url": null,
      "created_date": review_detail.reviewed_at,
      "modified_date": review_detail.reviewed_at,
      "is_internal_message": true
    };
  };

  const loadAllMessages = (conversationId, isRefresh = false) => {
    if (conversationId === null || conversationId === undefined) return;

    // if we are loading this from new chat tab messaging system, we get the 
    // current value from parent list object (chatMessagingSystemAllActiveConversations)
    // console.log("chatMessagingSystemAllActiveConversations loadAllMessages", chatMessagingSystemAllActiveConversations)
    if (chatMessagingSystemAllActiveConversations !== undefined) {
      const matchedConversation = (chatMessagingSystemAllActiveConversations || []).filter(x => x.id === conversationId);
      if (matchedConversation.length !== 0) {
        if (matchedConversation[0].message_list !== undefined) {
          setMergedMessagesListAndInternalMessagesInfoData({
            "message_list": matchedConversation[0].message_list,
            "call_list": matchedConversation[0].calls_list,
            "expert_reviewed_user_id": internalMessagesInfo?.expert_reviewed_user_id,
            "intake_form_histories": internalMessagesInfo?.intake_form_histories,
            "intake_form_notes": internalMessagesInfo?.intake_form_notes,
            "ordered_by_user_datetime": internalMessagesInfo?.ordered_by_user_datetime,
            "ordered_by_user_id": internalMessagesInfo?.ordered_by_user_id,
            "job_status_change_history": internalMessagesInfo?.job_status_change_history,
            "review_details": internalMessagesInfo?.review_details
          })
          const intakeFormHistoriesData = []
          const intakeFormNotesData = []
          const reviewDetails = []

          internalMessagesInfo?.intake_form_histories.map(history => {
            intakeFormHistoriesData.push(createIntakeFormHistoryData(history));
          });

          internalMessagesInfo?.intake_form_notes.map(note => {
            intakeFormNotesData.push(createIntakeFormNotesData(note));
          });

          internalMessagesInfo?.review_details.map(review_detail => {
            reviewDetails.push(createReviewDetailData(review_detail))
          })

          const mergedMessagesAndCalls = mergeTwoArrays(
            mergeTwoArrays(
              mergeTwoArrays(
                matchedConversation[0].message_list,
                matchedConversation[0].calls_list
              ),
              mergeTwoArrays(
                intakeFormHistoriesData,
                intakeFormNotesData
              )
            ),
            reviewDetails
          );
          const sortedByDate = _.sortBy(mergedMessagesAndCalls, (x) => x.created_date);
          setMessageList(sortedByDate);
          setMessageListLoading(false);
          return;
        }
      }
      console.log("Unsuccessful parent match - not loading manually, waiting for bulk load op. Conv ID: ", conversationId);
      return;
    }


    if (!isRefresh) setMessageListLoading(true);

    getAllCallRailTextsForConversation(conversationId).request.then((response) => {
      const { data } = response;
      setMessageList(data);
      if (!isRefresh) setMessageListLoading(false);
    }).catch((err) => {
      if (!isRefresh) setMessageListLoading(false);
      message.error(JSON.stringify(err?.response?.data?.detail));
    });
  }

  const loadCustomerInfo = (conversationObject) => {
    const conversationId = conversationObject.id;
    setCustomerInfo({});
    // fetch first last name from backend
    getFirstLastNameCallrailConversation(conversationId).then((response) => {
      const { data } = response;
      const { status, status_display, details } = data;
      const { first, last } = details;
      setCustomerInfo({ ...customerInfo, first: first, last: last });
    }).catch((err) => {
      message.warning(err?.response?.data?.detail || "Unknown error while fetching customer first and last name - please contact developer with information about how this occured.");
    })
  }

  const loadConversation = (conversationId) => {
    setSelectedTagIds([]);
    getSingleCallRailConversation(conversationId).then((response) => {
      const { data } = response;
      loadCustomerInfo(data);
      setConversation(data);
      setSelectedTagIds((data.tags || []).map(tag => tag.id));
    }).catch((err) => {
      message.error("Error while loading conversation details, there could be some issues present while you use current functionality");
    });
  }

  const tryFetchConvoForPhoneNr = (phoneNr) => {
    getCallRailConversationForPhoneNr(phoneNr).request.then((response) => {
      const { data } = response;
      setConversationId(data.id);
      if (onNewConversationInitiatedAndCallrailConversationCreated !== null) {
        onNewConversationInitiatedAndCallrailConversationCreated(data);
      }
    }).catch((err) => {
      if (err.response.status === 404) {
        setTimeout(() => {
          tryFetchConvoForPhoneNr(phoneNr);
        }, 1000);
        return;
      }
      message.error("Error while fetching conversation for phone number, please contact support or try refreshing the page and see if the text went through");
    });
  }

  const sendMessage = (messageText) => {
    if ((conversationId === null || conversationId === undefined) && (phoneNrOnlyChat === null || phoneNrOnlyChat === undefined)) {
      message.warning("No conversation or phone number selected!");
      return;
    }
    if (messageText === "" && newMessageImageFiles.length === 0) {
      message.warning("Please enter message text or add image files to send - empty messages are not allowed! ");
      return;
    }
    setNewMessage("");
    const payload = {
      content: messageText,
      media_urls: newMessageImageFiles
    }
    message.info("Sent!", 5);

    const func = (phoneNrOnlyChat === null || phoneNrOnlyChat === undefined) ? sendSmsMessageCallRail : sendSmsMessageCallRailPhoneNumber;
    const param = (phoneNrOnlyChat === null || phoneNrOnlyChat === undefined) ? conversationId : phoneNrOnlyChat;
    let isNewConversationInitiated = false;
    if (phoneNrOnlyChat !== null && phoneNrOnlyChat !== undefined) {
      setPhoneNrOnlyChat(null);
      isNewConversationInitiated = true;
    }
    func(payload, param)
      .then((response) => {
        if (refreshCallRailLogs !== undefined) refreshCallRailLogs();

        loadAllMessages(conversationId);
        const { data } = response;

        if (isNewConversationInitiated) {
          setMessageList([{
            id: uuidv4(),
            is_send_agent_initiated: true,
            agent_name: "Sending...",
            content: messageText,
          }]);
          setTimeout(() => {
            tryFetchConvoForPhoneNr(param);
          }, 4000);
        }

        if (data.status === "Sent") {
          message.success(data.status_display);
          return;
        }

        message.error(data.status_display);
        setNewMessage(messageText);
      })
      .catch((error) => {
        message.error("Error occurred while sending the message - please check-in with Sales Portal support. Please make sure you note the phone number.", 15);
      })
  }

  const sharedSuccessfulResponseAction = (response) => {
    if (refreshCallRailLogs !== undefined) refreshCallRailLogs();
    if (refreshOperation !== null) refreshOperation();
    const { data } = response;
    message.success(data.status_display);
  }

  const setAcknowledgeAllConversationMessages = (isAcknowledgeAction) => {
    if (isAcknowledgeAction) {
      acknowledgeAllMessagesForConvoCallRail(conversationId).then((response) => {
        sharedSuccessfulResponseAction(response);
      }).catch((error) => {
        message.warning(error?.response?.data?.status_display || "Error while acknowledging all messages for this conversation - unknown issue - contact the developer");
      })
    } else {
      undoAcknowledgeAllMessagesForConvoCallRail(conversationId).then((response) => {
        sharedSuccessfulResponseAction(response);
      }).catch((error) => {
        message.warning(error?.response?.data?.status_display || "Error while undoing the acknowledged all messages for this conversation - unknown issue - contact the developer");
      })
    }
  }

  const openJobsForPhoneNumber = () => {
    // Get current url. 
    // If it contains "/intake-forms" then load the target url
    // Else, push via router
    // Reason for this: if we are already on the intake forms page, changing the url doesn't properly fetch new entries
    // ideally intake forms filtering should be revisited and fixed, but for now this is a workaround
    const currentUrl = window.location.href;
    console.log("Current URL: ", currentUrl);
    const targetUrl = `/intake-forms?attributes=search_term%3D%2B${conversation.customer_phone_number}&intakeFormPage=1`;
    if (currentUrl.includes("/intake-forms")) {
      window.location.href = targetUrl;
      return;
    }
    history.push(targetUrl);
  }

  const getMessageStatus = (message) => {
    if (!message.agent_name) return "";
    return "received";
  }

  const getAllQuickResponses = () => {
    getAllChatQuickResponseCategories().then((response) => {
      const { data } = response;
      setQuickResponses(data);
    }).catch((error) => {
      message.error("Issue loading all quick responses, please retry loading the chat message modal");
    })
  }

  const loadCurrentUserInfo = () => {
    GetCurrentLoggedInUserResponseStyle(accountUsers).then((response) => {
      const userInfo = response.data;
      setLoggedInUserInfo(userInfo);
    }).catch((err) => {
      message.error(`Error while loading info for user: ${JSON.stringify(err?.response?.data?.detail)}`);
    });
  };

  const addNewConversationTag = () => {
    setNewTagAddition("");
    setNewTagAdding(true);
    const payload = { name: newTagAddition, is_reusable: false, type: "Conversation" };
    addTag(payload)
      .then((response) => {
        const { data } = response;
        let tagsCopy = tags;
        tagsCopy.push({ ...data, is_just_added_by_user: true });
        setTags(tagsCopy);
        setNewTagAdding(false);
        console.log(data);
      })
      .catch((error) => {
        message.warning("Error while adding a new tag, please reload the page and try again.")
      })
  }

  const getTags = () => {
    getAllConversationTags().then((response) => {
      const { data } = response;
      console.log(data);
      setTags(data);
    }).catch((err) => {
      message.error(`Error while loading conversation tags, please retry later: ${JSON.stringify(err?.response?.data?.detail)}`);
    })
  }

  useEffect(() => {
    if (chatMessagingSystemAllActiveConversations === undefined) return;

    loadAllMessages(conversationId, true)
    const intervalId = setInterval(() => {
      loadAllMessages(conversationId, true)
    }, 1500);
    return () => {
      clearInterval(intervalId)
    };
  }, [chatMessagingSystemAllActiveConversations]);

  useEffect(() => {
    if (conversationId === null || conversationId === undefined) return;

    // console.log("messageList, conversationId, chatMessagingSystemAllActiveConversations", messageList, conversationId, chatMessagingSystemAllActiveConversations)
    loadConversation(conversationId);

    // this will most likely occur when the Messages page is active
    if (chatMessagingSystemAllActiveConversations === undefined) {
      loadAllMessages(conversationId)
      const intervalId = setInterval(() => {
        loadAllMessages(conversationId, true)
      }, 1500);
      return () => {
        clearInterval(intervalId)
      };
    }
  }, [conversationId]);

  useEffect(() => {
    getAllQuickResponses();
    loadCurrentUserInfo();
    getTags();
  }, [])

  const messagesComponent = (
    <If condition={messageListLoading}>
      <Spin />
      <Else />
      <MessageList
        referance={messageListReference}
        className='message-list main'
        lockable={true}
        toBottomHeight={"100%"}
        style={{
          marginLeft: "20px",
          marginRight: "20px",
        }}
        onOpen={(x, i, e) => {
          const matchedObject = messageList[i];
          setImageSrc(matchedObject.media_url);
          setIsImageVisible(true);
        }}
        dataSource={
          messageList.map((message) => {
            // considering that we are merging callrail calls and messages
            // we need to determine if the "message" object is message or call
            // different formatting is applied for each
            let messageFormatted = {};
            const isCall = message.is_message_acknowledged === undefined;
            if (isCall) {
              // call type
              messageFormatted = {
                status: "received",
                position: message.status === "OutboundCall" ? "right" : "left",
                type: "text",
                title: message.status === "OutboundCall" ? message.agent_email : conversation?.customer_formatted_name || "Customer",
                text: message.status === "OutboundCall" ? "📞 Outgoing Call" : (message.status === "MissedCall" ? "📞 MISSED " : "📞 Answered ") + "Incoming Call",
                date: moment.utc(message.created_date).local().toDate(),
              }
              if (message.status === "MissedCall") {
                messageFormatted.titleColor = "red";
              }

            }
            if (!isCall) {
              // message type
              let dateVal = new Date();
              if (message.timestamp !== undefined) {
                dateVal = moment.utc(message.timestamp).local().toDate()
              }
              const isMediaType = message.media_url !== null;
              messageFormatted = {
                status: getMessageStatus(message),
                position: message.direction === "Outgoing" ? "right" : "left",
                type: isMediaType ? "photo" : "text",
                title: message.direction === "Outgoing" ? message.agent_name : conversation?.customer_formatted_name || "Customer",
                text: message.content,
                date: dateVal,
              }
              if (isMediaType) {
                messageFormatted.data = {
                  uri: message.media_url,
                  height: 300,
                };
              }
            }
            const className = !isCall && message.is_internal_message ? "internal-message-color" : "";

            return { ...messageFormatted, className };
          })}
      />
    </If>
  )

  const moreOptionsComponent = () => {
    return (
      <>
        <Row style={{ marginTop: '10px' }}>
          <Col flex="auto">
            <i> Press CTRL+Enter to quick send a message</i>
          </Col>
          <Col flex="80px">
            <Button
              type="info"
              onClick={() => setAcknowledgeAllConversationMessages(true)}
            >
              Review
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: '5px' }}>
          <If condition={mode === "ChatMessageSystemSingleChat"}>
            <Space key={"chatMessageSystemButtons"}>
              <Col flex="80px">
                <Button
                  type="info"
                  size="small"
                  onClick={() => {
                    openJobsForPhoneNumber();
                  }}
                >
                  View Jobs
                </Button>
              </Col>
              <Col flex="80px">
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    createJobHandler({ conversation, customerInfo })
                  }}
                >
                  Create Job
                </Button>
              </Col>
              <Col flex="80px">
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    setIsIgnoreCallRailRecordModalVisible(true);
                    setPhoneNumberForIgnore(conversation?.customer_phone_number);
                  }}
                >
                  Ignore
                </Button>
              </Col>
            </Space>
          </If>
          <Col flex="auto"></Col>
          <Col flex="80px">
            <Button
              type="info"
              size="small"
              onClick={() => {
                setIsFollowUpNeededModalVisible(true);
              }}
            >
              Follow up Needed
            </Button>
          </Col>
        </Row>
      </>
    )
  };

  const inputComponent = (
    <>
      <Input.Group compact>
        <TextArea
          style={{ width: 'calc(100% - 63px)' }}
          placeholder="Type a new message here..."
          multiline={true}
          autoSize={{ minRows: 2, maxRows: 6 }}
          value={newMessage}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && e.ctrlKey) {
              sendMessage(newMessage);
            }
          }}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <Button
          type="primary"
          onClick={() => sendMessage(newMessage)}
        >
          Send
        </Button>
        {/* <Upload
          ond
          listType="picture"
          onChange={(info) => {
            // on change we set the list of new message image files to the current state of upload component
            // this is done so that we can show the user the preview of the image before sending it
            setNewMessageImageFiles(info.fileList.map((file) => file.url));
          }}
          fileList={newMessageImageFiles.map((image, index) => {
            return {
              uid: `Image-${index}`,
              name: `Image ${index + 1}`,
              status: 'done',
              url: image,
            }
          })}
        >
        </Upload> */}
      </Input.Group>
      <If condition={chatElementWidth !== 425}>
        <Collapse bordered={false} >
          <Panel header="More" key="1">
            {moreOptionsComponent()}
          </Panel>
        </Collapse>
      </If>
      <If condition={chatElementWidth === 425}>
        {moreOptionsComponent()}
      </If>
    </>
  )

  const generateTabsHistory = () => {
    // Transform message_list
    const transformedMessageLists = mergedMessagesListAndInternalMessagesInfoData?.message_list?.map(message => ({
      label: message.created_date,
      children: (
        <span>
          {message.content ? (
            message.content
          ) : (
            <Image
              alt=""
              style={{ maxHeight: '180px' }}
              src={message.media_url}
            />
          )}
        </span>
      ),
      state: `${message.direction} SMS`
    })) ?? [];

    const transformedCallLists = mergedMessagesListAndInternalMessagesInfoData?.call_list?.map(message => ({
      label: message.created_date,
      children: `${message.first_name} ${message.last_name}`,
      state: message.status === "OutboundCall" ? "📞 Outgoing Call" : (message.status === "MissedCall" ? "📞 MISSED " : "📞 Answered ") + "Incoming Call"
    })) ?? [];

    let reviewDetailsLists = []
    if (mergedMessagesListAndInternalMessagesInfoData.review_details) {
      reviewDetailsLists = mergedMessagesListAndInternalMessagesInfoData?.review_details?.map(reviewDetail => ({
        label: reviewDetail.reviewed_at,
        children: `Conversation marked as Reviewed by ${GetUserDisplayNameAccountUsers(accountUsers, reviewDetail?.user_created_by_id) ?? ''}`,
        state: "Reviewed Info"
      })) ?? [];
    }

    // Transform intake_form_histories
    const transformedHistories = mergedMessagesListAndInternalMessagesInfoData?.intake_form_histories?.map(history => {
      const changedProperty = changedPropertyValues[history.changed_property];
      const oldValueLabel = changedProperty.values.find(x => x.value == history.old_value)?.label;
      const newValueLabel = changedProperty.values.find(x => x.value == history.new_value)?.label;

      const changeDescription = oldValueLabel
        ? `from ${oldValueLabel} -> ${newValueLabel}`
        : `to ${newValueLabel}`;

      return {
        label: history.created_date,
        children: `${changedProperty.label} change ${changeDescription}`,
        state: `${changedProperty.label}`
      };
    }) ?? [];

    const transformedJobStatusChangeHistories = mergedMessagesListAndInternalMessagesInfoData?.job_status_change_history?.map(history => {
      const changedProperty = installStatusChangedHistoryValues.find(status => status.value === history.changed_property)?.label;
      const oldValueLabel = INSTALL_STATUS_VALUES.find(status => status.value === history.old_value)?.label;
      const newValueLabel = INSTALL_STATUS_VALUES.find(status => status.value === history.new_value)?.label;

      const changeDescription = oldValueLabel
        ? `from ${oldValueLabel} -> ${newValueLabel}`
        : `to ${newValueLabel}`;

      return {
        label: history.created_date,
        children: `${changedProperty} change ${changeDescription}`,
        state: 'Job Status Change History'
      };
    }) ?? [];

    // Transform intake_form_notes
    const transformedNotes = mergedMessagesListAndInternalMessagesInfoData?.intake_form_notes?.map(note => ({
      label: note.created_date,
      children: note.note,
      state: 'Note'
    })) ?? [];

    const orderedByUser = [];
    if (mergedMessagesListAndInternalMessagesInfoData?.ordered_by_user_datetime && mergedMessagesListAndInternalMessagesInfoData?.ordered_by_user_id) {
      orderedByUser.push({
        label: mergedMessagesListAndInternalMessagesInfoData.ordered_by_user_datetime,
        children: `Ordered by ${GetUserDisplayNameAccountUsers(accountUsers, mergedMessagesListAndInternalMessagesInfoData?.ordered_by_user_id) ?? ''}`,
        state: 'Ordered By User'
      });
    }

    const reviewedByUser = [];
    if (mergedMessagesListAndInternalMessagesInfoData?.expert_reviewed_user_id) {
      reviewedByUser.push({
        label: mergedMessagesListAndInternalMessagesInfoData.ordered_by_user_datetime,
        children: `Reviewed by ${GetUserDisplayNameAccountUsers(accountUsers, mergedMessagesListAndInternalMessagesInfoData?.expert_reviewed_user_id) ?? ''}`,
        state: 'Reviewed By User'
      });
    }

    // Combine all transformations into one array
    let transformedData = [
      ...transformedMessageLists,
      ...transformedHistories,
      ...transformedNotes,
      ...orderedByUser,
      ...transformedJobStatusChangeHistories,
      ...transformedCallLists,
      ...reviewDetailsLists
    ];

    return _.sortBy(transformedData, x => x.label)
  }

  function getColorForState(state) {
    switch (state) {
      case 'Incoming SMS': return '#1f77b4'; // Dark blue
      case 'Outgoing SMS': return '#ff7f0e'; // Orange
      case 'Internal SMS': return '#2ca02c'; // Green
      case 'Note': return '#17becf'; // Cyan
      case 'Ordered By User': return '#9467bd'; // Purple
      case 'Reviewed By User': return '#8c564b'; // Brown
      case 'Job Status Change History': return '#e377c2'; // Pink
      case '📞 MISSED Incoming Call': return '#d62728'; // Red
      case '📞 Answered Incoming Call': return '#aec7e8'; // Light blue
      case '📞 Outgoing Call': return '#98df8a'; // Light green
      case 'Sale Status': return '#ffbb78'; // Light orange
      case 'Glass Status': return '#ff9896'; // Light red
      case 'Install Status': return '#bcbd22'; // Yellow-green
      case 'Payment Status': return '#7f7f7f'; // Gray
      case 'Glass Logistics Status': return '#ff9898'; // Light yellow
      case 'Form': return '#1f77b4'; // Dark blue (different from 'Note')
      case 'Reviewed Info': return '#ff69b4'; // Dark Pink
      default: return '#ff7f0e'; // Orange (default color)
    }
  }

  const historyItems = generateTabsHistory().map((item, index) => (
    <Timeline.Item key={index} color={getColorForState(item.state)}>
      <div>{item.children}</div>
      <span style={{ fontSize: "10px" }}>
        {dayjs(item.label).fromNow()}
        <Tooltip title={moment(item.label).format(displayNoYearDateTimeFormat)}>
          <QuestionCircleFilled style={{ marginLeft: '5px', verticalAlign: 'middle' }} />
        </Tooltip>
        <p>{item.state}</p>
      </span>
    </Timeline.Item>
  ));

  const historyComponent = (
    <>
      <Timeline mode="left">
        {historyItems}
      </Timeline>
    </>
  )

  const acknowledgeAllConversationMessagesComponent = (
    <Row style={{ marginBottom: '15px' }}>
      <Col flex="auto">
        <Select
          value={selectedTagIds}
          onChange={(e) => {
            setSelectedTagIds(e);
            editCallRailConversationTags({ tags: e }, conversationId)
            console.log(e);
          }}
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder="Select Tags"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
          dropdownRender={(menu) => {
            const displayNode = (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Row style={{ marginLeft: "10px", marginRight: '10px' }}>
                  <Col flex="auto">
                    <Input
                      placeholder="Enter new tag"
                      value={newTagAddition}
                      onChange={(e) => setNewTagAddition(e.target.value)} />
                  </Col>
                  <Col flex="200px">
                    <Typography.Link
                      onClick={addNewConversationTag}
                      style={{ whiteSpace: 'nowrap' }}>
                      <PlusOutlined /> Add Tag (not reusable)
                    </Typography.Link>
                  </Col>
                </Row>
              </>
            )
            if (newTagAdding) {
              return (
                <Spin>
                  {displayNode}
                </Spin>
              )
            }
            return displayNode
          }}
        >
          {tags.map((tag) => {
            if (tag.is_just_added_by_user !== true) {
              if (!tag.is_reusable && !selectedTagIds.includes(tag.id)) {
                return <></>;
              }
            }
            return (
              <Option
                value={tag.id}
                key={tag.id}
              >
                {tag.name}
              </Option>
            );
          })}
        </Select>
      </Col>
      <Col flex="100px">
        <Button
          type="primary"
          onClick={() => setIsQuickResponsesDrawerVisible(true)}
          icon={<SnippetsFilled />}>
          Quick Responses
        </Button>
      </Col>
    </Row>
  )

  const showCallRailConversationFullScreenModal = (value) => {
    setCallRailConversationFullScreenModalVisible(value);
  };

  return (
    <>
      <If condition={mode === "ChatMessageSystemSingleChat" || mode === "Modal"}>
        <If condition={mode !== "Modal"}>
          <span>
            <FullscreenOutlined onClick={() => showCallRailConversationFullScreenModal(true)} style={{ position: "absolute", right: "20px", top: "20px", cursor: "pointer", zIndex: 1 }} />
          </span>
        </If>
        <Tabs defaultActiveKey="1" style={{ width: "100%" }}>
          <Tabs.TabPane key="chat-tab" tab="Chat">
            {acknowledgeAllConversationMessagesComponent}
            <div className={"scrollable-container-message-list-fb-style"}>
              {messagesComponent}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key="history-tab" tab="History">
            <div className={"scrollable-container-message-list-fb-style"}>
              {historyComponent}
            </div>
          </Tabs.TabPane>
        </Tabs>
        {inputComponent}
      </If>
      <If condition={mode === "FullPage"}>
        {acknowledgeAllConversationMessagesComponent}
        <div className={"scrollable-container-message-list"}>
          {messagesComponent}
        </div>
        {inputComponent}
      </If>
      <Image
        width={2}
        style={{
          display: 'none',
          zIndex: 1002,
        }}
        src={imageSrc}
        preview={{
          visible: isImageVisible,
          scaleStep: 0.5,
          src: imageSrc,
          onVisibleChange: (value) => {
            setIsImageVisible(value);
          },
        }}
      />
      <FollowUpNeededModal
        isModalVisible={isFollowUpNeededModalVisible}
        setIsModalVisible={setIsFollowUpNeededModalVisible}
        selectedPhoneNumber={phoneNrOnlyChat || conversation?.customer_phone_number}
        onComplete={() => {
          // we are no longer acknowledging all messages.
          // Task going in a bit more detail: https://trello.com/c/e5tClSMe/9-pop-up-gets-blocked-by-chat-when-try-to-minimize-chat-pop-up-goes-away#comment-660baa3b354eef9814414a12
          // setAcknowledgeAllConversationMessages(false);
        }}
      />
      {/* TODO: Extract this to a component */}
      <Drawer
        title={
          <Row>
            <Col flex="auto">
              <p>Quick Response</p>
            </Col>
            <Col flex="100px">
              <Button
                type="primary"
                onClick={() => setIsAddChatQuickResponseModalVisible(true)}
              > Add New </Button>
            </Col>
          </Row>
        }
        placement="right"
        style={{ zIndex: 1002 }}
        closable={false}
        onClose={() => setIsQuickResponsesDrawerVisible(false)}
        visible={isQuickResponsesDrawerVisible}
      >
        <Tooltip
          placement="left"
          title={
            <>
              <p>Append mode will add the clicked message text to message you currently have in chat input.</p>
              <br />
              <p>Replace mode will replace the message you currently have in chat input with the clicked message text</p>
            </>
          }>
          <p>Message interaction mode:</p>
          <Switch
            checked={quickResponseInteractionMode === "Replace"}
            onChange={(checked, _) => setQuickResponsesDrawerInteractionMode(checked ? "Replace" : "Append")}
            checkedChildren="Replace"
            unCheckedChildren="Append" />
        </Tooltip>

        <i style={{ marginTop: "10px", display: "block" }}>Click on a message to add it to your message</i>
        <Collapse>
          {quickResponses.map((quickResponseCategory) => {
            return (
              <Panel header={quickResponseCategory.name} key={quickResponseCategory.id}>
                {quickResponseCategory.chat_quick_responses_ordered_num_used.map((response) => {
                  var messageWithVariableValuesApplied = response.content;
                  var mediaType = response.content_type === "Image" ? "photo" : "text";
                  const isMedia = response.content_type === "Image";

                  if (loggedInUserInfo !== undefined && !isMedia) {
                    messageWithVariableValuesApplied = messageWithVariableValuesApplied
                      .replace("<employee_first>", loggedInUserInfo.first_name)
                      .replace("<employee_last>", loggedInUserInfo.last_name)
                      .replace("<email>", loggedInUserInfo.email)
                      .replace("<customer_first>", customerInfo.first)
                      .replace("<customer_last>", customerInfo.last)
                  }
                  return (
                    <MessageBox
                      position={"right"}
                      type={mediaType}
                      text={messageWithVariableValuesApplied}
                      className="preset-message"
                      date={null}
                      onClick={() => {
                        logUsageChatQuickResponse(response.id).catch((err) => console.log("error while logging the chat usage"));
                        if (isMedia) {
                          if (!newMessageImageFiles.includes(response.media_url)) {
                            // even if the user tries to add the same image twice, it will be added only once
                            setNewMessageImageFiles([...newMessageImageFiles, response.media_url])
                          }
                        }
                        if (quickResponseInteractionMode === "Replace") {
                          setNewMessage(messageWithVariableValuesApplied);
                        } else {
                          setNewMessage((currMsg) => `${currMsg} ${messageWithVariableValuesApplied}`);
                        }
                        setIsQuickResponsesDrawerVisible(false);
                      }}
                      data={mediaType === "photo" ? { uri: response.media_url, alt: "small-image" } : null}
                    />
                  )
                })}

              </Panel>
            )
          })}
        </Collapse>
        <AddEditChatQuickResponseModal
          isModalVisible={isAddChatQuickResponseModalVisible}
          getAllChatQuickResponses={getAllQuickResponses}
          modalMode="Add"
          setIsModalVisible={setIsAddChatQuickResponseModalVisible}
        />
      </Drawer>
      <CallRailConversationFullScreenModal
        isModalVisible={isCallRailConversationFullScreenModalVisible}
        chatMessagingSystemAllActiveConversations={chatMessagingSystemAllActiveConversations}
        setIsModalVisible={setCallRailConversationFullScreenModalVisible}
        conversationId={conversationId}
        setConversationId={setConversationId}
        internalMessagesInfo={internalMessagesInfo}
        setPhoneNumberForIgnore={setPhoneNumberForIgnore}
        refreshCallRailLogs={refreshCallRailLogs}
      />
    </>
  )

}

export default MessagesListComponent
