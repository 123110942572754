import { useEffect, useState, createContext, useRef, useContext } from "react";
import { Modal, Form, Input, message, Checkbox, Collapse, Typography, Row, Select, Col, Radio, InputNumber, DatePicker, Popconfirm, Button, Table, Drawer, List, Spin, Space, Badge, Tooltip } from "antd";

import moment from 'moment';
import debounce from 'lodash/debounce';
import { v4 as uuidv4 } from 'uuid';

import TextArea from "antd/lib/input/TextArea";
import { getAllNagsPartNrNotesForNagsPartNr, getPriceCheckForVendor, getPriceCheckVendors } from "../../actions";
import NagsPartNrNotesListComponent from "../nagsPartNrNotesListComponent";
import { InfoCircleOutlined } from "@ant-design/icons";
import PilkingtonDataComponent from "../pilkingtonDataComponent";
import PilkingtonDataPanelComponent from "../pilkingtonDataPanelComponent";

const { Panel } = Collapse;


const PriceCheckDrawerComponent = ({ isDrawerVisible, setIsDrawerVisible, nagsPartNrSearch, selectVendor }) => {

  const [dataLoading, setDataLoading] = useState(false);
  const [priceCheckData, setPriceCheckData] = useState([]);

  const [nagsPartNrs, setNagsPartNrs] = useState([]);
  const [nagsPartNrsNotes, setNagsPartNrsNotes] = useState([]);

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  }

  const fetchNotes = (nagsPartNr) => {
    getAllNagsPartNrNotesForNagsPartNr(nagsPartNr).then((response) => {
      const { data } = response;
      setNagsPartNrsNotes(curr => {
        let wasNoteUpdated = false;
        const newObject = [...curr.map(x => {
          if (x.nags_part_nr !== nagsPartNr) return x;

          x.is_loading = false;
          x.notes = data;
          wasNoteUpdated = true;
          return x;
        })];
        if (!wasNoteUpdated) {
          alert("Note fetching for price check failed, please retry - error with: " + nagsPartNr)
        }
        return newObject;
      });
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
    })
  }

  const priceCheckPerVendor = (nagsPartNr, vendorName) => {
    getPriceCheckForVendor(nagsPartNr, vendorName).then((response) => {
      const { data } = response;
      setPriceCheckData((curr) => {
        return curr.map((vendorPriceCheckData) => {
          if (vendorPriceCheckData.vendor === vendorName) {
            vendorPriceCheckData = data;
            vendorPriceCheckData.isLoading = false;

            // adding new nags part numbers - used for fetching installation notes
            const partNrs = data.vendor_price_check_data.map(x => x.nags_part_nr);
            setNagsPartNrs((currNagsPartNrs) => { 
              for (const partNr of partNrs) {
                if (!currNagsPartNrs.includes(partNr)){
                  currNagsPartNrs.push(partNr);
                }
              }
              return [...currNagsPartNrs];
            })
          }
          return vendorPriceCheckData;
        })
      })
    }).catch((error) => {
      message.error("Error occurred while fetching price check for vendor " + vendorName);
    })
  }

  const getAllPriceCheckVendors = (nagsPartNr) => {
    if (!isDrawerVisible) return;
    setNagsPartNrs([]);
    setNagsPartNrsNotes([]);
    setDataLoading(true);
    setPriceCheckData([]);
    getPriceCheckVendors().then((response) => {
      setDataLoading(false);
      const { data } = response;

      // setting the isLoading property to true
      setPriceCheckData(data.map(x => {
        x.isLoading = x.is_api_vendor;
        return x;
      }));

      // firing request to get price check per vendor
      data.map(x => {
        if (x.is_api_vendor) priceCheckPerVendor(nagsPartNr, x.vendor);
        return x;
      })
      
    }).catch((err) => {
      setDataLoading(false);
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
    })
  }

  useEffect(() => {
    if (nagsPartNrSearch === null) return;
    getAllPriceCheckVendors(nagsPartNrSearch);
  }, [nagsPartNrSearch]);

  useEffect(() => {
    if (!isDrawerVisible) return; 
    setNagsPartNrsNotes((curr) => {
      for (const iterator of nagsPartNrs) {
        if (!curr.map(x => x.nags_part_nr).includes(iterator)) {
          curr.push({
            nags_part_nr: iterator,
            is_loading: true
          })
          fetchNotes(iterator);
        }
      }
      return [ ...curr ];
    })
  }, [nagsPartNrs])

  const nagsNotes = nagsPartNrsNotes.map(x => x.notes);

  return (
    <Drawer
      title="Price Check"
      placement="right"
      size="large"
      visible={isDrawerVisible}
      onClose={() => closeDrawer()}
    >
      <If condition={dataLoading}>
        <Space direction="horizontal" style={{ marginTop: "30px", width: '100%', justifyContent: 'center' }}>
          <Spin tip="Loading... Allow up to 15 seconds for results to be returned" />
        </Space>
      </If>
      <If condition={!dataLoading}>
        <Collapse defaultActiveKey={['InterchangeNumbers']}>
          <Panel header="Interchange" key="InterchangeNumbers">
            {nagsPartNrs.map(x => <p>{x}</p>)}
          </Panel>
        </Collapse>
        <div style={{marginBottom: "15px"}}>
          <NagsPartNrNotesListComponent 
            nagsPartNrNotes={nagsNotes.filter(x => x !== undefined).flat()} 
          />
        </div>
        <h3>Price Lookup Data</h3>
        <Collapse defaultActiveKey={['Atlas Inventory']}>
          {priceCheckData.map((priceCheck) => {
            const returnedApiResults = (priceCheck.vendor_price_check_data || []);
            const countOfResults = returnedApiResults.length;
            
            return (
              <Panel 
                header={
                  <Spin spinning={priceCheck.isLoading}>
                    <span>{priceCheck.vendor} 
                      <Badge count={countOfResults} style={{ backgroundColor: '#52c41a' }}/>
                    </span>
                  </Spin>
                } 
                key={priceCheck.vendor}
              >
                <>
                  <h3 style={{ margin: "15px" }}>{priceCheck.vendor}</h3>
                  <If condition={countOfResults !== 0}>
                    <List
                      size="small"
                      loading={dataLoading}
                      dataSource={priceCheck.vendor_price_check_data}
                      renderItem={result => {
                        const extraVendorData = result.additional_vendor_data;
                        const vendorName = result.source;
                        const isItemInStock = result.quantity > 0;
                        const selectThisPart = (
                          <Button 
                            type="primary" 
                            style={{ marginTop: "15px" }} 
                            onClick={() => {
                              selectVendor(priceCheck, result);
                              closeDrawer();
                            }}
                            disabled={!isItemInStock}
                          > 
                            Select This Part 
                          </Button>
                        );
                        
                        const noteFiltered = nagsPartNrsNotes.filter(x => x.nags_part_nr === result.nags_part_nr);

                        return (
                          <List.Item style={{ width: "100%", display: "block" }}>
                            <p className="display-block">
                              Source: {vendorName}
                              <If condition={result.is_xyg_interchange_number}>
                                <Tooltip title={
                                  <p>Interchange - Search Term: {result.nags_part_nr_used_for_search}</p>
                                }>
                                  &nbsp;<InfoCircleOutlined style={{ color: "red"}}/>
                                </Tooltip>
                              </If>
                            </p>
                            <p className="display-block">Name: {result.part_name}</p>
                            <p className="display-block">NAGS Part #: {result.nags_part_nr}</p>
                            <p className="display-block">Price: {result.price}</p>
                            <p className="display-block">Quantity: {result.quantity}</p>
                            
                            {/* External System ID isn't shown to user anymore - was used for debugging returned results */}
                            
                            {/* We don't display External System ID if the vendor name is MyGrant */}
                            {/* Reason for this: Mygrant external_system_id is a JSON string with all details as mygrant doesn't use product ID for orders */}
                            {/* <If condition={vendorName !== "Mygrant"}>
                              <p className="display-block">External System ID: {result.external_system_id}</p>
                            </If> */}

                            <p>Is XYG HUD: {result.is_xyg_hud_property ? "Yes" : "No"}</p>
                            <If condition={extraVendorData !== null}>
                              <h4 className="display-block" style={{ marginTop: "10px" }}>
                                Additional vendor data: 
                              </h4>
                              <If condition={vendorName === "Atlas Inventory"}>
                                <p>Location Building: {extraVendorData.location.building_name}</p>
                                <p>Location Name: {extraVendorData.location.name}</p>
                                <p>Reconciled: {extraVendorData.item.reconciled}</p>
                                <p>Inventory SKU: {extraVendorData.item.sku}</p>

                                {/* Inventory Item ID isn't shown to user anymore - was used for debugging returned results */}
                                {/* <p>Inventory Item ID: {extraVendorData.item.item_id}</p> */}
                                
                                <p>Notes: {extraVendorData.item.note}</p>
                                <p>Is damaged?: {extraVendorData.item.is_damaged === "1" ? "Yes" : "No"}</p>

                                <p>Quantity: {extraVendorData.item.qty}</p>
                                <p>Brand: {extraVendorData.item.brand || <i>No brand value</i>}</p>
                              </If>
                              <If condition={vendorName === "Mygrant"}>
                                <p>Location: {extraVendorData.ResponseShipFromBranchName}</p>
                              </If>
                            </If>
                            
                            <div style={{ marginTop: "15px"}}>
                              <If condition={noteFiltered.length > 0}>
                                <If condition={!noteFiltered[0].is_loading}>
                                  <div>
                                    <NagsPartNrNotesListComponent 
                                      nagsPartNrNotes={noteFiltered[0].notes} 
                                      />
                                  </div>
                                <Else/>
                                  <Spin >
                                    Installation Notes: 
                                  </Spin>
                                </If>
                              </If>
                            </div>

                            <If condition={noteFiltered.length === 0}>
                              <p> Possible error with Notes </p>
                            </If>
                            <div style={{marginBottom: "15px"}}>
                              <If condition={(result.pilkington_products || []).length !== 0}>
                                <Collapse>
                                  <PilkingtonDataPanelComponent
                                    key={"abcd"}
                                    pilkingtonProducts={result.pilkington_products}
                                  />
                                </Collapse>
                              <Else/>
                                <i style={{ display: "block" }}> No Pilkington data available</i>
                              </If>
                            </div>

                            <If condition={!isItemInStock}>
                              <Tooltip title="Can't order as the quantity is 0">
                                {selectThisPart}
                              </Tooltip>
                            <Else/>
                              {selectThisPart}
                            </If>
                          </List.Item>
                        )
                      }}
                    />
                  <Else />
                    <Spin spinning={priceCheck.isLoading}>
                      <If condition={priceCheck.is_api_vendor}>
                        <i style={{ display: "block", margin: "15px" }}> No stock returned from External Vendor </i>
                      </If>
                      <Button
                        type="primary"
                        style={{ marginTop: "5px", marginLeft: "15px" }} onClick={() => {
                          selectVendor(priceCheck);
                          closeDrawer();
                        }}> Use this Vendor </Button>
                    </Spin>
                  </If>
                </>
              </Panel>
            )
          })}
        </Collapse>
      </If>
    </Drawer>
  )
}

export default PriceCheckDrawerComponent