import { useContext, useEffect, useState, useRef } from "react";
import { Modal, Form, Steps, Input, message, Checkbox, Collapse, Typography, Row, Select, Col, Radio, InputNumber, DatePicker, Image, Button, Space, Switch, Affix, Card, Popover, Divider, Spin, Popconfirm, Alert, Table, Tooltip, List, Skeleton, Badge } from "antd";
import { addIntakeForm, addTag, getAllIntakeForms as fetchIntakeForms, createSingleLiabilityWaiver, deleteFile, editIntakeForm, getAGCRMNagsPartNrResultsIntakeForm, getAllInsuranceCarriers, getAllIntakeFormTags, getAllShopLocations, getAllUnacknowledgedAndUnpromptedJobReviews, getCallRailConversationForPhoneNr, getCarInfoForVinLookup, getSingleCustomerPhoneNumber, publishSquareInvoice, quickQuoteForIntakeForm, sendSignedWaiverToCustomer, sendWaiverToCustomerToSign, getSingleIntakeForm, getAllIntakeFormHistoryEntries } from "../../actions";

import moment from 'moment';
import instance from "../../axios";
import jwt from 'jwt-decode'
import _ from "lodash";

import { DollarFormatter, DollarParser, fetchCustomers, fetchVehicles, formatCustomerDisplayValue, formatCustomerSearchDisplayLabelValue, GetAllCSREmployeesAccountUsersResponseStyle, GetAllTechnicianEmployeesAccountUsersResponseStyle, getCsrsNamesForIntakeForm, GetCurrentLoggedInUserResponseStyle, getListOfServicesForIntakeForm, getTechniciansForIntakeForm, GetTreeArrayValuesCascader, getVehiclesForJob, IsString, IsUserAllowedToEditOrDeleteCallbackNote, MobileJobDistanceFormatter, MobileJobTimeFormatter, nicelyFormatPascalCaseString, PercentageFormatter, PercentageParser, roundNumber, singleIntakeFormMap } from "../../helpers";
import { fields } from "./addEditIntakeFormFields";
import TextArea from "antd/lib/input/TextArea";
import CheckableTag from "antd/lib/tag/CheckableTag";
import GlassReplacementJobEntryTable from "../glassReplacementJobComponent";
import ChipRepairJobEntryTable from "../chipRepairJobComponent";
import BodyAndPaintJobEntryTable from "../bodyAndPaintJobComponent";
import TintOnlyJobEntryTable from "../tintOnlyJobComponent";
import DebounceSelect from "../debounceSelect";
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, ExclamationCircleFilled, InboxOutlined, InfoCircleFilled, MessageFilled, SwapOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import AddMissingVehicleComponent from "../addMissingVehicleComponent";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

import GetMobileJobDistanceModal from "./getMobileJobDistanceModal";
import VehicleComponent from "../vehicleComponent";
import { v4 as uuidv4 } from 'uuid';

import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { backendDisplayDateTimeFormat, displayDateTimeFormat, GLASS_TYPE_CASCADER_VALUES, phoneNumberMaskFormat, phoneNumberMaskFormatNines, QUOTE_REJECTED_REASONS } from "../../global_constants";

import InputMask from 'react-input-mask';
import MessagesListComponent from "../messagesListComponent";
import EditNagsPartNrSearchResultModalComponent from "../editNagsPartNrSearchResultModalComponent";
import VehicleDetailJobEntryTable from "../vehicleDetailJobComponent";
import AddEmailToIntakeFormComponent from "../addEmailToIntakeFormComponent";
import ZeroCostReasonModalComponent from "./zeroCostReasonModalComponent";
import DeliveryMethodSelectionComponent from "../deliveryMethodSelectionComponent";
import AddEditIntakeFormDepositRequestModalComponent from "../addEditIntakeFormDepositRequest";
import InsurancePricingIntakeFormComponent from "../insurancePricingIntakeForm";
import debounce from 'lodash/debounce';
import { checkIfStringTypeFilterIsSet } from "../../utilities/queryString";
import InfiniteScroll from "react-infinite-scroll-component";
import JobNoteExpandComponent from "../jobNoteExpandComponent";
import AccountUsersSystemContext from "../../context/accountUsersSystem";
import AlternativeContactComponent from "../alternativeContactComponent";

var InputMaskCore = require('inputmask-core');

const { Title } = Typography;
const { Panel } = Collapse;
const { Step } = Steps;

const defaultIntakeFormWarrantyPagination = {
  current: 1,
  pageSize: 15,
}

const AddEditIntakeFormComponent = ({
  isModalVisible,
  getAllIntakeForms,
  setIsModalVisible,
  displayMode,
  intakeForm,
  openSpecificChat = () => { },
  displayType = "Modal",
  prefillCustomerData = null,
  prefillWebFormData = null,
  prefillVinsFromTextMessages = null,
}) => {

  const token = instance.defaults.headers.common["Authorization"]?.replace("Bearer ", "");
  let user = null;
  if (token !== undefined) {
    user = jwt(token);
  }

  const { users: accountUsers } = useContext(AccountUsersSystemContext);

  const [form] = Form.useForm();
  const [intakeFormAdding, setIntakeFormAdding] = useState(false);

  const [intakeFormElementsFields, setIntakeFormElementsFields] = useState(fields);

  const [appointedTimeRequiredOverride, setAppointedTimeRequired] = useState(false);
  const [customerCancellationReasonVisible, setCustomerCancellationReasonVisible] = useState(false);
  const [rejectedQuoteReasonVisible, setRejectedQuoteReasonVisible] = useState(false);
  const [isOtherRejectedQuoteReasonSelected, setOtherRejectedQuoteReasonSelected] = useState(false);
  const [isInsurancePaymentMethod, setIsInsurancePaymentMethod] = useState(false);
  const [isWarrantyPaymentMethod, setIsWarrantyPaymentMethod] = useState(false);
  const [isMobileJobLocation, setIsMobileJobLocation] = useState(false);
  const [isQuoteStatus, setIsQuoteStatus] = useState(false);
  const [showCustomerInfoSearchBar, setShowCustomerInfoSearchBar] = useState(false);
  const [isEmergencyService, setIsEmergencyService] = useState(false);
  const [isCustomerACompany, setIsCustomerACompany] = useState(false);
  const [isQuoteOnlyLocationSelected, setIsQuoteOnlyLocationSelected] = useState(false);

  const [selectedJobTypeTags, setSelectedJobTypeTags] = useState([]);
  const [glassReplacementJobEntries, setGlassReplacementJobEntries] = useState([]);
  const [chipRepairJobEntries, setChipRepairJobEntries] = useState([]);
  const [bodyAndPaintJobEntries, setBodyAndPaintJobEntries] = useState([]);
  const [vehicleDetailJobEntries, setVehicleDetailJobEntries] = useState([]);
  const [tintOnlyJobEntries, setTintOnlyJobEntries] = useState([]);
  const [mobileFee, setMobileFee] = useState(0);
  const [deductibleAmount, setDeductibleAmount] = useState(0);
  const [emergencyServiceFee, setEmergencyServiceFee] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [totalPriceSum, setTotalPriceSum] = useState(0);
  const [originalTotalPrice, setOriginalTotalPrice] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState('');
  const [discountAmountValue, setDiscountAmountValue] = useState('');
  const [disableCustomDiscountButton, setDisableCustomDiscountButton] = useState(true);

  const [technicianEmployees, setTechnicianEmployees] = useState([])

  const [selectedVehicle, setSelectedVehicle] = useState(undefined);

  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [isOverrideCustomerInfo, setIsOverrideCustomerInfo] = useState(false);

  const [shopLocations, setShopLocations] = useState([]);
  const [tags, setTags] = useState([]);
  const [newTagAddition, setNewTagAddition] = useState("");
  const [newTagAdding, setNewTagAdding] = useState(false);

  const [selectedInsuranceImage, setSelectedInsuranceImage] = useState("");
  const [preloadedInsuranceImages, setPreloadedInsuranceImages] = useState([]);
  const [insuranceImages, setInsuranceImages] = useState([]);
  const [selectedGenericImage, setSelectedGenericImage] = useState("");
  const [preloadedGenericImages, setPreloadedGenericImages] = useState([]);
  const [genericImages, setGenericImages] = useState([]);
  const [insuranceDocuments, setInsuranceDocuments] = useState([]);
  const [statusHistory, setStatusHistory] = useState({});
  const [activeStatusHistoryKey, setActiveStatusHistoryKey] = useState(null);

  const [isAddMissingVehicleModalVisible, setIsAddMissingVehicleModalVisible] = useState(false);

  const [isGetMobileJobDistanceModalVisible, setIsGetMobileJobDistanceModalVisible] = useState(false);
  const [mobileAddressFormatted, setMobileAddressFormatted] = useState(null)

  const [publishInvoiceButtonLoading, setPublishInvoiceButtonLoading] = useState(false);
  const [publishEmailValue, setPublishEmailValue] = useState(null);

  const [isAddEmailToIntakeFormModalVisible, setIsAddEmailToIntakeFormModalVisible] = useState(false);

  const [isChatListVisible, setIsChatListVisible] = useState(false);
  const [intakeFormPhoneNumber, setIntakeFormPhoneNumber] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const [phoneNrOnlyChat, setPhoneNrOnlyChat] = useState(null);
  const [intakeFormConversationRecord, setIntakeFormConversationRecord] = useState(null);

  const [editingKeyGlassReplacementJobEntry, setEditingKeyGlassReplacementJobEntry] = useState('');
  const [editingKeyChipRepairJobEntry, setEditingKeyChipRepairJobEntry] = useState('');
  const [editingKeyBodyAndPaintJobEntry, setEditingKeyBodyAndPaintJobEntry] = useState('');
  const [editingKeyTintOnlyJobEntry, setEditingKeyTintOnlyJobEntry] = useState('');
  const [editingKeyVehicleDetailJobEntry, setEditingKeyVehicleDetailJobEntry] = useState('');

  const [unacknowledgedJobReviews, setUnacknowledgedJobReviews] = useState([]);

  const [expertUnpromptedPartReviews, setExpertUnpromptedPartReviews] = useState([]);

  const [isGlassPartMarkedForSale, setIsGlassPartMarkedForSale] = useState(false);

  const [vehicles, setVehicles] = useState([]);
  const [activeKeyVehicleCollapse, setActiveKeyVehicleCollapse] = useState(null);

  const [alternativeContacts, setAlternativeContacts] = useState([]);

  const [foundCustomerByPhoneNumber, setFoundCustomerByPhoneNumber] = useState(null);

  const [refreshVehicles, doRefreshVehicles] = useState(0);

  const [nagsPartNrAGCRMInstallInfos, setNagsPartNrAGCRMInstallInfos] = useState({});

  const [isEditNagsPartNrSearchResultModalVisible, setIsEditNagsPartNrSearchResultModalVisible] = useState(false);
  const [selectedNagsPartNr, setSelectedNagsPartNr] = useState(null);

  const [quickQuoteButtonLoading, setQuickQuoteButtonLoading] = useState(false);

  const [isZeroCostReasonModalVisible, setIsZeroCostReasonModalVisible] = useState(null);

  const [liabilityWaivers, setLiabilityWaivers] = useState([]);
  const [manuallyCreateWaiverType, setManuallyCreateWaiverType] = useState(null);
  const [isManuallyCreateWaiverTypeLoading, setIsManuallyCreateWaiverTypeLoading] = useState(false);

  const [isDeliveryMethodSignedDocModalVisible, setIsDeliveryMethodSignedDocModalVisibility] = useState(false);
  const [isDeliveryMethodDocToSignModalVisible, setIsDeliveryMethodDocToSignModalVisibility] = useState(false);
  const [selectedLiabilityWaiverId, setSelectedLiabilityWaiverId] = useState(false);

  const [isAddDepositModalVisible, setIsAddDepositModalVisible] = useState(false);
  const [isEditDepositModalVisible, setIsEditDepositModalVisible] = useState(false);
  const [selectedDepositRequest, setSelectedDepositRequest] = useState(null);
  const [depositRequests, setDepositRequests] = useState([]);
  const depositRequestsRef = useRef(null);
  const [showDeletedDepositRequests, setShowDeletedDepositRequests] = useState(false);

  const [insuranceCarriers, setInsuranceCarriers] = useState([]);
  const [selectedInsuranceCarrierId, setSelectedInsuranceCarrierId] = useState(null);
  const [isInsurancePricingComponentVisible, setIsInsurancePricingComponentVisible] = useState(true);

  const [intakeFormsWarrantyLoading, setIntakeFormsWarrantyLoading] = useState(false);
  const [intakeFormsWarranty, setIntakeFormsWarranty] = useState([]);
  const [intakeFormsWarrantyPagination, setIntakeFormsWarrantyPagination] = useState(defaultIntakeFormWarrantyPagination);
  const [selectedWarrantySourceIntakeForm, setSelectedWarrantySourceIntakeForm] = useState(null);

  const customersDataRef = useRef(null);

  // it wasn't specified as requirement - but if the mode is addition, we should display the customer info (state is uncollapsed)
  // if it is addition, we can hide it by default and users can uncollapse themselves
  const [customerDetailsCollapseActiveKey, setCustomerDetailsCollapseActiveKey] = useState(
    displayMode === "Add" ? ["customer_details"] : null
  );

  const scrollToDepositRequestsComponent = () => {
    depositRequestsRef.current.scrollIntoView();
  }

  const handleCancel = () => {
    if (displayType === "Modal") {
      if (depositRequests.filter(dr => dr.userAdded).length > 0) {
        message.warning("You have unsaved deposit requests. Please save them before closing the modal.");
        scrollToDepositRequestsComponent();
        return;
      }
      if (isGlassPartMarkedForSale) {
        const isClose = window.confirm("If glass Parts are marked for Sale, the Job Form must be saved in order to actually perform the sale. Are you sure you want to close Job Form and not perform the sale?")
        if (!isClose) return;
      }
      setIsModalVisible(false);
    }
  };

  const getIntakeFormHistoryEntries = (intakeFormId) => {
    getAllIntakeFormHistoryEntries(intakeFormId).then((response) => {
      const { data } = response;
      setStatusHistory(data);
    }).catch((err) => {
      message.error(err?.response?.data?.message || "Error while fetching intake form history entries");
    });
  }

  const fetchIntakeFormsForWarrantySelection = (searchTerm, page = 1, intakeFormId = null) => {
    setIntakeFormsWarrantyLoading(true);

    const params = new URLSearchParams({
      ...(checkIfStringTypeFilterIsSet(searchTerm)) && { search_term: searchTerm },
    }).toString();

    fetchIntakeForms(page, intakeFormsWarrantyPagination.pageSize, params).request.then(res => {
      setIntakeFormsWarrantyLoading(false);
      const { data } = res;
      const { model, pagination } = data;

      if (page === 1) {
        setIntakeFormsWarranty(model.map(singleIntakeFormMap))
      } else {
        setIntakeFormsWarranty(prevIntakeFormsWarranty => [...prevIntakeFormsWarranty, ...model.map(singleIntakeFormMap)])
      }

      setIntakeFormsWarrantyPagination(pagination);
    }).catch(err => {
      setIntakeFormsWarrantyLoading(false);
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    })
  }

  const handleJobTypeChange = (tag, checked) => {
    const nextSelectedTags = checked ? [...selectedJobTypeTags, tag] : selectedJobTypeTags.filter(t => t !== tag);
    console.log('Selected Job types: ', nextSelectedTags);
    setSelectedJobTypeTags(nextSelectedTags);
  }

  const addNewlyAddedFrontendId = (item) => {
    if (item.userAdded) {
      item.newly_added_frontend_id = item.id;
    }
    return item;
  }

  const removeIdsIfUserAdded = (item) => {
    if (item.userAdded) {
      delete item.id;
    }
    return item;
  };

  const fileMapBehavior = (item) => {
    if (item.id !== undefined) {
      return item.id;
    }
    if (item.response.id === undefined) {
      const msg = "File ID is not present - files are probably still uploading. Please retry after a quick wait"
      message.error(msg);
      throw new Error(msg);
    }
    return item.response?.id;
  };

  const fileListInitialLoadMapBehavior = (file) => {
    return {
      ...file,
      status: "done",
      name: file.original_filename,
      uid: file.id,
      id: file.id,
    }
  }

  const addNewIntakeFormTag = () => {
    setNewTagAddition("");
    setNewTagAdding(true);
    const payload = { name: newTagAddition, is_reusable: false, type: "IntakeForm" };
    addTag(payload)
      .then((response) => {
        const { data } = response;
        let tagsCopy = tags;
        tagsCopy.push({ ...data, is_just_added_by_user: true });
        setTags(tagsCopy);
        setNewTagAdding(false);
        console.log(data);
      })
      .catch((error) => {
        message.warning("Error while adding a new tag, please reload the page and try again.")
      })
  }

  const publishInvoiceDraft = (emailOverride = undefined) => {

    // prompt the user for email entry.
    // If the email is on record, use that email address as prefilled value.

    // if emailOverride is used (value is different than undefined), we don't check the email, we just send the email
    if (emailOverride === undefined) {
      const email = form.getFieldValue("email");
      setIsAddEmailToIntakeFormModalVisible(true);
      setPublishEmailValue(email);
      return;
    }

    setPublishInvoiceButtonLoading(true);
    publishSquareInvoice(intakeForm.id, emailOverride).then((response) => {
      setPublishInvoiceButtonLoading(false);
      const { data } = response;
      if (data.status === "OK") {
        message.success(data.status_display);
      } else {
        message.error(data.status_display);
      }
    }).catch((err) => {
      setPublishInvoiceButtonLoading(false);
      message.error(err?.response?.data?.detail);
    })
  }

  const refreshAllIntakeForms = () => {
    if (getAllIntakeForms !== undefined && getAllIntakeForms !== null) {
      getAllIntakeForms();
    }
  }

  const areJobEntriesBeingEdited = () => {
    const listOfValuesToCheck = [
      editingKeyBodyAndPaintJobEntry, editingKeyGlassReplacementJobEntry,
      editingKeyChipRepairJobEntry, editingKeyTintOnlyJobEntry,
      editingKeyVehicleDetailJobEntry
    ];
    for (const iterator of listOfValuesToCheck) {
      if (iterator !== "") {
        return true;
      }
    }
    return false;
  }
  const setJobEntriesNotBeingEdited = () => {
    const listOfSetStateFuncs = [
      setEditingKeyBodyAndPaintJobEntry, setEditingKeyGlassReplacementJobEntry,
      setEditingKeyChipRepairJobEntry, setEditingKeyTintOnlyJobEntry,
      setEditingKeyVehicleDetailJobEntry
    ];
    for (const func of listOfSetStateFuncs) {
      func("")
    }
  }

  const onSubmit = (values, zeroCostReason = null) => {

    // if the customer is missing an email, and deposit request is issued with email as delivery method, issue a warning
    let doesNewEmailDepositRequestExist = false;
    for (const depositRequest of depositRequests) {
      if (depositRequest.delivery_method === "Email" && depositRequest.userAdded) {
        doesNewEmailDepositRequestExist = true;
      }
    }

    if (doesNewEmailDepositRequestExist) {
      const currentLocation = getCurrentSelectedShopLocation();
      if (!verifyShopLocationOkForNewSquareInvoiceCreation(currentLocation)) {
        return;
      }
    }
    if (doesNewEmailDepositRequestExist && (values.email === "" || values.email === null || values.email === undefined)) {
      message.error("You have a new deposit request with email as delivery method. Please fill out the Customer E-mail field before saving the form.");
      customersDataRef.current.scrollIntoView();
      return;
    }

    if (values.is_vip === undefined) {
      values.is_vip = false;
    }

    if (values.is_invoice_sent_to_insurance_or_vendor === undefined || values.is_invoice_sent_to_insurance_or_vendor === null) {
      values.is_invoice_sent_to_insurance_or_vendor = false;
    }

    // this is used to check that there are no unsaved job entries
    if (areJobEntriesBeingEdited()) {
      message.warning("Please finish (save or cancel) the editing for all job type entries!", 5);
      return;
    }

    if (vehicles.length === 0) {
      message.warning("Please add at least one vehicle!", 5);
      return;
    }

    // check if the year, model and make are filled out
    const fieldsToCheck = ["year", "make", "model"];
    for (const vehicle of vehicles) {
      for (const field of fieldsToCheck) {
        const fieldValue = vehicle[field];
        if (fieldValue === "" || fieldValue === null || fieldValue === undefined) {
          message.warning("Please properly fill out all the vehicle infos! Required fields for all vehicles are: Year, Make, Model", 5);
          return;
        }
      }
    }

    let totalCost = 0;
    for (const jobs of [
      glassReplacementJobEntries,
      chipRepairJobEntries,
      bodyAndPaintJobEntries,
      tintOnlyJobEntries,
      vehicleDetailJobEntries
    ]) {
      for (const job of jobs) {
        if (!isNaN(parseFloat(job.cost))) {
          totalCost = totalCost + parseFloat(job.cost);
        }
      }
    }

    if (totalCost === 0 && zeroCostReason === null && values.zero_cost_reason === null) {
      setIsZeroCostReasonModalVisible(true);
      return;
    }

    if (zeroCostReason !== null) {
      values.zero_cost_reason = zeroCostReason;
    }

    values.vehicles = vehicles.map(addNewlyAddedFrontendId).map(removeIdsIfUserAdded);

    values.alternative_contacts = alternativeContacts.map(addNewlyAddedFrontendId).map(removeIdsIfUserAdded);

    console.log(values.customer_info_json);
    delete values.customer_info_json;
    if (values.appointment_time !== null && values.appointment_time !== undefined) {
      const appointmentTimeBackendFormatted = moment(values.appointment_time).utc().format(backendDisplayDateTimeFormat);
      values.appointment_time = appointmentTimeBackendFormatted;
    }

    const tintPricingModificationMapBehavior = (item) => {
      item.tint_pricing_fk_name = null;
      if (item.tint_pricing !== null && item.tint_pricing !== undefined) {
        try {
          item.tint_pricing_fk_name = item.tint_pricing.value.split(";;;")[1];
        } catch (e) {
          item.tint_pricing_fk_name = item.tint_pricing.value;
        }
      }
      return item;
    };
    const inventorySourceModificationMapBehavior = (item) => {
      if (item.inventory_source !== "Other") {
        item.inventory_source_other_value = null;
      }
      return item;
    }
    const windshieldCalibrationModificationMapBehavior = (item) => {
      if (item.glass_type !== "Windshield") {
        delete item.calibration_pricing;
        delete item.adas_declined_status;
      }
      // maybe needed, not sure
      //if (item.calibration_pricing === null) item.calibration_pricing = 0;
      return item;
    };
    const glassTypeArrayModificationMapBehavior = (item) => {
      try {
        // hotfix for sometimes occuring issue where the selection turns into a string rather than array for cascader
        if (IsString(item.glass_type)) {
          //message.info("Please control that the Glass Type value is correct for the job you just saved - portal tried to fix an intermittent error.", 10);
          return item;
        }
        item.glass_type = item.glass_type.at(-1);
      } catch (error) {
        if (item.glass_type !== undefined) {
          alert("Please contact the developer with the following message: There was an error with Glass Type entry: " + error);
        }
      }
      return item;
    };
    values.glass_replacement_jobs = glassReplacementJobEntries
      .map(glassTypeArrayModificationMapBehavior)
      .map(removeIdsIfUserAdded)
      .map(tintPricingModificationMapBehavior)
      .map(windshieldCalibrationModificationMapBehavior)
      .map(inventorySourceModificationMapBehavior);
    values.chip_repair_jobs = chipRepairJobEntries.map(glassTypeArrayModificationMapBehavior).map(removeIdsIfUserAdded).map(windshieldCalibrationModificationMapBehavior);
    values.body_and_paint_jobs = bodyAndPaintJobEntries.map(removeIdsIfUserAdded);
    values.tint_only_jobs = tintOnlyJobEntries.map(glassTypeArrayModificationMapBehavior).map(removeIdsIfUserAdded).map(tintPricingModificationMapBehavior).map(windshieldCalibrationModificationMapBehavior);
    values.vehicle_detail_jobs = vehicleDetailJobEntries.map(removeIdsIfUserAdded);
    values.deposit_requests = depositRequests.map(removeIdsIfUserAdded);

    try {
      values.insurance_document_files = insuranceDocuments.map(fileMapBehavior);
      values.insurance_photo_files = insuranceImages.map(fileMapBehavior);
      values.generic_photo_files = genericImages.map(fileMapBehavior);
    } catch (error) {
      message.error(JSON.stringify(error));
      return;
    }
    let selectedCustomerDataParsed;
    try {
      selectedCustomerDataParsed = JSON.parse(selectedCustomer.value);
    } catch (error) {
      console.log("Error while parsing selectedCustomer - it is probably a new customer entry")
    }
    const customerFields = ["company_name", "is_company", "first_name", "telephone", "last_name", "address", "city", "state", "zip_code", "email"];
    // if the overwrite customer data is set to false remove all customer fields from request object and add customer id to request
    values.customer_id = selectedCustomerDataParsed?.id;
    if (values.overwrite_customer_data === false) {
      for (const customerField of customerFields) {
        delete values[customerField]
      }
    }
    // if the overwrite customer data is set to true, add all customer fields to request to customer_data_overwrite object
    // delete all customer fields properties from root object
    if (values.overwrite_customer_data === true) {
      values.customer_data_overwrite = {};
      for (const customerField of customerFields) {
        values.customer_data_overwrite[customerField] = values[customerField]
        delete values[customerField]
      }
    }
    // if there is no specified customer id, add all customer fields to request to customer_data_overwrite object
    // delete all customer fields properties from root object
    if (values.customer_id === undefined) {
      values.customer_data_overwrite = {};
      for (const customerField of customerFields) {
        values.customer_data_overwrite[customerField] = values[customerField]
        delete values[customerField]
      }
    }

    // if the sale_stage is "RejectedQuote":
    // if the other quote rejected reason option is selected, we use the other quote 
    // rejected reason textarea field to fetch the reason. If other is not selected, we use
    // the selected radio value
    if (values.sale_stage === "RejectedQuote") {
      if (values.quote_rejected_reason === "Other") {
        values.quote_rejected_reason = values.quote_rejected_reason_textarea;
        delete values.quote_rejected_reason_textarea;
      }
    }

    values.discount_amount = (form.getFieldValue("discount_amount") || 0)
    if(values.discount_amount > originalTotalPrice){
      message.error("Discount price cannot be greater than the actual price.");
      return;
    }
    console.log(values);

    if (displayMode === "Add") {
      setIntakeFormAdding(true);
      addIntakeForm(values)
        .then((response) => {
          setIntakeFormAdding(false);
          if (displayType === "Modal") {
            setIsModalVisible(false);
            refreshAllIntakeForms();
          }
          resetAllFields(true);
          message.success(`Successfully added the job`);
        })
        .catch((err) => {
          setIntakeFormAdding(false);
          message.error(err?.response?.data?.detail);
        });
    }
    else {
      delete values.job_reference;
      setIntakeFormAdding(true);
      editIntakeForm(values, intakeForm.id)
        .then((response) => {
          setIntakeFormAdding(false);
          if (displayType === "Modal") {
            setIsModalVisible(false);
            refreshAllIntakeForms();
          }
          message.success(`Successfully edited the job`);
        })
        .catch((err) => {
          setIntakeFormAdding(false);
          message.error(err?.response?.data?.detail);
        });
    }
  }

  const getShopLocations = () => {
    getAllShopLocations().then((response) => {
      const { data } = response;
      setShopLocations(data.map(
        (item) => {
          item.key = item.id
          return item;
        }
      ));
    }).catch((err) => {
      message.error(err?.response?.data?.detail);
    });
  }

  function disabledDateTime() {
    return {
      disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 23],
    };
  }

  const intakeFormIdFileUploadParam = intakeForm?.id !== undefined ? `?intake_form_id=${intakeForm?.id}` : "";

  const insuranceImageUploadsProps = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API_HOST}/api/v1/file/upload-insurance-photo${intakeFormIdFileUploadParam}`,
    headers: { "Authorization": instance.defaults.headers.common["Authorization"] },
    onChange(info) {
      let fileList = [...preloadedInsuranceImages, ...info.fileList];

      // Read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
      setInsuranceImages(fileList);

      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        if (insuranceImages.length === 0) {
          setSelectedInsuranceImage(`${info.file.response.location}`);
        }
        message.success(`${info.file.name} photo uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} photo upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    showUploadList: false,
  };

  const genericImageUploadsProps = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API_HOST}/api/v1/file/upload-generic-photo${intakeFormIdFileUploadParam}`,
    headers: { "Authorization": instance.defaults.headers.common["Authorization"] },
    onChange(info) {
      let fileList = [...preloadedGenericImages, ...info.fileList];

      // Read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
      setGenericImages(fileList);

      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        if (genericImages.length === 0) {
          setSelectedGenericImage(`${info.file.response.location}`);
        }
        message.success(`${info.file.name} photo uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} photo upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    showUploadList: false,
  };

  const insuranceFilesUploadProps = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API_HOST}/api/v1/file/upload-insurance-file${intakeFormIdFileUploadParam}`,
    headers: { "Authorization": instance.defaults.headers.common["Authorization"] },
    onChange(info) {
      let fileList = [...info.fileList];

      // Read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
      setInsuranceDocuments(fileList);
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    onRemove(e) {
      deleteFileHandler(e)
    }
  };

  const fetchConversationForPhoneNumber = (phoneNr) => {
    getCallRailConversationForPhoneNr(phoneNr).request.then((response) => {
      const { data } = response;
      setConversationId(data.id);
      setIntakeFormConversationRecord(data);
    }).catch((err) => {
      if (err.response.status === 404) {
        setPhoneNrOnlyChat(phoneNr);
        return;
      }
      message.error("Error while fetching conversation for phone number, please reenter phone number and try again");
    });
  }

  const setDefaultVisibilityToggles = (object) => {
    setIsInsurancePaymentMethod(object?.work_type === "Insurance");
    setIsWarrantyPaymentMethod(object?.work_type === "Warranty");
    setAppointedTimeRequired(object?.sale_stage === "Scheduled");
    setCustomerCancellationReasonVisible(object?.sale_stage === "CustomerCancelled");
    setRejectedQuoteReasonVisible(object?.sale_stage === "RejectedQuote");
    setIsQuoteStatus(object?.sale_stage === "Quote" || object?.sale_stage === "QuoteFollowUp");
    if (object?.shop_location !== undefined && object?.shop_location !== null) {
      setIsMobileJobLocation(object.shop_location?.name.toString().toLowerCase().includes("mobile"));
      setIsQuoteOnlyLocationSelected(object.shop_location?.name.toString().toLowerCase().includes("quote only"));
    }
    setMobileFee(object?.mobile_fee || 0);
    setDeductibleAmount(object?.deductible || 0);
    setEmergencyServiceFee(object?.emergency_service_fee || 0);
    setDepositAmount(object?.deposit_amount || 0);
    if (object?.telephone !== undefined && object?.telephone !== null) {
      setIntakeFormPhoneNumber(object?.telephone);
    }
    setIsCustomerACompany(object?.is_company);

    if (object?.work_type === "Insurance") {
      const formInsuranceCarrierValue = form.getFieldValue("insurance_carrier_id");
      setSelectedInsuranceCarrierId(formInsuranceCarrierValue);
    } else {
      setSelectedInsuranceCarrierId(null);
    }
  }

  const setMobileDistanceTime = (distance, time) => {
    form.setFieldsValue({
      mobile_distance: distance,
      mobile_time: time,
    })
  }

  const getInsuranceCarriers = () => {
    getAllInsuranceCarriers().then((response) => {
      const { data } = response;
      setInsuranceCarriers(data);

      setIntakeFormElementsFields(intakeFormElementsFields.map((field) => {
        if (field.name === "insurance_carrier_id") {
          field.options = data.map((option) => {
            return {
              value: option.id,
              label: option.name
            }
          });
        }
        return field;
      }))
    }).catch((err) => {
      message.error("Error while loading insurance carriers " + err?.response?.data?.detail);
    })
  }

  const getTags = () => {
    getAllIntakeFormTags().then((response) => {
      const { data } = response;
      console.log(data);
      setTags(data);
    }).catch((err) => {
      message.error("Error while loading intake form tags, please retry later " + err?.response?.data?.detail);
    })
  }

  const getTechnicianEmployees = () => {
    GetAllTechnicianEmployeesAccountUsersResponseStyle(accountUsers).then((response) => {
      const { data } = response;

      setTechnicianEmployees(data);

      setIntakeFormElementsFields(intakeFormElementsFields.map((field) => {
        if (field.name === "technician_user_id") {
          field.options = data.map((option) => {
            return {
              value: option.id,
              label: option.first_name + " " + option.last_name
            }
          });
        }
        return field;
      }))
    }).catch((err) => {
      message.error("Error while loading employees " + err?.response?.data?.detail);
    })
  }

  const getCsrEmployees = () => {
    GetAllCSREmployeesAccountUsersResponseStyle(accountUsers).then((response) => {
      const { data } = response;

      const isCurrentLoggedInUserPresent = data.filter(userRes => userRes.email === user.sub).length > 0;

      // if the current logged in user is not present in the CSR employees response, then request current user
      // info and add it to list of all CSR employees
      if (!isCurrentLoggedInUserPresent) {
        GetCurrentLoggedInUserResponseStyle(accountUsers).then((response) => {
          const userInfo = response.data;
          console.log(data);
          data.push(userInfo);
          setIntakeFormElementsFields(intakeFormElementsFields.map((field) => {
            if (field.name === "csr_user_id" || field.name === "csr_2_user_id") {
              field.options = data.map((option) => {
                return {
                  value: option.id,
                  label: option.first_name + " " + option.last_name
                }
              });
            }
            return field;
          }))
        }).catch((err) => {
          message.error("Error while loading info for user " + err?.response?.data?.detail);
        })
        return;
      }

      setIntakeFormElementsFields(intakeFormElementsFields.map((field) => {
        if (field.name === "csr_user_id" || field.name === "csr_2_user_id") {
          field.options = data.map((option) => {
            return {
              value: option.id,
              label: option.first_name + " " + option.last_name
            }
          });
        }
        return field;
      }))
    }).catch((err) => {
      message.error("Error while loading employees " + err?.response?.data?.detail);
    })
  }

  const emergencyServiceOnChange = (e) => {
    if (!e.target.checked) {
      form.setFieldsValue({ emergency_service_fee: 0 })
      setEmergencyServiceFee(0);
    }
    setIsEmergencyService(e.target.checked);
  }

  const loadJobReviewData = () => {
    getAllUnacknowledgedAndUnpromptedJobReviews(intakeForm.id).then((response) => {
      const { data } = response;
      setUnacknowledgedJobReviews(data.filter(x => x.request_type === "InternalUsers"));
      setExpertUnpromptedPartReviews(data.filter(x => x.request_type === "InternalUserUnpromptedProvided"));
    }).catch((error) => {
      message.warning(error?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    });
  }

  const loadAllAGCRMNagsPartInstallationInfos = () => {
    getAGCRMNagsPartNrResultsIntakeForm(intakeForm.id).then((response) => {
      const { data } = response;
      let currentNagsPartNrAGCRMInstallInfos = {};
      for (const iterator of data) {
        currentNagsPartNrAGCRMInstallInfos[iterator.part_number] = iterator;
      }
      setNagsPartNrAGCRMInstallInfos(currentNagsPartNrAGCRMInstallInfos);
    }).catch((err) => {
      message.warning(err?.response?.data?.detail || "Unknown error while fetching AG CRM Nags Part Install info - please contact developer with information about how this occured.");
    })
  }

  const loadWarrantySourceIntakeForm = (intakeFormId) => {
    if (intakeFormId === null || intakeFormId === undefined) {
      message.warning("Intake Form ID of Warranty source is null or undefined - might need developer inspection!");
      return;
    }
    getSingleIntakeForm(intakeFormId).then((response) => {
      const { data } = response;
      setSelectedWarrantySourceIntakeForm(data);
    }).catch((err) => {
      message.warning(err?.response?.data?.detail || "Unknown error while fetching warranty source intake form - please contact developer with information about how this occured.");
    })
  }

  const glassTypeArrayValueCascaderMapBehavior = (item) => {
    const arrayValue = GetTreeArrayValuesCascader(GLASS_TYPE_CASCADER_VALUES, item.glass_type);
    item.glass_type = arrayValue;
    //console.log("arrayValue for" + item.glass_type, arrayValue);
    return item;
  }
  const cancelOrderEnabledMapBehavior = (item) => {
    const externalPartIdExists = item.external_part_order_id !== null && item.external_part_order_id !== undefined
    item.cancel_order_enabled = externalPartIdExists;
    return item;
  }

  const resetAllFields = (isAdditionModalType = false) => {
    setIsGlassPartMarkedForSale(false);

    if (isAdditionModalType) {
      intakeForm = {};
    }

    form.resetFields();
    setGlassReplacementJobEntries((intakeForm?.glass_replacement_jobs || []).map(glassTypeArrayValueCascaderMapBehavior).map(cancelOrderEnabledMapBehavior));
    setChipRepairJobEntries((intakeForm?.chip_repair_jobs || []).map(glassTypeArrayValueCascaderMapBehavior));
    setBodyAndPaintJobEntries(intakeForm?.body_and_paint_jobs || []);
    setVehicleDetailJobEntries(intakeForm?.vehicle_detail_jobs || []);
    setTintOnlyJobEntries((intakeForm?.tint_only_jobs || []).map(glassTypeArrayValueCascaderMapBehavior));
    setVehicles(intakeForm?.intake_form_vehicles || []);
    setDepositRequests(intakeForm?.intake_form_deposits || []);
    setAlternativeContacts(intakeForm?.intake_form_alternative_contacts || []);

    let selectedJobTags = [];
    if (intakeForm?.glass_replacement_jobs?.length || [] > 0)
      selectedJobTags.push("Glass");
    if (intakeForm?.chip_repair_jobs?.length || [] > 0)
      selectedJobTags.push("Chip Repair");
    if (intakeForm?.body_and_paint_jobs?.length || [] > 0)
      selectedJobTags.push("Body & Paint");
    if (intakeForm?.tint_only_jobs?.length || [] > 0)
      selectedJobTags.push("Tint Only");
    if (intakeForm?.vehicle_detail_jobs?.length || [] > 0)
      selectedJobTags.push("Vehicle Detail");

    setSelectedJobTypeTags(selectedJobTags);
    let insuranceImages = intakeForm?.insurance_photos || [];
    let insuranceDocuments = intakeForm?.insurance_documents || [];
    let genericImages = intakeForm?.generic_photos || [];

    setInsuranceImages(insuranceImages.map(fileListInitialLoadMapBehavior));
    setPreloadedInsuranceImages(insuranceImages.map(fileListInitialLoadMapBehavior));

    setGenericImages(genericImages.map(fileListInitialLoadMapBehavior));
    setPreloadedGenericImages(genericImages.map(fileListInitialLoadMapBehavior));

    setInsuranceDocuments(insuranceDocuments.map(fileListInitialLoadMapBehavior));

    setSelectedInsuranceImage("");
    setSelectedGenericImage("");

    setDefaultVisibilityToggles(intakeForm);

    setLiabilityWaivers(intakeForm?.all_liability_waivers || []);

    if (intakeForm?.customer_info_json !== undefined) {
      setSelectedCustomer({ ...intakeForm.customer_info_json });
    }

    setIsEmergencyService((intakeForm?.emergency_service_fee || 0) > 0)

    setIntakeFormPhoneNumber(intakeForm?.customer?.telephone || 0);
  }

  const getResult = (addressComponents, propertyName) => {
    let property = addressComponents.filter(component => component.types.filter(type => type === propertyName).length !== 0);
    if (property.length !== 0) return property[0];
    return undefined;
  }

  const getCurrentSelectedShopLocation = () => {
    const currentShopLocationId = form.getFieldValue("shop_location_id");
    const currentLocation = shopLocations.find(x => x.id === currentShopLocationId);
    return currentLocation;
  }

  const verifyShopLocationOkForNewSquareInvoiceCreation = (currentLocation) => {
    if (currentLocation === undefined || currentLocation === null) {
      message.error("Please select a shop location");
      return false;
    }

    if (
      currentLocation.square_location_id === ""
      ||
      currentLocation.square_location_id === undefined
      ||
      currentLocation.square_location_id === null
    ) {
      message.error("Please select a Shop Location with a Square Location ID populated - this is required to issue a Square Invoice. Most likely you have 'Quote Only' selected as Shop Location.", 8);
      return false;
    }

    return true;
  }

  const fillOutCustomerInfoFromGeocodeResult = (result) => {
    const addressComponents = result.address_components;

    let streetNumber = getResult(addressComponents, "street_number")?.long_name || "";
    let street = getResult(addressComponents, "route")?.long_name || "";
    let city = getResult(addressComponents, "locality")?.long_name || "";
    let postalCode = getResult(addressComponents, "postal_code")?.long_name || "";
    let state = getResult(addressComponents, "administrative_area_level_1")?.short_name || "";

    let addressElements = [streetNumber, street].filter(el => el !== "");
    let address = addressElements.join(" ");
    form.setFieldsValue({ address: address, state: state, zip_code: postalCode, city: city });
  }

  const deleteImageHandler = (photo, type) => {
    // type should be either "GenericImage" or "InsuranceImage"
    if (type === "GenericImage") setGenericImages(genericImages.filter(image => image.id !== photo.id))
    if (type === "InsuranceImage") setInsuranceImages(insuranceImages.filter(image => image.id !== photo.id))
    deleteFile(photo.id).then((response) => {
      message.info("Image has been successfully deleted")
    }).catch((err) => {
      message.error("Error while deleting the image, please retry later")
    })
  }

  const deleteFileHandler = (file) => {
    setInsuranceDocuments(insuranceDocuments.filter(fileObj => fileObj.id !== file))
    deleteFile(file.id).then((response) => {
      message.info("File has been successfully deleted")
    }).catch((err) => {
      message.error("Error while deleting the file, please retry later")
    })
  }

  const vinLookupHandler = () => {
    const vinValue = form.getFieldValue("vin");
    if (vinValue === undefined || vinValue === null) {
      message.warning("Please enter a valid VIN!");
      return;
    }
    if (vinValue.length !== 17) {
      message.warning("Please enter a valid VIN!");
      return;
    }
    getCarInfoForVinLookup(vinValue).then((response) => {
      const { data } = response;
      form.setFieldsValue({
        year: data.ModelYear,
        make: data.Make,
        model: data.Model,
        body_class: data.BodyClass,
        trim: data.Trim,
        trim_2: data.Trim2
      })
    }).catch((err) => {
      message.warning(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
    });
  }

  const liabilityWaiverListItems = (liabilityWaiver, intakeForm) => {
    return (
      <Space>
        {nicelyFormatPascalCaseString(liabilityWaiver.type)}
        <If condition={liabilityWaiver.status === "Signed"}>
          <Space>
            <Button
              type="link"
              onClick={() => {
                window.open(liabilityWaiver.signed_doc_url, "_blank");
              }}
            > Download Signed Doc </Button>
            <Button
              type="link"
              onClick={() => {
                console.log("liabilityWaiver", liabilityWaiver);
                setIsDeliveryMethodSignedDocModalVisibility(true);
                setSelectedLiabilityWaiverId(liabilityWaiver.id);
              }}
            > Send Signed Doc To Customer </Button>
          </Space>
        </If>
        <If condition={liabilityWaiver.status !== "Signed"}>
          <Space>

            <Button
              type="link"
              onClick={() => {
                const signDocumentUrl = `${process.env.REACT_APP_FRONTEND_CUSTOMER_FACING_URL}/sign-liability-waiver/${liabilityWaiver.id}/${intakeForm.id}`;
                window.open(signDocumentUrl, "_blank");
              }}
            > Sign Doc </Button>
            <Button
              type="link"
              onClick={() => {
                console.log("liabilityWaiver", liabilityWaiver);
                setIsDeliveryMethodDocToSignModalVisibility(true);
                setSelectedLiabilityWaiverId(liabilityWaiver.id);
              }}
            > Send Doc To Customer To Sign </Button>
          </Space>
        </If>
      </Space>
    );
  }

  const warrantyIntakeFormSearchChange = (e, loadNextPage = false) => {
    const searchTerm = e?.target?.value || e;

    if (loadNextPage) {
      fetchIntakeFormsForWarrantySelection(searchTerm, intakeFormsWarrantyPagination.current + 1);
      return;
    }

    fetchIntakeFormsForWarrantySelection(searchTerm);
  }

  const handleDiscountAmountChange = (value) => {
    if (value < 0 || value > originalTotalPrice) {
      message.error("Not a valid amount")
      return;
    }
    const discountPercentage = (((value / originalTotalPrice) * 100) || 0)
    updateDiscountState(discountPercentage)
  };

  const updateDiscountState = (discountPercentage) => {
    let discountAmount = (originalTotalPrice * discountPercentage) / 100;
    
    if (discountAmount > originalTotalPrice) {
      discountAmount = discountPercentage = 0
    }
    
    form.setFieldsValue({
      "discount_amount": discountAmount,
    });
    setDiscountPercentage(PercentageFormatter(roundNumber(discountPercentage, 2)));
    setDiscountAmountValue(discountAmount);
    setTotalPriceSum(originalTotalPrice - discountAmount);
  };

  const applyCustomDiscount = () => {
    const percentage = roundNumber(parseFloat(form.getFieldValue("discount_percentage") || 0), 2);
    if (percentage < 0 || percentage > 100) {
      message.error("Invalid discount percentage");
      return;
    }
    updateDiscountState(percentage);
  };

  const handlePercentageTagChange = (tag, checked) => {
    const percentage = parseInt(tag, 10);
    setDisableCustomDiscountButton(true);
    form.setFieldsValue({
      "discount_percentage": null,
    });
    updateDiscountState(percentage);
  };

  const intakeFormFieldDisplay = (item) => {
    if (displayMode === "Edit" && item.isAddOnly) {
      return <></>;
    }
    if (displayMode === "Add" && item.isEditOnly) {
      return <></>;
    }

    const colspan = item.colspan ? item.colspan : 12;
    if (item.type === "heading") {
      const ignorableHeadlinesIfNotInsurance = ["Insurance Photos Upload", "Insurance Files Upload"]
      if (!isInsurancePaymentMethod) {
        for (const name of ignorableHeadlinesIfNotInsurance) {
          if (name === item.label) {
            return <></>
          }
        }
      }
      return (
        <Col span={colspan}>
          <Title level={3}>{item.label}</Title>
        </Col>
      )
    }

    if (item.type === "egr_lead") {
      if (!intakeForm?.egr_lead) return <></>;
      return (
        <Col span={colspan}>
          <Alert
            style={{ marginBottom: "15px" }}
            message="Generated from EGR Lead"
            description={
              <>
                This job was created from
                <Button
                  style={{ paddingLeft: "8px" }}
                  onClick={() => {
                    window.open(`/egr-lead/${intakeForm?.egr_lead_id}`, "_blank");
                  }}
                  type="link"
                >this EGR Lead. </Button>
              </>
            }
            type="success"
          />
        </Col>
      )
    }

    if (item.type === "created_by_callrail_form") {
      if (!(intakeForm?.created_by_callrail_form)) return <></>;
      return (
        <Col span={colspan}>
          <Alert
            style={{ marginBottom: "15px" }}
            message="Generated from Callrail Form"
            description={
              <>
                This job was created from
                <Button
                  style={{ paddingLeft: "8px" }}
                  onClick={() => {
                    window.open(`/callrail-records?callRailFilter=search_term%3D${intakeForm?.created_by_callrail_form?.phone_number}%26show_all_interactions%3Dtrue&callRailLogPage=1&callRailLogsMode=Forms`, "_blank");
                  }}
                  type="link"
                > this CallRail Form. </Button>
              </>
            }
            type="success"
          />
        </Col>
      )
    }

    if (item.type === "split_job_info") {
      if ((intakeForm?.splitted_from_intake_form_id || false) === false) return <></>;
      return (
        <Col span={colspan}>
          <Alert
            style={{ marginBottom: "15px" }}
            message="Split"
            description={
              <>
                This job was splitted from
                <Link
                  to={`/intake-form/${intakeForm?.splitted_from_intake_form_id}`}
                > this Job. </Link>
              </>
            }
            type="info"
          />
        </Col>
      )
    }

    if (item.type === "warranty_info") {
      if ((intakeForm?.is_warranty_work || false) === false) return <></>;
      let warrantyReasons = [];
      try {
        warrantyReasons = JSON.parse(intakeForm?.warranty_reasons || "[]");
      } catch (e) {
        console.log("Error while parsing warranty reasons", e);
      }
      return (
        <Col span={colspan}>
          <Alert
            style={{ marginBottom: "15px" }}
            message="Warranty"
            description={
              <>
                This job is a warranty work for
                <Link
                  to={`/intake-form/${intakeForm?.warranty_work_original_intake_form_id}`}
                > this Job. </Link>
              </>
            }
            type="info"
          />
          <Form.Item
            name="warranty_work_description"
            label="Warranty Work Note"
          >
            <TextArea />
          </Form.Item>
          <div style={{ marginTop: "15px", marginBottom: "15px" }}>
            <b>Warranty reasons: </b>
            <If condition={warrantyReasons.length > 0}>
              {warrantyReasons.join(", ")}
            </If>
            <If condition={warrantyReasons.length === 0}> <i>No warranty reasons present.</i> </If>
          </div>

        </Col>
      )
    }
    if (item.type === "price-total") {
      return (
        <Col span={colspan}>
          <Title level={3}>
            {item.label}: {DollarFormatter(roundNumber(originalTotalPrice))}
          </Title>
        </Col>
      )
    }
    if (item.type === "discount-amount") {
      return (
        <Col span={colspan}>
          <Title level={3}>
            {item.label}: ({DollarFormatter(roundNumber(discountAmountValue))}) ({(discountPercentage)})
          </Title>
        </Col>
      )
    }
    if (item.type === "calculated-total-price") {
      return (
        <Col span={colspan}>
          <Title level={3}>
            {item.label}: {DollarFormatter(roundNumber(totalPriceSum))}
          </Title>
        </Col>
      )
    }
    if (item.type === "emergencyJobCheckbox") {
      return (
        <Col span={colspan}>
          <Checkbox
            style={{ marginBottom: "10px" }}
            checked={isEmergencyService}
            onChange={emergencyServiceOnChange}>{item.label}</Checkbox>
        </Col>
      )
    }
    if (item.type === "job_notes_existing_job") {
      return (
        <Col span={colspan}>
          <Collapse defaultActiveKey={['1']} style={{ marginBottom: "15px", marginTop: "15px" }}>
            <Panel header="Job Notes" key="1">
              <JobNoteExpandComponent
                record={intakeForm}
              />
            </Panel>
          </Collapse>

        </Col>
      )
    }
    if (item.type === "checkbox") {
      let disabled = false;

      return (
        <Col span={colspan}>
          <Form.Item
            label={item.label}
            name={item.name}
            valuePropName="checked"
          >
            <Checkbox
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      )
    }
    if (item.type === "insurance-photos-upload") {
      if (!isInsurancePaymentMethod) {
        return <></>
      }
      return (
        <>
          <Col span={colspan}>
            <If condition={selectedInsuranceImage !== ""}>
              <div style={{ display: "block", justifyContent: "center", alignItems: 'center', marginBottom: "20px" }}>
                <Image
                  height={300}
                  className="selected-image"
                  src={selectedInsuranceImage}
                />
              </div>
            </If>
          </Col>
          <Col span={colspan}>
            {insuranceImages.map((photo) => {
              if (photo.url === undefined) {
                return <></>;
              }
              return (
                <div class="image-delete-icon-container">
                  <Popconfirm
                    title="Are you sure you want to delete this image?"
                    onConfirm={() => deleteImageHandler(photo, "InsuranceImage")}
                    okText="Yes"
                    cancelText="No"
                  >
                    <img
                      alt=""
                      className="btn-delete"
                      src="http://cdn1.iconfinder.com/data/icons/diagona/icon/16/101.png" />
                  </Popconfirm>

                  <img
                    alt=""
                    width={120}
                    className="img-hover-pointer"
                    style={{ marginRight: 10 }}
                    onClick={() => setSelectedInsuranceImage(`${photo.url}`)}
                    src={`${photo.url}`} />
                </div>
              );
            })
            }
          </Col>
          <Col span={colspan}>
            <Dragger {...insuranceImageUploadsProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag Images to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Dragger>
          </Col>
        </>
      )
    }
    if (item.type === "status-history") {
      return (
        <Col span={colspan} style={{ marginBottom: "10px" }}>
          <If condition={displayMode === "Edit"}>
            <Collapse
              onChange={(key) => {
                setActiveStatusHistoryKey(key);
                if (key.length === 0) {
                  return;
                }
                if (key[0] === "Main") {
                  if (_.isEmpty(statusHistory)) {
                    getIntakeFormHistoryEntries(intakeForm.id);
                  }
                }
              }}
              activeKey={activeStatusHistoryKey}
            >
              <Panel header="History" key="Main">
                <Collapse>
                  {item.extra.map((definition) => {
                    const isLegacyStatus = definition.key === "legacy_status_history";
                    return (
                      <Panel header={definition.panelHeaderLabel} key={definition.key}>
                        <Steps progressDot direction="vertical" current={statusHistory[definition.objectKey]?.length - 1 || 0}>
                          {statusHistory[definition.objectKey]?.map((historyEntry) => {
                            let createdDate = historyEntry.created_date;
                            if (createdDate !== null && createdDate !== undefined) {
                              createdDate = moment.utc(createdDate).local().format(displayDateTimeFormat);
                            }
                            return (
                              <Step
                                title={isLegacyStatus ? nicelyFormatPascalCaseString(historyEntry.status) : nicelyFormatPascalCaseString(historyEntry.new_value)}
                                description={
                                  <>
                                    <span className="display-block"> Created: {createdDate} </span>
                                    <If condition={!isLegacyStatus}>
                                      <span className="display-block"> Previous Value: {nicelyFormatPascalCaseString(historyEntry.old_value, "-")} </span>
                                    </If>
                                    <If condition={historyEntry.is_deleted}>
                                      <span className="display-block"> Deleted: {moment.utc(historyEntry.deleted_date).local().format(displayDateTimeFormat)} </span>
                                      <span className="display-block"> Deleted By: {historyEntry.user_deleted_by_id} </span>
                                    </If>
                                  </>
                                }
                              />
                            );
                          })}
                        </Steps>
                      </Panel>
                    )
                  })}
                </Collapse>
              </Panel>
            </Collapse>
          </If>
        </Col>
      )
    }
    if (item.type === "generic-photo-upload") {
      return (
        <>
          <Col span={colspan}>
            <If condition={selectedGenericImage !== ""}>
              <div style={{ display: "block", justifyContent: "center", alignItems: 'center', marginBottom: "20px" }}>
                <Image
                  height={300}
                  className="selected-image"
                  src={selectedGenericImage}
                />
              </div>
            </If>
          </Col>
          <Col span={colspan}>
            {genericImages.map((photo) => {
              if (photo.url === undefined) {
                return <></>;
              }
              return (
                <div class="image-delete-icon-container">
                  <Popconfirm
                    title="Are you sure you want to delete this image?"
                    onConfirm={() => deleteImageHandler(photo, "GenericImage")}
                    okText="Yes"
                    cancelText="No"
                  >
                    <img
                      alt=""
                      className="btn-delete"
                      src="http://cdn1.iconfinder.com/data/icons/diagona/icon/16/101.png" />
                  </Popconfirm>

                  <img
                    alt=""
                    width={120}
                    className="img-hover-pointer"
                    style={{ marginRight: 10 }}
                    onClick={() => setSelectedGenericImage(`${photo.url}`)}
                    src={`${photo.url}`} />
                </div>
              );
            })
            }
          </Col>
          <Col span={colspan}>
            <Dragger {...genericImageUploadsProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag Images to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Dragger>
          </Col>
        </>
      )
    }
    if (item.type === "insurance-files-upload") {
      if (!isInsurancePaymentMethod) {
        return <></>
      }
      return (
        <Col span={colspan} style={{ marginBottom: "20px", marginTop: "20px" }}>
          <Dragger {...insuranceFilesUploadProps} fileList={insuranceDocuments}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag Insurance File Documents to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Dragger>
        </Col>
      )
    }
    if (item.type === "intakeFormTags") {
      return (
        <Col span={colspan}>
          <Form.Item
            label={item.label}
            name={item.name}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Select Tags"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
              dropdownRender={(menu) => {
                const displayNode = (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Row style={{ marginLeft: "10px", marginRight: '10px' }}>
                      <Col flex="auto">
                        <Input
                          placeholder="Enter new tag"
                          value={newTagAddition}
                          onChange={(e) => setNewTagAddition(e.target.value)} />
                      </Col>
                      <Col flex="200px">
                        <Typography.Link
                          onClick={addNewIntakeFormTag}
                          style={{ whiteSpace: 'nowrap' }}>
                          <PlusOutlined /> Add Tag (not reusable)
                        </Typography.Link>
                      </Col>
                    </Row>
                  </>
                )
                if (newTagAdding) {
                  return (
                    <Spin>
                      {displayNode}
                    </Spin>
                  )
                }
                return displayNode
              }}
            >
              {tags.map((tag) => {
                if (tag.is_just_added_by_user !== true) {
                  if (!tag.is_reusable && !currentSelectedTagIds.includes(tag.id)) {
                    return <></>;
                  }
                }

                return (
                  <Option
                    value={tag.id}
                    key={tag.id}
                  >
                    {tag.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

        </Col>
      )
    }
    if (item.type === "job-type-heading") {
      if (selectedJobTypeTags.find(e => e.includes(item.jobType))) {
        return (
          <Col span={colspan}>
            <Title level={3}>{item.label}</Title>
          </Col>
        )
      } else {
        return <></>
      }
    }

    if (item.type === "quick_quote") {
      if (displayMode === "Add") {
        return <></>;
      }
      return (
        <Col span={colspan}>
          <Title level={3}>{item.label}</Title>
          <i style={{ display: "block", marginTop: "15px", marginBottom: "15px" }}>Create a Quick Quote for existing Intake Form details. All details except Jobs will be copied over.</i>
          <Popconfirm
            title={
              <>
                <b>Create new Quick Quote?</b>
                <p style={{ display: "block" }}>
                  Quick Quote will be created from <b>currently saved</b> Intake Form - if you made changes just now, they WON'T be present in new Quote Intake Form.
                </p>
                <p style={{ display: "block", marginTop: "13px" }}>Are you sure you want to make a new Quote Intake Form with this job details?</p>
              </>
            }
            onConfirm={() => {
              setQuickQuoteButtonLoading(true);
              const payload = {
                intake_form_id: intakeForm.id
              }
              quickQuoteForIntakeForm(payload).then((response) => {
                setQuickQuoteButtonLoading(false);
                const { data } = response;
                message.success("Successfully created a new Quote Job entry.");
              }).catch((err) => {
                setQuickQuoteButtonLoading(false);
                message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
              })
            }}
            okText="Yes - create new Job"
            cancelText="Cancel"
          >
            <Button
              loading={quickQuoteButtonLoading}
              type="primary"
              style={{ marginBottom: "15px" }}
            > Quick Quote </Button>
          </Popconfirm>
        </Col>
      )
    }

    if (item.type === "warranty_original_job_selection") {
      if (!isWarrantyPaymentMethod) {
        return <></>;
      }

      return (
        <Col span={colspan}>
          <Title level={3}> Warranty - Original Job </Title>
          <i style={{ display: "block" }}>
            Selected:
            <If condition={selectedWarrantySourceIntakeForm}>
              &nbsp; Customer:
              &nbsp;{formatCustomerDisplayValue(selectedWarrantySourceIntakeForm.customer)},
              &nbsp;{getVehiclesForJob(selectedWarrantySourceIntakeForm).join(", ")},
              &nbsp;{getListOfServicesForIntakeForm(selectedWarrantySourceIntakeForm).join(", ")}
            </If>
          </i>
          <p style={{ marginBottom: "9px", marginTop: "8px" }}> Search Term:</p>
          <Form.Item
            name={"warranty_intake_search_term_value"}
          >
            <Input onChange={debounce(warrantyIntakeFormSearchChange, 1000)} />
          </Form.Item>
          <Form.Item
            name={"warranty_work_original_intake_form_id"}
          >
            <div
              id="scrollableDiv"
              style={{
                height: intakeFormsWarranty.length !== 0 ? 550 : 200,
                overflow: 'auto',
                padding: '0 16px',
                border: '1px solid rgba(140, 140, 140, 0.35)',
              }}
            >
              <InfiniteScroll
                dataLength={intakeFormsWarranty.length}
                next={() => {
                  const warrantyIntakeFormSearchFieldValue = form.getFieldValue("warranty_intake_search_term_value");
                  warrantyIntakeFormSearchChange(warrantyIntakeFormSearchFieldValue, true)
                }}
                hasMore={intakeFormsWarranty.length < (intakeFormsWarrantyPagination.total || 0)}
                loader={
                  <Skeleton
                    paragraph={{
                      rows: 1,

                    }}
                    active
                  />
                }
                endMessage={
                  <If condition={intakeFormsWarranty.length !== 0}>
                    <Divider plain>You have reached the end</Divider>
                  </If>
                }
                scrollableTarget="scrollableDiv"
              >
                <List
                  grid={{
                    gutter: 12,
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 3,
                    xl: 3,
                    xxl: 3,
                  }}
                  style={{ marginTop: "10px" }}
                  size="small"
                  loading={intakeFormsWarrantyLoading}
                  dataSource={intakeFormsWarranty}
                  renderItem={(intakeFormWarrantyCandidate) => {
                    const { phone_number: phoneNr, email } = intakeFormWarrantyCandidate.customer;
                    const appointmentTime = intakeFormWarrantyCandidate.appointment_time
                    const csrNames = getCsrsNamesForIntakeForm(intakeFormWarrantyCandidate);
                    const technicians = getTechniciansForIntakeForm(intakeFormWarrantyCandidate);
                    const services = getListOfServicesForIntakeForm(intakeFormWarrantyCandidate);
                    const vehicles = getVehiclesForJob(intakeFormWarrantyCandidate);

                    const displayValues = [
                      { label: "Customer", value: formatCustomerDisplayValue(intakeFormWarrantyCandidate.customer) },
                      { label: "Customer Phone Number", value: phoneNr },
                      { label: "Customer Email", value: email },
                      { label: "Appointment Time", value: appointmentTime ? appointmentTime.format(displayDateTimeFormat) : "Not set" },
                      { label: "CSR(s)", value: csrNames.join(", ") },
                      { label: "Technician(s)", value: technicians.join(", ") },
                      { label: "Service(s)", value: services.join(", ") },
                      { label: "Vehicle(s)", value: vehicles.join(", ") },
                      { label: "Price", value: intakeFormWarrantyCandidate.price },
                    ]
                    return (
                      <List.Item
                        key={intakeFormWarrantyCandidate.title}
                      >
                        <Card
                          style={{
                            backgroundColor: intakeFormWarrantyCandidate?.id === intakeForm?.id ? "rgb(240 240 240 / 70%)" : "white",
                          }}
                          title={null}
                          extra={
                            <If condition={intakeFormWarrantyCandidate.id === selectedWarrantySourceIntakeForm?.id}>
                              <CheckCircleOutlined
                                style={{
                                  color: "green",
                                  fontSize: '32px',
                                }}
                              />
                            </If>
                          }
                        >
                          {displayValues.filter(x => x.value !== "" && x.value !== null && x.value !== undefined).map((displayValue) => {
                            return (
                              <p style={{ marginBottom: "0px", display: "block" }}>
                                <b>{displayValue.label}:</b> {displayValue.value}
                              </p>
                            )
                          })}
                          <Button
                            disabled={intakeFormWarrantyCandidate.id === intakeForm?.id}
                            type="link"
                            style={{ float: "right" }}
                            onClick={() => {
                              if (intakeFormWarrantyCandidate.id === intakeForm?.id) {
                                message.error("You can't select the current job as the warranty source job.");
                                return;
                              }
                              setSelectedWarrantySourceIntakeForm(intakeFormWarrantyCandidate);
                              form.setFieldsValue({ warranty_work_original_intake_form_id: intakeFormWarrantyCandidate.id })
                            }}> Use </Button>
                        </Card>
                      </List.Item>
                    )
                  }}
                />
              </InfiniteScroll>
            </div>
          </Form.Item>
        </Col>
      )
    }

    if (item.type === "overwrite-customer-data-switch") {
      if (selectedCustomer === undefined) {
        return (
          <Col span={colspan} ref={customersDataRef}>
            <p style={{ display: "block", marginBottom: "10px" }}>New customer will be created</p>
          </Col>
        )
      }

      return (
        <Col span={colspan} ref={customersDataRef}>
          <Form.Item
            name={item.name}
            label={item.label}
            valuePropName="checked"
          >
            <Switch
              onChange={(e) => {
                if (!e && selectedCustomer !== undefined) {
                  setSelectedCustomer({ ...selectedCustomer });
                }
                setIsOverrideCustomerInfo(e);
              }} />
          </Form.Item>
        </Col>
      )
    }
    if (item.type === "switch") {
      let disabled = false;
      // check if the customer field should be disabled (customer data overwrite isn't checked)
      const customerFields = ["is_company"];
      for (const iterator of customerFields) {
        if (item.name === iterator && !isOverrideCustomerInfo) {
          disabled = true;
          break;
        }
      }
      return (
        <Col span={colspan}>
          <Form.Item
            name={item.name}
            label={item.label}
            valuePropName="checked"
          >
            <Switch
              disabled={disabled}
              onChange={(checked) => {
                setIsCustomerACompany(checked);
              }}
            />
          </Form.Item>
        </Col>
      )
    }
    if (item.type === "deposit_management_component") {
      let depositsDataSource = [];
      let nonDeletedDeposits = depositRequests.filter(x => x.is_deleted === undefined || x.is_deleted === false);
      if (showDeletedDepositRequests) {
        depositsDataSource = depositRequests;
      } else {
        depositsDataSource = nonDeletedDeposits
      }
      return (
        <Col span={colspan} ref={depositRequestsRef} style={{ scrollMargin: "100px" }}>
          <Collapse style={{ marginBottom: "10px" }}>
            <Panel
              header={
                <Space>
                  <span>Deposits</span>
                  <Badge
                    overflowCount={9999}
                    showZero
                    count={nonDeletedDeposits.length || 0}
                    style={{ backgroundColor: '#1DA57A' }}
                  />
                </Space>
              }
              key="depositsPanel"
            >
              <span style={{ display: "block" }}> Display Deleted Deposit Requests? </span>
              <Switch
                style={{ marginBottom: "10px" }}
                checked={showDeletedDepositRequests}
                onChange={(e) => {
                  setShowDeletedDepositRequests(e);
                }}
              />
              <Table
                rowKey={"id"}
                pagination={false}
                columns={[
                  {
                    title: 'Method',
                    key: 'method',
                    render: (record) => {
                      return (
                        <Space key="methodSpace">
                          <If condition={record?.userAdded}>
                            <Popover key="popoverDepositInfo" content={"Deposit Request is not yet saved - please save the current Intake Form to create the request."} title="Info">
                              <InfoCircleFilled style={{ color: "orange" }} />
                            </Popover>
                          </If>
                          <If condition={record.square_invoice_publish_error !== null && record.square_invoice_publish_error !== undefined}>
                            <Popover
                              key="popoverPublishError"
                              content={
                                <>
                                  <span style={{ display: "block" }}>Publish action failed! Full response dump:</span>
                                  <span style={{ display: "block" }}>{record.square_invoice_publish_error}</span>
                                </>
                              }
                              overlayStyle={{ maxWidth: "500px" }}
                              title="ERROR"
                            >
                              <ExclamationCircleFilled style={{ color: "red" }} />
                            </Popover>
                          </If>

                          <span key="methodSpan">{nicelyFormatPascalCaseString(record?.method)}</span>
                        </Space>
                      )
                    }
                  },
                  {
                    title: 'Delivery Method',
                    key: 'delivery_method',
                    dataIndex: 'delivery_method',
                    render: (record) => {
                      return record ? record : "-";
                    }
                  },
                  {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    render: (record) => {
                      return (
                        <Space key="statusSpace">
                          <If condition={record === "CanceledFromSquare"}>
                            <Popover key="canceledFromSquareInfo" content={"Square Invoice has been canceled from Square. 'Paid Amount' could be incorrect - proceed with caution as that amount is used to compute 'Deposit Amount'! Double check all deposits and work price"} title="Warning">
                              <ExclamationCircleFilled style={{ color: "red" }} />
                            </Popover>
                          </If>
                          {nicelyFormatPascalCaseString(record)}
                        </Space>
                      )
                    }
                  },
                  {
                    title: 'Amount',
                    dataIndex: 'amount',
                    key: 'amount',
                    render: (record) => {
                      return DollarFormatter(record);
                    }
                  },
                  {
                    title: 'Paid Amount',
                    dataIndex: 'amount_paid',
                    key: 'amount_paid',
                    render: (record) => {
                      return DollarFormatter(record);
                    }
                  },
                  {
                    title: 'Notes',
                    dataIndex: 'notes',
                    key: 'notes',
                  },
                  {
                    title: 'Created',
                    dataIndex: 'created_date',
                    key: 'created_date',
                    render: (record) => {
                      return moment.utc(record).local().format(displayDateTimeFormat);
                    }
                  },
                  {
                    title: 'Paid On',
                    dataIndex: 'paid_date',
                    key: 'paid_date',
                    render: (record) => {
                      if (record === undefined || record === null) {
                        return <i>Not yet fully paid</i>;
                      }
                      return moment.utc(record).local().format(displayDateTimeFormat);
                    }
                  },
                  {
                    title: 'Actions',
                    key: 'actions',
                    render: (record) => {
                      return (
                        <>
                          <Space key="actionsSpace">
                            <If condition={!record?.userAdded && record.delivery_method === "SMS"}>
                              <Button
                                type="link"
                                onClick={() => {
                                  setDepositRequests((curr) => {
                                    return curr.map(x => {
                                      if (x.id === record.id) {
                                        if (x.resend_request) {
                                          x.resend_request = false;
                                        } else {
                                          x.resend_request = true;
                                        }
                                      }
                                      return x;
                                    })
                                  })
                                }}
                              >
                                {record.resend_request ? "Cancel Resend" : "Resend"}
                              </Button>
                            </If>

                            <Button
                              type="link"
                              onClick={() => {
                                setIsEditDepositModalVisible(true);
                                setSelectedDepositRequest(record);
                              }}
                            >
                              Edit
                            </Button>

                            <If condition={record?.userAdded}>
                              <Button
                                type="link"
                                onClick={() => {
                                  setDepositRequests((curr) => {
                                    return curr.filter(e => e.id !== record.id)
                                  })
                                }}
                              >
                                Remove Request
                              </Button>
                            </If>
                            <If condition={record?.userAdded === undefined || record?.userAdded === null}>
                              <Popconfirm
                                title={
                                  <>
                                    <span style={{ display: "block", maxWidth: "250px" }}>
                                      Are you sure to cancel this Deposit Request?
                                      If the delivery method is Square Invoice, the Square Invoice will
                                      be canceled (after you save the current Intake Form) and you will
                                      need to <b>manually</b> issue a refund to the customer if they
                                      wish to get a refund.
                                    </span>
                                  </>
                                }
                                onConfirm={() => {
                                  setDepositRequests((curr) => {
                                    return curr.map((x) => {
                                      if (x.id === record.id) {
                                        x.is_deleted = true;
                                      }
                                      return x;
                                    })
                                  })
                                }}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button
                                  type="link"
                                >
                                  Cancel Request
                                </Button>
                              </Popconfirm>
                            </If>
                          </Space>
                          <If condition={record?.method === "SquareInvoice" && !record?.userAdd}>
                            <Row>
                              <Col span={24}>
                                <Button
                                  type="link"
                                  onClick={() => {
                                    const squareInvoiceId = record?.intake_form_square_invoice_history?.square_invoice_id;
                                    if (squareInvoiceId === null || squareInvoiceId === undefined) {
                                      message.warning("No Square Invoice ID found for this Deposit Request");
                                      return;
                                    }
                                    window.open(`https://squareup.com/pay-invoice/${squareInvoiceId}`, '_blank');
                                  }}
                                >
                                  Open Square Invoice
                                </Button>
                              </Col>
                            </Row>
                          </If>
                        </>
                      )
                    }
                  },
                ]}
                dataSource={depositsDataSource}
              />
              <Button
                type="primary"
                style={{ marginTop: "10px" }}
                onClick={() => {
                  const currentLocation = getCurrentSelectedShopLocation();

                  if (!verifyShopLocationOkForNewSquareInvoiceCreation(currentLocation)) {
                    return;
                  }

                  setIsAddDepositModalVisible(true);
                }}
              >
                Add Deposit Request
              </Button>
            </Panel>
          </Collapse>
        </Col>
      )
    }

    if (item.type === "callback-info-component") {
      return (
        <Col span={colspan}>
          <Title level={3}> {item.label} </Title>
          <div className="space-align-block">
            <Button
              style={{ marginBottom: "13px" }}
              onClick={() => {
                const randomId = uuidv4();
                setAlternativeContacts((curr) => [...curr, { id: randomId, userAdded: true }]);
              }}
              type="primary"
            > Add Callback </Button>
          </div>
          <If condition={(alternativeContacts || []).length > 0}>
            <div style={{ marginBottom: "20px", marginTop: "15px" }}>
              <Collapse>
                {alternativeContacts.map((alternativeContact) => {
                  const contactNameFields = [alternativeContact.first_name, alternativeContact.last_name, alternativeContact.phone_number]
                  let contactName = contactNameFields.filter(x => x !== undefined && x !== null && x !== "").join(" ");
                  if (contactName === "") contactName = <i>Please enter Callback details</i>

                  const canUserDeleteEditNotes = IsUserAllowedToEditOrDeleteCallbackNote(accountUsers) || displayMode === "Add";

                  return (
                    <Panel
                      key={alternativeContact.id}
                      header={contactName}
                      extra={
                        <If condition={canUserDeleteEditNotes}>
                          <Popconfirm
                            title="Are you sure to delete this callback?"
                            onConfirm={(e) => {
                              e.stopPropagation();
                              setAlternativeContacts((curr) => {
                                return curr.filter(x => x.id !== alternativeContact.id)
                              })
                            }}
                            onCancel={(e) => e.stopPropagation()}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              onClick={(e) => e.stopPropagation()}
                              icon={<DeleteOutlined />}
                            > Remove </Button>
                          </Popconfirm>
                        </If>
                      }>
                      <AlternativeContactComponent
                        alternativeContact={alternativeContact}
                        setAlternativeContacts={setAlternativeContacts}
                        canUserDeleteEditNotes={canUserDeleteEditNotes}
                      />
                    </Panel>
                  )
                })}
              </Collapse>
            </div>
          </If>
        </Col>
      )
    }

    if (item.type === "insurance_pricing_component") {
      if (!isInsurancePaymentMethod) return <></>;
      return (
        <Col span={colspan}>

          <Title level={3}> Insurance Pricing </Title>

          <Collapse style={{ marginBottom: "10px" }}>
            <Panel header="Insurance Pricing" key="insurancePricing">
              <InsurancePricingIntakeFormComponent
                isVisible={isInsurancePricingComponentVisible}
                insuranceCarriers={insuranceCarriers}
                selectedInsuranceCarrierId={selectedInsuranceCarrierId}
                glassReplacementJobs={glassReplacementJobEntries}
                setGlassReplacementJobs={setGlassReplacementJobEntries}
                nagsPartNrPriceAGCRM={nagsPartNrAGCRMInstallInfos}
                applyPriceSheet={(glassJobWithPriceSheet, glassPriceTotal, calibrationPriceTotal) => {
                  console.log({ glassJobWithPriceSheet, glassPriceTotal, calibrationPriceTotal });
                  if (!(
                    glassJobWithPriceSheet.calibration_price_sheet_option
                    ||
                    glassJobWithPriceSheet.glassPartPriceSheet
                  )) {
                    message.error("No price sheet selected! Discount not applied!");
                    console.log("No price sheet price reduction/change applied");
                    return;
                  }

                  setGlassReplacementJobEntries((curr) => {
                    return curr.map((x) => {
                      if (x.key === glassJobWithPriceSheet.key) {
                        x.calibration_price_sheet_option = glassJobWithPriceSheet.calibration_price_sheet_option;
                        x.glassPartPriceSheet = glassJobWithPriceSheet.glassPartPriceSheet;
                        x.calibration_pricing_total = calibrationPriceTotal;
                        x.glass_price_total = glassPriceTotal;
                      }
                      return x;
                    })
                  })

                  message.success("Price sheet discount applied!");
                }}
              />
            </Panel>
          </Collapse>
        </Col>
      )
    }

    if (item.type === "ddl-debounce-select-customer-info-search") {
      if (!showCustomerInfoSearchBar) {
        return <></>;
      }
      return (
        <Col span={colspan}>
          <Form.Item
            label={item.label}
            name={item.name}
          >
            <DebounceSelect
              allowClear={true}
              showSearch={true}
              debounceTimeout={450}
              value={selectedCustomer}
              placeholder="Start typing a keyword of customer first name and last name (in that order)"
              fetchOptions={fetchCustomers}
              extraParam={displayMode === "Add" ? prefillCustomerData?.id : intakeForm?.customer_id}
              onChange={(selectedCustomer) => {
                setSelectedCustomer(selectedCustomer);
              }}
            />
          </Form.Item>
        </Col>
      )
    }
    if (item.type === "ddl-debounce-select-vehicle-search") {
      return (
        <>
          <Col span={colspan}>
            <Form.Item
              label={item.label}
            >
              <DebounceSelect
                allowClear={true}
                showSearch={true}
                debounceTimeout={450}
                value={selectedVehicle}
                placeholder="Start typing a keyword of vehicle year, make and model (in that order)"
                fetchOptions={fetchVehicles}
                onChange={(selectedVehicle) => {
                  setSelectedVehicle(selectedVehicle);
                }}
              />
            </Form.Item>
          </Col>
          <If condition={vehicles.length !== 0}>
            <Col span={colspan}>
              <p style={{ marginTop: "13px", marginBottom: "13px" }}>List of Vehicles</p>
            </Col>
          </If>
          <Col span={24}>
            <Collapse activeKey={activeKeyVehicleCollapse} onChange={(key) => setActiveKeyVehicleCollapse(key)}>
              {vehicles.map((vehicle) => {
                const vehicleNameFields = [vehicle.year, vehicle.make, vehicle.model]
                let vehicleName = vehicleNameFields.filter(x => x !== undefined && x !== null && x !== "").join(" ");
                if (vehicleName === "") vehicleName = <i>Please enter vehicle details</i>
                return (
                  <Panel
                    key={vehicle.id}
                    header={vehicleName}
                    extra={
                      <Popconfirm
                        title="Are you sure to delete this vehicle?"
                        onConfirm={(e) => {
                          e.stopPropagation();
                          setVehicles((curr) => {
                            return curr.filter(x => x.id !== vehicle.id)
                          })

                          // updating the job entries, setting the intake form vehicle selection to null
                          const functs = [
                            setGlassReplacementJobEntries, setChipRepairJobEntries,
                            setBodyAndPaintJobEntries, setTintOnlyJobEntries,
                            setVehicleDetailJobEntries
                          ]

                          for (const iterator of functs) {
                            iterator((curr) => {
                              return curr.map(x => {
                                if ((x.intake_form_vehicle_id || "None").toString() === vehicle.id.toString()) {
                                  x.intake_form_vehicle_id = null;
                                }
                                return x;
                              })
                            })
                          }
                        }}
                        onCancel={(e) => e.stopPropagation()}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button onClick={(e) => e.stopPropagation()} icon={<DeleteOutlined />}>Remove</Button>
                      </Popconfirm>
                    }>
                    <VehicleComponent
                      refreshVehicles={refreshVehicles}
                      vehicle={vehicle}
                      setVehicles={setVehicles}
                    />
                  </Panel>
                )
              })}
            </Collapse>
          </Col>
        </>
      )
    }
    if (item.type === "add-new-vehicle") {
      return (
        <Col span={colspan}>
          <Space>
            <Title level={3}>{item.label}</Title>
            <div style={{ marginBottom: "12px", marginLeft: "12px" }}>
              <Link
                to="#"
                onClick={() => setIsAddMissingVehicleModalVisible(true)}> Add a missing vehicle </Link>
            </div>
          </Space>
        </Col>
      )
    }

    if (item.type === "add-vehicle-component") {
      return (
        <>
          <Col span={colspan}>
            <div className="space-align-block">
              <Button
                onClick={() => {
                  const randomId = uuidv4();
                  setVehicles((curr) => [...curr, { id: randomId, userAdded: true }])
                  setActiveKeyVehicleCollapse(randomId)
                }}
                type="primary"
              >{item.label}</Button>
            </div>
          </Col>
        </>
      )
    }

    if (item.type === "nags_installation_time") {
      let hourSum = 0;
      const nagsPartsRows = Object.getOwnPropertyNames(nagsPartNrAGCRMInstallInfos).map((key) => {
        let hours = nagsPartNrAGCRMInstallInfos[key].user_edited_hours;
        let isUserEditedTime = true;
        if (hours === null || hours === undefined) {
          hours = nagsPartNrAGCRMInstallInfos[key].hours;
          isUserEditedTime = false;
        }
        hourSum = hourSum + hours;
        return {
          fullObject: nagsPartNrAGCRMInstallInfos[key],
          isUserEditedTime: isUserEditedTime,
          key: key,
          value: hours
        }
      });
      return (
        <>
          <Col span={colspan}>
            <h3>Installation Time</h3>
            <Table
              pagination={false}
              columns={[
                {
                  title: 'Part',
                  render: (record) => {
                    const isUserEditedTime = record.isUserEditedTime;
                    const partNrDisplay = <p>{record.key}</p>;
                    return (
                      <>
                        <If condition={isUserEditedTime}>
                          <Tooltip title={`AG CRM listed hours: ${record.fullObject.hours}`}>
                            <Space>
                              {partNrDisplay} <EditOutlined />
                            </Space>
                          </Tooltip>
                        </If>
                        <If condition={!isUserEditedTime}>
                          {partNrDisplay}
                        </If>
                      </>
                    )
                  }
                },
                {
                  title: 'Hours',
                  dataIndex: 'value',
                },
                {
                  title: 'Action',
                  render: (record) => {
                    return (
                      <Button onClick={() => {
                        setIsEditNagsPartNrSearchResultModalVisible(true);
                        setSelectedNagsPartNr(record.key);
                      }}>
                        Edit
                      </Button>
                    )
                  }
                },
              ]}
              dataSource={[
                ...nagsPartsRows,
                {
                  key: "Total",
                  value: roundNumber(hourSum, 2)
                }
              ]}
              size="small"
            />
          </Col>
        </>
      )
    }

    if (item.type === "vinLookupButton") {
      return (
        <Space align="center">
          <Col span={colspan}>
            <div className="space-align-block">
              <Button
                onClick={() => {
                  vinLookupHandler();
                }}
                type="primary"
              >{item.label}</Button>
            </div>
          </Col>
        </Space>
      )
    }

    if (item.type === "customer-info-heading-button") {
      return (
        <Col span={colspan}>
          <Space>
            <Title level={3}>{item.label}</Title>
            <If condition={(customerDetailsCollapseActiveKey || []).includes("customer_details")}>
              <div
                style={{ marginBottom: "12px", marginLeft: "12px" }}>
                <Button
                  type="link"
                  onClick={() => setShowCustomerInfoSearchBar((prevVal) => !prevVal)}> Search Customer </Button>
              </div>
            </If>
          </Space>
        </Col>
      )
    }

    if (item.type === "job-type-object") {
      const isMatchingSelectedJobTypes = selectedJobTypeTags.find(e => e.includes(item.jobTypeRequired));

      if (!isMatchingSelectedJobTypes) {
        return <></>;
      }
      switch (item.jobTypeRequired) {
        case "Glass":
          return (
            <Col span={colspan}>
              <GlassReplacementJobEntryTable
                nagsPartNrAGCRMInstallInfos={nagsPartNrAGCRMInstallInfos}
                setNagsPartNrAGCRMInstallInfos={setNagsPartNrAGCRMInstallInfos}
                glassReplacementJobEntries={glassReplacementJobEntries}
                setGlassReplacementJobEntries={setGlassReplacementJobEntries}
                isInsuranceWorkType={isInsurancePaymentMethod}
                intakeFormFormObject={form}
                editingKey={editingKeyGlassReplacementJobEntry}
                setEditingKey={setEditingKeyGlassReplacementJobEntry}
                unacknowledgedJobReviews={unacknowledgedJobReviews}
                setUnacknowledgedJobReviews={setUnacknowledgedJobReviews}
                expertUnpromptedPartReviews={expertUnpromptedPartReviews}
                setExpertUnpromptedPartReviews={setExpertUnpromptedPartReviews}
                intakeForm={intakeForm}
                refreshUnacknowledgedJobReviews={loadJobReviewData}
                setIsGlassPartMarkedForSale={setIsGlassPartMarkedForSale}
                vehicles={vehicles}
                technicianEmployees={technicianEmployees}
                shopLocations={shopLocations}
                isMobileJobLocation={isMobileJobLocation}
                isQuoteOnlyLocationSelected={isQuoteOnlyLocationSelected}
              />
            </Col>
          )

        case "Chip Repair":
          return (
            <Col span={colspan}>
              <ChipRepairJobEntryTable
                chipRepairJobEntries={chipRepairJobEntries}
                setChipRepairJobEntries={setChipRepairJobEntries}
                editingKey={editingKeyChipRepairJobEntry}
                setEditingKey={setEditingKeyChipRepairJobEntry}
                vehicles={vehicles}
                technicianEmployees={technicianEmployees}
              />
            </Col>
          )

        case "Body & Paint":
          return (
            <Col span={colspan}>
              <BodyAndPaintJobEntryTable
                bodyAndPaintJobEntries={bodyAndPaintJobEntries}
                setBodyAndPaintJobEntries={setBodyAndPaintJobEntries}
                editingKey={editingKeyBodyAndPaintJobEntry}
                setEditingKey={setEditingKeyBodyAndPaintJobEntry}
                vehicles={vehicles}
                technicianEmployees={technicianEmployees}
              />
            </Col>
          )

        case "Tint Only":
          return (
            <Col span={colspan}>
              <TintOnlyJobEntryTable
                tintOnlyJobEntries={tintOnlyJobEntries}
                setTintOnlyJobEntries={setTintOnlyJobEntries}
                editingKey={editingKeyTintOnlyJobEntry}
                setEditingKey={setEditingKeyTintOnlyJobEntry}
                vehicles={vehicles}
                technicianEmployees={technicianEmployees}
              />
            </Col>
          )

        case "Vehicle Detail":
          return (
            <Col span={colspan}>
              <VehicleDetailJobEntryTable
                vehicleDetailJobEntries={vehicleDetailJobEntries}
                setVehicleDetailJobEntries={setVehicleDetailJobEntries}
                editingKey={editingKeyVehicleDetailJobEntry}
                setEditingKey={setEditingKeyVehicleDetailJobEntry}
                vehicles={vehicles}
                technicianEmployees={technicianEmployees}
              />
            </Col>
          )
        default:
          return <></>
      }
    }
    if (item.type === "tags") {
      return (
        <Col span={colspan} style={{ marginBottom: "20px" }}>
          <span style={{ marginRight: 8, marginBottom: 10, display: "block" }}>{item.label}:</span>
          {item.options.map(tag => (
            <CheckableTag
              key={tag}
              style={{ border: '1px solid gray' }}
              checked={
                item.name === "discount_percentage_tag"
                  ? false
                  : selectedJobTypeTags.indexOf(tag) > -1
              }
              onChange={checked =>
                item.name === "discount_percentage_tag"
                  ? handlePercentageTagChange(tag, checked)
                  : handleJobTypeChange(tag, checked)
              }
            >
              {tag}
            </CheckableTag>
          ))}
        </Col>
      );
    }
    if (item.type === "phoneNumber") {
      let disabled = false;
      // check if the phone number should be hidden (customer data overwrite isn't checked)
      if (item.name === "telephone" && !isOverrideCustomerInfo) {
        disabled = true;
      }

      let label = item.label
      if (foundCustomerByPhoneNumber !== null) {
        label = (
          <Space>
            <span>{label}</span>
            <Button type="link"
              onClick={() => {
                const labelNameValue = formatCustomerSearchDisplayLabelValue(foundCustomerByPhoneNumber);
                setSelectedCustomer({
                  label: `${labelNameValue}, Phone #: ${foundCustomerByPhoneNumber.telephone}`,
                  value: JSON.stringify({
                    ...foundCustomerByPhoneNumber,
                    net_term: foundCustomerByPhoneNumber.default_net_term,
                    is_vip: foundCustomerByPhoneNumber.default_is_vip
                  }),
                  key: foundCustomerByPhoneNumber.id,
                });
                setFoundCustomerByPhoneNumber(null);
              }}
            > Customer found with matching phone number, use saved details?</Button>
          </Space>
        )
      }

      return (
        <Col span={colspan}>
          <Form.Item
            label={label}
            name={item.name}
            rules={[
              {
                required: item.required,
                message: `Please input the ${item.label}`,
              },
            ]}
          >
            <InputMask
              mask={phoneNumberMaskFormatNines}
              maskChar="_"
              disabled={disabled}
              className="ant-input"
              placeholder={`Please input the ${item.label}`}>
            </InputMask>
          </Form.Item>
        </Col>

      )
    }
    if (item.type === "yearNumberMask") {
      return (
        <Col span={colspan}>
          <Form.Item
            label={item.label}
            name={item.name}
            rules={[
              {
                required: item.required,
                message: `Please input the ${item.label}`,
              },
            ]}
          >
            <InputMask
              mask="9999"
              maskChar="_"
              className="ant-input"
              placeholder={`Please input the ${item.label}`}>
            </InputMask>
          </Form.Item>
        </Col>

      )
    }
    if (item.type === "text") {

      if (item.name === "job_reference" && displayMode === "Add") {
        return <></>;
      }

      let required = item.required;
      let disabled = false;

      // if the is_company field is true, hide first name, last name
      // if the is_company field is false, hide company name
      // if (isCustomerACompany && (item.name === "first_name" || item.name === "last_name")) { 
      //   return <></>;
      // }
      // if (!isCustomerACompany && item.name === "company_name") { 
      //   return <></>;
      // }

      // new requirement came in where we can have both first name and last name and company name
      // required changes based on isCustomerACompany values
      if (isCustomerACompany && (item.name === "first_name" || item.name === "last_name")) {
        required = false;
      }
      if (!isCustomerACompany && item.name === "company_name") {
        required = false;
      }

      // check if the customer field should be disabled (customer data overwrite isn't checked)
      const customerFields = ["company_name", "first_name", "last_name", "address", "city", "state", "zip_code", "email"];
      for (const iterator of customerFields) {
        if (item.name === iterator && !isOverrideCustomerInfo) {
          disabled = true;
          break;
        }
      }

      // check if the status is quote. If it is, do not require address city and state
      const quoteNotRequired = ["address", "city", "state", "zip_code"]
      for (const iterator of quoteNotRequired) {
        if (item.name === iterator && isQuoteStatus) {
          required = false;
          break;
        }
      }

      const optionalMobileRequired = ["address", "city", "state", "zip_code"];
      if (!isMobileJobLocation) {
        for (const iterator of optionalMobileRequired) {
          if (item.name === iterator) {
            required = false;
          }
        }
      }

      const optionalInsuranceOnly = ["insurance_referral_claim_dispatch_number"]
      if (!isInsurancePaymentMethod) {
        for (const iterator of optionalInsuranceOnly) {
          if (item.name === iterator) {
            return <></>;
          }
        }
      }

      let isGoogleAutocomplete = false;
      if (item.label === "Address") {
        isGoogleAutocomplete = true;
      }

      let isVinElement = false;
      if (item.label === "VIN") isVinElement = true;

      if (item.disabled) disabled = true;

      let formRules = [
        {
          required: required,
          message: `Please input the ${item.label}`,
        }
      ]
      if (item.name === "email") {
        formRules.push({
          type: 'email',
          message: 'The input is not valid E-mail!',
        })
      }

      return (
        <Col span={colspan}>
          <Form.Item
            label={item.label}
            name={item.name}
            rules={formRules}
          >
            <If condition={isGoogleAutocomplete}>
              <PlacesAutocomplete
                onChange={(address) => {

                }}
                onSelect={(address) => {
                  console.log(address);
                  geocodeByAddress(address)
                    .then(results => fillOutCustomerInfoFromGeocodeResult(results[0]))
                    .catch(error => console.error('Error', error));
                }}
                debounce={800}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <Input
                      {...getInputProps({
                        placeholder: 'Search For Places...',
                        className: 'location-search-input',
                      })}
                      style={{
                        width: '100%'
                      }}
                      disabled={disabled}
                      size="middle"
                    />
                    <div className="autocomplete-dropdown-container" style={{ marginBottom: "10px", lineHeight: "28px" }}>
                      {loading && <div>Loading...</div>}
                      {suggestions.map(suggestion => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <Else />
              <If condition={isVinElement}>
                <Input
                  disabled={disabled}
                  size="middle"
                  className="uppercase-transform"
                  showCount
                  maxLength={17}
                  placeholder={`Please input the ${item.label}`}
                  style={{
                    textTransform: "uppercase"
                  }}
                />
                <Else />
                <Input
                  disabled={disabled}
                  size="middle"
                  placeholder={`Please input the ${item.label}`}
                />
              </If>
            </If>
          </Form.Item>
        </Col>

      )
    }
    if (item.type === "emptySpace") {
      return (
        <Col span={colspan}>
          <div style={{ width: "100%" }}></div>
        </Col>
      )
    }

    if (item.type === "quote_rejected_reason_object") {
      if (!rejectedQuoteReasonVisible) {
        return <></>;
      }
      // isOtherRejectedQuoteReasonSelected, setOtherRejectedQuoteReasonSelected
      return (
        <Col span={colspan}>
          <Form.Item
            label={item.label}
            name="quote_rejected_reason"
            rules={[
              {
                required: item.required,
                message: `Please input the ${item.label}`,
              },
            ]}
          >
            <Radio.Group onChange={(e) => {
              setOtherRejectedQuoteReasonSelected(e.target.value === "Other");
            }}>
              <Space direction="vertical">
                {QUOTE_REJECTED_REASONS.map((reason) => {
                  return <Radio value={reason}>{reason !== "Other" ? reason : `${reason} (specify)`}</Radio>
                })}
              </Space>
            </Radio.Group>
          </Form.Item>
          <If condition={isOtherRejectedQuoteReasonSelected}>
            <Form.Item
              label={`Other Quote Rejected Reason`}
              name="quote_rejected_reason_textarea"
              rules={[
                {
                  required: item.required,
                  message: `Please input the ${item.label}`,
                },
              ]}
            >
              <TextArea
                placeholder={`Please input the ${item.label}`}
              />
            </Form.Item>
          </If>
        </Col>

      )
    }
    if (item.type === "textarea") {
      if (item.name === "customer_cancelled_reason" && !customerCancellationReasonVisible) {
        return <></>;
      }
      return (
        <Col span={colspan}>
          <Form.Item
            label={item.label}
            name={item.name}
            rules={[
              {
                required: item.required,
                message: `Please input the ${item.label}`,
              },
            ]}
          >
            <TextArea
              placeholder={`Please input the ${item.label}`}
            />
          </Form.Item>
        </Col>

      )
    }
    if (item.type === "flipButton") {
      // if (isCustomerACompany) { 
      //   return <></>;
      // }
      // check if the customer fields should be hidden (customer data overwrite isn't checked)
      if (!isOverrideCustomerInfo) {
        return <></>
      }
      return (
        <Space align="center">
          <Col span={colspan}>
            <div className="space-align-block">
              <Button
                type="primary"
                onClick={() => {
                  let firstName = form.getFieldValue("first_name");
                  let lastName = form.getFieldValue("last_name");
                  form.setFieldsValue({
                    first_name: lastName,
                    last_name: firstName
                  });
                }}
                icon={<SwapOutlined />} />
            </div>
          </Col>
        </Space>
      )
    }
    if (item.type === "datetime") {
      return (
        <Col span={colspan}>
          <Form.Item
            label={item.label}
            name={item.name}
            rules={[
              {
                required: appointedTimeRequiredOverride ? true : item.required,
                message: `Please input the ${item.label}`,
              },
            ]}
          >
            <DatePicker
              showTime={{ use12Hours: true, format: "hh:mm a" }}
              placeholder={`Please input the ${item.label}`}
              disabledTime={disabledDateTime}
              hideDisabledOptions={true}
              minuteStep={15}
              style={{
                marginBottom: "10px",
                width: "100%"
              }}
              format={displayDateTimeFormat}
            />
          </Form.Item>
        </Col>
      )
    }
    if (item.type === "currency") {
      let disabled = item.disabled || false;
      let required = item.required || false;
      const optionalInsuranceOnly = ["deductible"]
      if (!isInsurancePaymentMethod) {
        for (const iterator of optionalInsuranceOnly) {
          if (item.name === iterator) {
            return <></>;
          }
        }
      }

      const isNotMobileHidden = ["mobile_fee"];
      if (!isMobileJobLocation) {
        for (const iterator of isNotMobileHidden) {
          if (item.name === iterator) {
            return <></>;
          }
        }
      }

      const isMobileRequired = ["mobile_fee"];
      if (isMobileJobLocation) {
        for (const iterator of isMobileRequired) {
          if (item.name === iterator) {
            required = true;
          }
        }
      }

      const isNotEmergencyServiceHidden = ["emergency_service_fee"]
      if (!isEmergencyService) {
        for (const iterator of isNotEmergencyServiceHidden) {
          if (item.name === iterator) {
            return <></>;
          }
        }
      }
      const isEmergencyServiceRequired = ["emergency_service_fee"];
      if (isEmergencyService) {
        for (const iterator of isEmergencyServiceRequired) {
          if (item.name === iterator) {
            required = true;
          }
        }
      }

      return (
        <Col span={colspan}>
          <Form.Item
            label={item.name === "discount_amount" ? `${item.label} (${discountPercentage})` : item.label}
            name={item.name}
            rules={[
              {
                required: required,
                message: `Please input the ${item.label}`,
              },
            ]}
          >
            <InputNumber
              disabled={disabled}
              size="middle"
              style={{ width: "100%" }}
              formatter={DollarFormatter}
              parser={DollarParser}
              placeholder={`Please input the ${item.label}`}
              min={0}
              onChange={(value) => {
                if (item.name === "discount_amount") {
                  handleDiscountAmountChange(value);
                }
              }}
            />
          </Form.Item>
        </Col>
      )
    }
    if (item.type === "percentage") {
      return (
        <Col span={colspan}>
          <Form.Item
            label={item.label}
            name={item.name}
            rules={[
              {
                required: false,
                message: `Please input the ${item.label}`,
              },
            ]}
          >
            <InputNumber
              size="middle"
              style={{ width: "100%" }}
              formatter={PercentageFormatter}
              parser={PercentageParser}
              placeholder={`Please input the ${item.label}`}
              onChange={(value) => {
                if (item.name === "discount_percentage") {
                  setDisableCustomDiscountButton(!(value && value > 0))
                }
              }}
            />
          </Form.Item>
          <If condition={item.name === "discount_percentage"}>
            <Button onClick={applyCustomDiscount} style={{marginBottom: "15px"}} disabled={disableCustomDiscountButton}>
              Apply Custom Discount
            </Button>
          </If>
        </Col>
      )
    }
    if (item.type === "mobileDistanceNumber") {

      const isNotMobileHidden = ["mobile_distance"];
      if (!isMobileJobLocation) {
        for (const iterator of isNotMobileHidden) {
          if (item.name === iterator) {
            return <></>;
          }
        }
      }
      const value = form.getFieldValue(item.name);
      return (
        <Col span={colspan}>
          <Form.Item
            label={item.label}
            name={item.name}
          >
            <span>{MobileJobDistanceFormatter(value)}</span>
            {/* Editing is problematic due to proper value format needed (for saving data and later on 
                calculating the mobile fee automatically based on pricing tables)
                So, most likely we can't offer custom editing */}
          </Form.Item>
        </Col>
      )
    }
    if (item.type === "calculateMobileDistanceButton") {

      if (!isMobileJobLocation) return <></>;

      return (
        <Col span={colspan}>
          <Button
            onClick={() => {
              const addressFields = ["address", "city", "state", "zip_code"];
              let addressString = "";
              for (const iterator of addressFields) {
                const value = form.getFieldValue(iterator);
                if (value === undefined || value === null) {
                  message.warning("Please insert a valid address field: " + iterator, 5);
                  return;
                }
                addressString = addressString + ", " + value;
              }
              setIsGetMobileJobDistanceModalVisible(true);
              setMobileAddressFormatted(addressString)
            }}
            style={{ marginBottom: "10px" }}
            type="primary" >Get Distances</Button>
        </Col>
      )
    }
    if (item.type === "mobileDuration") {

      const isNotMobileHidden = ["mobile_time"];
      if (!isMobileJobLocation) {
        for (const iterator of isNotMobileHidden) {
          if (item.name === iterator) {
            return <></>;
          }
        }
      }
      const value = form.getFieldValue(item.name);
      return (
        <Col span={colspan}>
          <Form.Item
            label={item.label}
            name={item.name}
          >
            <span>{MobileJobTimeFormatter(value)}</span>
            {/* Editing is problematic due to proper value format needed (for saving data and later on 
                calculating the mobile fee automatically based on pricing tables)
                So, most likely we can't offer custom editing */}
          </Form.Item>
        </Col>
      )
    }
    if (item.type === "ddl") {
      const clearable = item.clearable || false;
      const defaultValue = item.defaultValue;
      let required = item.required;

      const optionalInsuranceOnly = ["insurance_carrier_id"]
      if (!isInsurancePaymentMethod) {
        for (const iterator of optionalInsuranceOnly) {
          if (item.name === iterator) {
            return <></>;
          }
        }
      }

      const requiredForEmergencyService = ["technician_user_id"]
      if (isEmergencyService) {
        for (const iterator of requiredForEmergencyService) {
          if (item.name === iterator) {
            required = true;
          }
        }
      }

      return (
        <Col span={colspan}>
          <Form.Item
            label={item.label}
            name={item.name}
            initialValue={defaultValue || null}
            rules={[
              {
                required: required,
                message: `Please input the ${item.label}`,
              },
            ]}
          >
            <Select
              allowClear={clearable}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={`Please input the ${item.label}`}>
              {item.options.map((option) => {
                return (
                  <Select.Option
                    value={option.value}
                    key={option.value}
                  >
                    {option.label}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>

      )
    }
    if (item.type === "liability-waivers") {
      if (displayMode === "Add") {
        return <p>Please save the Intake Form, and you will see auto-determined liability waivers and be able to generate other waivers</p>;
      }
      const manuallyCreatedWaiver = ["ReleaseOfLiabilityWaiver"]
      return (
        <Col span={colspan}>
          <p style={{ display: "block" }}>Current Created Auto-Determined Waivers: </p>

          <ul style={{ display: "block" }}>
            {(liabilityWaivers).filter(x => !manuallyCreatedWaiver.includes(x.type)).map((liabilityWaiver) => {
              return (
                <li>
                  {liabilityWaiverListItems(liabilityWaiver, intakeForm)}
                </li>
              )
            })}
          </ul>

          <p style={{ display: "block" }}>Manually Created Waivers: </p>

          <ul style={{ display: "block" }}>
            {(liabilityWaivers).filter(x => manuallyCreatedWaiver.includes(x.type)).map((liabilityWaiver) => {
              return (
                <li>
                  {liabilityWaiverListItems(liabilityWaiver, intakeForm)}
                </li>
              )
            })}
          </ul>

          <p style={{ display: "block" }}>Manually create other waiver types: </p>
          <Select
            loading={isManuallyCreateWaiverTypeLoading}
            disabled={isManuallyCreateWaiverTypeLoading}
            placeholder="Select a Waiver Type to manually create"
            value={manuallyCreateWaiverType}
            onChange={(value) => {
              setManuallyCreateWaiverType(value)
            }}
          >
            {manuallyCreatedWaiver.map((x) => {
              return (
                <Select.Option value={x}>{nicelyFormatPascalCaseString(x)}</Select.Option>
              )
            })}
          </Select>
          <Button
            style={{ display: "block", marginTop: "15px" }}
            type={"primary"}
            loading={isManuallyCreateWaiverTypeLoading}
            onClick={() => {
              if (manuallyCreateWaiverType === null) {
                message.warning("Please select a waiver type to create!");
                return;
              }
              setIsManuallyCreateWaiverTypeLoading(true);
              createSingleLiabilityWaiver(intakeForm.id, manuallyCreateWaiverType).then((response) => {
                setIsManuallyCreateWaiverTypeLoading(false);
                const { data } = response;
                message.success(`Successfully created ${nicelyFormatPascalCaseString(manuallyCreateWaiverType)}`);
                setLiabilityWaivers((curr) => {
                  return [...curr, data];
                })
                const signDocumentUrl = `${process.env.REACT_APP_FRONTEND_CUSTOMER_FACING_URL}/sign-liability-waiver/${data.id}/${intakeForm.id}`;
                window.open(signDocumentUrl, "_blank");
              }).catch((err) => {
                setIsManuallyCreateWaiverTypeLoading(false);
                message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
              });
            }}
          >
            Create Waiver
          </Button>
        </Col>
      )
    }
    if (item.type === "radio") {
      let style = {}
      if (item.name === "work_type") {
        style.marginTop = "18px"
      }
      return (
        <Col span={colspan} style={style}>
          <Form.Item
            label={item.label}
            name={item.name}
            rules={[
              {
                required: item.required,
                message: `Please input the ${item.label}`,
              },
            ]}
          >
            <If condition={item.name === "shop_location_id"}>
              <Radio.Group
                placeholder={`Please input the ${item.label}`}
                onChange={(e) => {
                  let matchedShopLocation = shopLocations.filter(loc => loc.id === e.target.value);
                  matchedShopLocation = matchedShopLocation?.length > 0 ? matchedShopLocation[0] : null;
                  let isMobileLocation = false;
                  let isQuoteOnlyLocation = false;
                  if (matchedShopLocation !== null) {
                    isMobileLocation = matchedShopLocation?.name.toString().toLowerCase().includes("mobile");
                    isQuoteOnlyLocation = matchedShopLocation?.name.toString().toLowerCase().includes("quote");
                  }
                  setIsMobileJobLocation(isMobileLocation);
                  setIsQuoteOnlyLocationSelected(isQuoteOnlyLocation);
                }}
              >
                <If condition={isQuoteStatus}>
                  {/* 
                    If the status is Quote, then don't filter out the is_quote location.
                  */}
                  {shopLocations.map((shopLocation) => {
                    return (
                      <Radio
                        value={shopLocation.id}
                        key={shopLocation.id}
                      >
                        {shopLocation.name}
                      </Radio>
                    )
                  })}
                  <Else />
                  {shopLocations.filter(shopLocation => !shopLocation.is_quote).map((shopLocation) => {
                    return (
                      <Radio
                        value={shopLocation.id}
                        key={shopLocation.id}
                      >
                        {shopLocation.name}
                      </Radio>
                    )
                  })}
                </If>
              </Radio.Group>
              <Else />
              <Radio.Group
                placeholder={`Please input the ${item.label}`}
              >
                {item.options.map((option) => {
                  return (
                    <Radio
                      value={option.value}
                      key={option.value}
                    >
                      {option.label}
                    </Radio>
                  )
                })}
              </Radio.Group>
            </If>
          </Form.Item>
        </Col>
      )
    }
  }

  useEffect(() => {
    if (selectedVehicle === undefined) {
      return;
    }
    const selectedVehicleParsed = JSON.parse(selectedVehicle?.value);

    // if there are no open vehicle additions, add a new entry 
    if (activeKeyVehicleCollapse === null) {
      setVehicles((curr) => [...curr, { id: uuidv4(), ...selectedVehicleParsed, userAdded: true }]);
      return;
    }
    // if there are no open vehicle additions, add a new entry 
    if (activeKeyVehicleCollapse.length === 0) {
      setVehicles((curr) => [...curr, { id: uuidv4(), ...selectedVehicleParsed, userAdded: true }]);
      return;
    }
    // if there is open vehicle entry, override the info
    // override only the first OPEN vehicle entry
    setVehicles((curr) => {
      let wasOneHandled = false;
      return curr.map((veh) => {

        if (wasOneHandled) return veh;
        if (!activeKeyVehicleCollapse.includes(veh.id.toString())) return veh;

        wasOneHandled = true;
        veh.year = selectedVehicleParsed.year;
        veh.make = selectedVehicleParsed.make;
        veh.model = selectedVehicleParsed.model;
        return veh;
      })
    })
    doRefreshVehicles(prev => prev + 1);
  }, [selectedVehicle])

  useEffect(() => {
    console.log("Selected Customer Changed!", selectedCustomer);
    if (selectedCustomer === undefined) {
      setIsOverrideCustomerInfo(true);
      form.setFieldsValue({ "overwrite_customer_data": true });
      // if the selected customer is undefined, set all customer info fields to null
      form.setFieldsValue({
        first_name: null,
        telephone: null,
        last_name: null,
        address: null,
        city: null,
        state: "TX",
        zip_code: null,
        email: null,
        is_company: false,
        company_name: null,
        is_vip: false,
      })
      return;
    }
    setIsOverrideCustomerInfo(false);
    form.setFieldsValue({ "overwrite_customer_data": false });

    const selectedCustomerParsed = JSON.parse(selectedCustomer?.value);
    form.setFieldsValue(selectedCustomerParsed);
    setIsCustomerACompany(selectedCustomerParsed.is_company);

    try {
      var phoneNumberMask = new InputMaskCore({ pattern: phoneNumberMaskFormat })
      let phoneNumber = selectedCustomerParsed.telephone;
      let phoneNumberDigitsOnly = phoneNumber.replace("(", "").replace(")", "").replace("-", "").replace(" ", "");

      // do not delete this - it fails for whatever reason if it isn't present
      phoneNumberMask.input(phoneNumberDigitsOnly[0])
      phoneNumberDigitsOnly = phoneNumberDigitsOnly.substring(1);

      // this will fail if the phone number is not valid
      if (!phoneNumberMask.paste(phoneNumberDigitsOnly)) {
        message.warning("Please enter a valid phone number")
        form.setFieldsValue({ telephone: null })
      }
    } catch (error) {
      message.warning("Error while trying to set a phone number - please set it manually")
      form.setFieldsValue({ telephone: null })
    }
  }, [selectedCustomer])

  useEffect(() => {
    setFoundCustomerByPhoneNumber(null);
    setDisableCustomDiscountButton(true);
    if (isModalVisible) {
      setActiveStatusHistoryKey(null)
      getTags();
      setStatusHistory({});
    }
    if (displayType === "Modal") {
      setJobEntriesNotBeingEdited();
      if (isModalVisible) {
        setIsModalVisible(true);
      }
      if (displayMode === 'Edit') {
        resetAllFields();
      }
      if (displayMode === 'Add') {
        const randomVehicleId = uuidv4();
        setVehicles([{ id: randomVehicleId, userAdded: true }]);
        setActiveKeyVehicleCollapse(randomVehicleId);
        // setting the default work_type to Cash
        form.setFieldsValue({ "work_type": "Cash" })
        // setting the extra job pay to 0 by default
        form.setFieldsValue({ "extra_job_pay": 0 })
        // preselecting the current logged in user as CSR
        // if the jwt/auth data is older, we manually fire a get current employee info call
        // and get info that way
        // TODO: ^ probably can be removed?
        form.setFieldsValue({ "csr_user_id": user?.id })
        if (user?.id === undefined && form.getFieldValue("csr_user_id") === undefined) {
          GetCurrentLoggedInUserResponseStyle(accountUsers).then((response) => {
            const userInfo = response.data;
            form.setFieldsValue({ "csr_user_id": userInfo?.id })
          }).catch((err) => {
            message.error("Error while loading info for user " + err?.response?.data?.detail);
          })
        }
      }
    }
  }, [isModalVisible]);

  useEffect(() => {
    setSelectedWarrantySourceIntakeForm(null);
    resetAllFields();
    form.setFieldsValue({ tags: (intakeForm?.tags || []).map((tag) => tag.id) });
    if (intakeForm?.id !== undefined) {
      setOtherRejectedQuoteReasonSelected(intakeForm.quote_rejected_reason === "Other");
      loadJobReviewData();
      loadAllAGCRMNagsPartInstallationInfos();

      if (intakeForm.work_type === "Warranty") {
        loadWarrantySourceIntakeForm(intakeForm?.warranty_work_original_intake_form_id);
      }
    }
  }, [intakeForm]);

  useEffect(() => {
    setFoundCustomerByPhoneNumber(null);
    setPhoneNrOnlyChat(null);
    setIsChatListVisible(false);
    setConversationId(null);
    setIntakeFormConversationRecord(null);

    if (intakeFormPhoneNumber === null || intakeFormPhoneNumber === undefined) return;

    let phoneNr = intakeFormPhoneNumber.toString();
    if (phoneNr.length !== 14) return;
    if (phoneNr.includes("_")) return;

    fetchConversationForPhoneNumber(phoneNr);

    getSingleCustomerPhoneNumber(phoneNr).then((response) => {
      const { data } = response;
      setFoundCustomerByPhoneNumber(data);
    }).catch((err) => {
      if (err.response.status === 404) {
        return;
      }
      message.error("Error while fetching customer for phone number, please reenter phone number and try again");
    });
  }, [intakeFormPhoneNumber]);

  useEffect(() => {
    // customer data should only be prefilled if the modal is in Add mode
    if (displayMode !== "Add") {
      return;
    }
    // if there is no prefill customer data, set selected customer to undefined
    // set debounced field value to undefined as well (so that it shows as new entry)
    if (prefillCustomerData === undefined || prefillCustomerData === null) {
      setSelectedCustomer(undefined);
      form.setFieldsValue({ customer_info_json: undefined })
      form.setFieldsValue({ "state": "TX" });
      return;
    }
    // if the prefillCustomerData was created from already existing customer (from our backend)
    // fill it so that it properly handles the search through debounced select
    if (prefillCustomerData?.id !== undefined) {
      const customerDataPrefilled =
      {
        label: `${prefillCustomerData.company_name || (prefillCustomerData.first_name + " " + prefillCustomerData.last_name)}, Phone #: ${prefillCustomerData.telephone}`,
        value: JSON.stringify({ ...prefillCustomerData }),
        key: prefillCustomerData.id
      }
      setSelectedCustomer(customerDataPrefilled);
      form.setFieldsValue({ customer_info_json: customerDataPrefilled });
      form.setFieldsValue({ net_term: prefillCustomerData.default_net_term || null });
      form.setFieldsValue({ is_vip: prefillCustomerData.default_is_vip || false });
      return;
    }
    // if the prefillCustomerData was just created from callrail call details, set selected customer to undefined
    // set the form values of first and last name, as well as telephone 
    if (prefillCustomerData !== undefined) {
      setSelectedCustomer(undefined);
      let telephone = null;
      // phone requires some special handling, not sure why - probably needs a rewrite
      try {
        var phoneNumberMask = new InputMaskCore({ pattern: phoneNumberMaskFormat })
        let phoneNumber = prefillCustomerData.telephone;
        let phoneNumberDigitsOnly = phoneNumber.replace("(", "").replace(")", "").replace("-", "").replace(" ", "");

        // do not delete this - it fails for whatever reason if it isn't present
        phoneNumberMask.input(phoneNumberDigitsOnly[0])
        phoneNumberDigitsOnly = phoneNumberDigitsOnly.substring(1);

        // this will fail if the phone number is not valid
        if (!phoneNumberMask.paste(phoneNumberDigitsOnly)) {
          message.warning("Error while trying to set a phone number - please set it manually")
        } else {
          telephone = phoneNumberMask.getValue();
        }
      } catch (error) {
        message.warning("Error while trying to set a phone number - please set it manually")
      }
      form.setFieldsValue({
        first_name: prefillCustomerData.first_name,
        last_name: prefillCustomerData.last_name,
        telephone: telephone,
        zip_code: prefillCustomerData.zip_code,
        email: prefillCustomerData.email
      });
      setIntakeFormPhoneNumber(telephone);
      return;
    }
  }, [prefillCustomerData])

  useEffect(() => {
    if (prefillWebFormData === null) return;

    // web form data should only be prefilled if the modal is in Add mode
    if (displayMode !== "Add") {
      return;
    }

    const randomVehicleId = uuidv4();
    setVehicles(
      [
        {
          id: randomVehicleId,
          userAdded: true,
          vin: prefillWebFormData.vin,
          year: prefillWebFormData.year,
          make: prefillWebFormData.make,
          model: prefillWebFormData.model,
        }
      ]
    );
    //form.setFieldsValue({job_notes: prefillWebFormData.job_notes});
  }, [prefillWebFormData])

  useEffect(() => {
    if (prefillVinsFromTextMessages === null) return;

    // web form data should only be prefilled if the modal is in Add mode
    if (displayMode !== "Add") {
      return;
    }

    fetchConversationForPhoneNumber(prefillCustomerData.telephone);

    setVehicles(prefillVinsFromTextMessages.map(x => {
      x = {
        ...x,
        id: uuidv4(),
        userAdded: true,
      }
      return x;
    }))
    prefillVinsFromTextMessages.forEach(element => {
      if (element.vin !== undefined && element.vin !== null && element.vin !== "") {
        getCarInfoForVinLookup(element.vin).then((response) => {
          const { data } = response;
          setVehicles((curr) => {
            return curr.map((vehicle) => {
              if (vehicle.vin === element.vin) {
                vehicle = {
                  ...vehicle,
                  year: data.ModelYear,
                  make: data.Make,
                  model: data.Model,
                  body_class: data.BodyClass,
                  trim: data.Trim,
                  trim_2: data.Trim2
                }
              }
              return vehicle;
            })
          });
        }).catch((err) => {
          message.warning(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
        });
      }
    });
  }, [prefillVinsFromTextMessages])

  useEffect(() => {
    let price = 0;
    const jobEntryObjects = [
      glassReplacementJobEntries, chipRepairJobEntries,
      bodyAndPaintJobEntries, tintOnlyJobEntries,
      vehicleDetailJobEntries
    ];
    let index = 0;
    for (const jobEntryObject of jobEntryObjects) {
      for (const jobEntry of jobEntryObject) {
        let tintPrice = 0;
        if (index === 0 || index === 3) {
          if (jobEntry.tint_pricing?.value.split(";;;")[0] === "null") {
            tintPrice = parseFloat(jobEntry.tint_price);
          } else {
            tintPrice = parseFloat(jobEntry.tint_pricing?.value.split(";;;")[0]);
          }
        } else {
          tintPrice = parseFloat(jobEntry.tint_price);
        }

        let glassPrice = (isInsurancePaymentMethod ? jobEntry.glass_price_total : null) || jobEntry.glass_price;
        glassPrice = parseFloat(glassPrice);

        const price_ = parseFloat(jobEntry.price);
        if (!isNaN(tintPrice)) {
          price = price + tintPrice;
        }
        if (!isNaN(glassPrice)) {
          price = price + glassPrice;
        }
        if (!isNaN(price_)) {
          price = price + price_;
        }

        let calibrationPrice = (isInsurancePaymentMethod ? jobEntry.calibration_pricing_total : null) || jobEntry.calibration_pricing;
        if (calibrationPrice !== undefined && calibrationPrice !== null) {
          calibrationPrice = parseFloat(calibrationPrice);
          if (!isNaN(calibrationPrice)) {
            price = price + calibrationPrice;
          }
        }
      }
      index++;
    }

    const currentMobileFee = form.getFieldValue("mobile_fee");
    if (!isNaN(parseFloat(currentMobileFee))) {
      price = price + parseFloat(currentMobileFee);
    }

    const currentEmergencyServiceFee = form.getFieldValue("emergency_service_fee");
    if (!isNaN(parseFloat(currentEmergencyServiceFee))) {
      price = price + parseFloat(currentEmergencyServiceFee);
    }

    const currentDeductibleAmount = form.getFieldValue("deductible");
    if (!isNaN(parseFloat(currentDeductibleAmount))) {
      price = price - parseFloat(currentDeductibleAmount);
    }
    
    price = roundNumber(price);
    setTotalPriceSum(price);
    setOriginalTotalPrice(price);
  }, [
    glassReplacementJobEntries, chipRepairJobEntries, bodyAndPaintJobEntries,
    tintOnlyJobEntries, vehicleDetailJobEntries, mobileFee, deductibleAmount,
    emergencyServiceFee, isInsurancePaymentMethod
  ]);

  useEffect(() => {
    const discountAmount = form.getFieldValue("discount_amount") || 0;
    let discountPercentage = 0
    if (originalTotalPrice > 0) {
      discountPercentage = (discountAmount / originalTotalPrice) * 100;
    }
    updateDiscountState(discountPercentage)
  }, [originalTotalPrice]);

  useEffect(() => {
    getInsuranceCarriers();
    getShopLocations();
    getCsrEmployees();
    getTechnicianEmployees();
    updateDiscountState(0);
  }, []);

  const currentSelectedTagIds = form.getFieldValue("tags") || [];

  const displayValuesNode = (
    <>
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        onFinish={(values) => onSubmit(values)}
        initialValues={intakeForm}
        onFinishFailed={(e) => {
          // if it fails, open the customer details collapsed element
          // most likely the users won't fill out the customer details
          setCustomerDetailsCollapseActiveKey(["customer_details"]);
        }}
        onValuesChange={(changedVal, allValues) => {
          setDefaultVisibilityToggles(allValues);
        }}
      >
        <Form.Item
          style={{ display: "hidden", height: "0", margin: "0" }}
          name="zero_cost_reason"
          initialValue={null}
        >
        </Form.Item>
        <If condition={intakeForm !== undefined && (intakeForm?.square_invoice_id !== undefined && intakeForm?.square_invoice_id !== null)}>
          <Button
            style={{ marginBottom: "20px" }}
            type="primary"
            onClick={() => publishInvoiceDraft()}
            loading={publishInvoiceButtonLoading}> Publish Invoice In Square </Button>
          <i style={{ display: "block" }}>
            <b>
              Important! &nbsp;
            </b>
            Publish Invoice action will use the currently saved Intake Form state.
          </i>
          <i style={{ display: "block", marginBottom: "20px" }}>
            If you made some changes and didn't save them, those won't be reflected in the Published Invoice
          </i>
        </If>
        <Row gutter={18}>
          {intakeFormElementsFields.map((item) => {
            if (item.children === undefined) {
              return intakeFormFieldDisplay(item);
            }
            const colspan = item.colspan ? item.colspan : 12;

            if (item.type === "panel-grouper") {
              return (
                <Col span={colspan}>
                  <Collapse
                    style={{ marginBottom: "20px" }}
                    activeKey={item.name === "customer_details" ? customerDetailsCollapseActiveKey : null}
                    onChange={(e) => {
                      setCustomerDetailsCollapseActiveKey(e);
                    }}
                  >
                    <Panel
                      header={item?.label}
                      forceRender={true}
                      key={"customer_details"}
                    >
                      <Row gutter={18}>
                        {
                          item.children.map((child) => {
                            return intakeFormFieldDisplay(child);
                          })
                        }
                      </Row>
                    </Panel>
                  </Collapse>
                </Col>
              )
            }
          })}
        </Row>

      </Form>

      <AddMissingVehicleComponent
        isModalVisible={isAddMissingVehicleModalVisible}
        setIsModalVisible={setIsAddMissingVehicleModalVisible}
        setSelectedVehicle={setSelectedVehicle}
      />

      <EditNagsPartNrSearchResultModalComponent
        isModalVisible={isEditNagsPartNrSearchResultModalVisible}
        setIsModalVisible={setIsEditNagsPartNrSearchResultModalVisible}
        nagsPartNr={selectedNagsPartNr}
        setNagsPartNrAGCRMInstallInfos={setNagsPartNrAGCRMInstallInfos}
      />
    </>
  )

  const getChatIconColorState = (conversationId) => {
    if (conversationId === null || conversationId === undefined) return "black";

    return "#50ff50";
  }

  const getChatIconPopoverText = (conversationId) => {
    if (conversationId === null || conversationId === undefined) return "No existing messages found for current phone number";

    return "Click to open messages";
  }

  const chatFloatingIconAndChatWidgetNode = (
    <>
      <Row style={{ position: "fixed", right: "32px", bottom: "82px", zIndex: 2147483640 }}>
        <Col flex="auto"></Col>
        <Col flex="100px">
          <Affix offsetBottom={15} style={{ position: "relative" }}>
            <div>
              <If condition={isChatListVisible}>
                <div style={{ position: "absolute", bottom: 50, right: 0 }}>
                  <Card style={{ borderColor: "slategray" }}>
                    <MessagesListComponent
                      conversationId={conversationId}
                      setConversationId={setConversationId}
                      mode="Modal"
                      phoneNrOnlyChat={phoneNrOnlyChat}
                      setPhoneNrOnlyChat={setPhoneNrOnlyChat}
                      onNewConversationInitiatedAndCallrailConversationCreated={(conversation) => {
                        setConversationId(conversation.id);
                        setIntakeFormConversationRecord(conversation);
                      }}
                    />
                  </Card>
                </div>
              </If>
              <Popover content={getChatIconPopoverText(conversationId)} placement="rightTop">
                <MessageFilled
                  style={{ fontSize: 48, color: getChatIconColorState(conversationId) }}
                  onClick={() => {
                    // if the conversation exists, we open as a chat tab, otherwise we default to conversation modal
                    // new conversations should be also handled via chat tabs, but that is yet to be implemented
                    if (conversationId !== null && conversationId !== undefined) {
                      openSpecificChat(intakeFormConversationRecord);
                      return;
                    }
                    setIsChatListVisible((prev) => !prev)
                  }}
                />
              </Popover>
            </div>
          </Affix>
        </Col>
      </Row>
    </>
  );

  const saveButtonNode = (stopPropagation) => (
    <Button
      loading={intakeFormAdding}
      type="primary"
      onClick={(e) => {
        if (stopPropagation) {
          e.stopPropagation();
          return;
        }
        form.submit();
      }}
      style={displayType !== "Modal" ? { marginTop: 30, marginBottom: "20px", float: "right" } : {}}
    >
      {intakeForm !== undefined ? "Save" : "Add"}
    </Button>
  );

  const shouldDisplayPopconfirm = () => {
    let shouldDisplay = false;
    for (const glassJob of glassReplacementJobEntries) {
      if (glassJob.submit_order === false && (glassJob.external_part_id !== null && glassJob.external_part_id !== undefined)) {
        shouldDisplay = true;
        break;
      }
      if (glassJob.submit_order === undefined && glassJob.external_part_order_id === null && (glassJob.external_part_id !== null && glassJob.external_part_id !== undefined)) {
        shouldDisplay = true;
        break;
      }
    }
    return shouldDisplay;
  }

  const intakeFormFooterArea = (showCloseButton) => {

    // temporarily setting this to always false because we don't have production credentials on all API vendors
    // we should uncomment the line below when we have production credentials

    //let isDisplayPopconfirmForCreateOrder = shouldDisplayPopconfirm();
    let isDisplayPopconfirmForCreateOrder = false;

    return (
      <Space>
        <If condition={showCloseButton}>
          <Popconfirm
            title="Are you sure you want to close? Unsaved changes will be lost."
            onConfirm={(e) => {
              handleCancel();
              e.stopPropagation();
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="info"
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ float: "right" }}
            >
              Close
            </Button>
          </Popconfirm>
        </If>
        <If condition={isDisplayPopconfirmForCreateOrder}>
          <Popconfirm
            title={
              <>
                <b>NO ORDER SUBMITTED!</b>
                <p style={{ display: "block" }}>
                  You don't have the 'Submit an Order?' checkbox checked,
                </p>
                <p style={{ display: "block" }}>
                  meaning that an order won't be sent to the vendor.
                </p>
                <p style={{ display: "block", marginTop: "13px" }}>Are you sure you don't want to place an order?</p>
              </>
            }
            onConfirm={(e) => {
              e.stopPropagation();
              form.submit()
            }}
            okText="Yes, I'm sure I don't want to place an order"
            cancelText="Let me edit"
          >
            {saveButtonNode(true)}
          </Popconfirm>
          <Else />
          {saveButtonNode(false)}
        </If>
      </Space>
    )
  }

  let footer = null;

  if (displayType === "Modal") {
    footer = intakeFormFooterArea(true);
  } else {
    footer = intakeFormFooterArea(false);
  }

  return (
    <>
      <If condition={displayType === "Modal"}>
        <Modal
          title={intakeForm !== undefined ? "Edit Job" : "Add Job"}
          okText={intakeForm !== undefined ? "Save" : "Add"}
          cancelText="Close"
          width={1640}
          visible={isModalVisible}
          onOk={() => form.submit()}
          onCancel={() => handleCancel()}
          confirmLoading={intakeFormAdding}
          maskClosable={false}
          className={"intake-form-modal"}
          footer={footer}
        >
          {displayValuesNode}
          {chatFloatingIconAndChatWidgetNode}
        </Modal>
        <Else />
        {displayValuesNode}
        {footer}
        {chatFloatingIconAndChatWidgetNode}
      </If>
      <GetMobileJobDistanceModal
        isModalVisible={isGetMobileJobDistanceModalVisible}
        setIsModalVisible={setIsGetMobileJobDistanceModalVisible}
        setMobileDistanceTime={setMobileDistanceTime}
        address={mobileAddressFormatted}
      />
      <AddEmailToIntakeFormComponent
        isModalVisible={isAddEmailToIntakeFormModalVisible}
        onCancel={() => {
          setIsAddEmailToIntakeFormModalVisible(false);
        }}
        onSuccess={(email) => {
          setIsAddEmailToIntakeFormModalVisible(false);
          publishInvoiceDraft(email);
        }}
        initialEmailValue={publishEmailValue}
      />
      <ZeroCostReasonModalComponent
        isModalVisible={isZeroCostReasonModalVisible}
        setIsModalVisible={setIsZeroCostReasonModalVisible}
        onClickUseZeroCostReason={(e) => {
          const zeroCostReason = e.zero_cost_reason;
          const formValues = form.getFieldsValue();
          onSubmit(formValues, zeroCostReason)
        }}
      />
      <If condition={isAddDepositModalVisible}>
        <AddEditIntakeFormDepositRequestModalComponent
          mode="Add"
          isModalVisible={isAddDepositModalVisible}
          setIsModalVisible={setIsAddDepositModalVisible}
          onSubmitForm={(newDepositEntry) => {
            const newDepositRequest = {
              ...newDepositEntry,
              id: uuidv4(),
              userAdded: true,
              status: "Unpaid",
              is_deleted: false
            }
            setDepositRequests([...depositRequests, newDepositRequest]);
            setIsAddDepositModalVisible(false);
          }}
        />
      </If>
      <If condition={isEditDepositModalVisible}>
        <AddEditIntakeFormDepositRequestModalComponent
          mode="Edit"
          isModalVisible={isEditDepositModalVisible}
          setIsModalVisible={setIsEditDepositModalVisible}
          depositRequest={selectedDepositRequest}
          onSubmitForm={(editedDepositRequest) => {
            setDepositRequests((curr) => {
              return curr.map((depositRequest) => {
                if (depositRequest.id === selectedDepositRequest.id) {
                  return { ...depositRequest, ...editedDepositRequest };
                }
                return depositRequest;
              })
            });
            setIsEditDepositModalVisible(false);
          }}
        />
      </If>
      <DeliveryMethodSelectionComponent
        isModalVisible={isDeliveryMethodSignedDocModalVisible}
        setIsModalVisible={setIsDeliveryMethodSignedDocModalVisibility}
        onSubmit={(values) => {
          console.log("values", values);
          console.log("selectedLiabilityWaiver", selectedLiabilityWaiverId);
          sendSignedWaiverToCustomer(selectedLiabilityWaiverId, values).then((response) => {
            const { data } = response;
            message.success("Successfully sent the liability waiver to customer!");
          }).catch((err) => {
            message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
          });
        }}
      />
      <DeliveryMethodSelectionComponent
        isModalVisible={isDeliveryMethodDocToSignModalVisible}
        setIsModalVisible={setIsDeliveryMethodDocToSignModalVisibility}
        onSubmit={(values) => {
          console.log("values", values);
          console.log("selectedLiabilityWaiver", selectedLiabilityWaiverId);
          sendWaiverToCustomerToSign(selectedLiabilityWaiverId, values).then((response) => {
            const { data } = response;
            message.success("Successfully sent the liability waiver to customer to sign!");
          }).catch((err) => {
            message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
          });
        }}
      />
    </>
  );
};

export default AddEditIntakeFormComponent;
